"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Play = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Play = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { opacity: ".64", fillRule: "evenodd", clipRule: "evenodd", d: "M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z", fill: "#000000" }, void 0), jsx_runtime_1.jsx("path", { d: "M16.745 12.218c.11-.054.11-.163.11-.218 0-.11-.055-.164-.11-.218L10.2 8.018c-.109-.054-.164-.054-.273 0-.054 0-.109.11-.109.218v7.582c0 .11.055.164.11.218.108.055.163.055.272 0l6.546-3.818z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M9.818 16.31c-.109 0-.163 0-.273-.055a.573.573 0 0 1-.272-.491V8.236c0-.218.109-.381.272-.49a.495.495 0 0 1 .546 0l6.545 3.763c.164.11.273.273.273.491 0 .218-.109.382-.273.49l-6.545 3.764c-.11.055-.164.055-.273.055zm0-8.074v7.582L16.364 12l.109.218-.11-.218-6.545-3.764z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Play = Play;
