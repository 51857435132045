import { FieldFunctionOptions } from '@apollo/client'
import { Reference } from '@apollo/client/utilities'

export const imageKitUri = (
  existing: Reference,
  { readField }: FieldFunctionOptions
) => {
  const uri = readField<string>('uri')
  return existing ?? uri ?? null
}
