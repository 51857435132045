"use strict";
var _a, _b, _c, _d, _e;
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var grey = {
    50: '#F1F5F6',
    100: '#E5ECEF',
    200: '#D2DEE2',
    300: '#C1D0D7',
    400: '#A7B8C0',
    500: '#97A7B0',
    600: '#798A94',
    700: '#54656D',
    800: '#334752',
    900: '#20343F',
};
var palette = {
    black: '#000000',
    white: '#FFFFFF',
    background: {
        default: '#FFFFFF',
    },
    like: {
        tap: '#f91880',
    },
    icon: {
        line: '#54656D',
        background: '#F1F5F6',
    },
    bonus: {
        main: '#fad862',
    },
    grey: grey,
    primary: {
        main: '#1970D4',
        light: '#E2F1FE',
        dark: '#1640A1',
        gradient: 'linear-gradient(102.8deg, #7EC5A3 0.39%, #498CB8 62.89%, #296AC5 100.31%)',
    },
    secondary: {
        main: '#008484',
        light: '#E0F7F7',
        dark: '#04615b',
    },
    error: {
        main: '#EF3D2E',
        light: '#FFEAED',
    },
    info: {
        main: '#1990F6',
        light: '#E2F1FE',
        dark: '#1970D4',
    },
    warning: {
        main: '#DF3E51',
        light: '#FCE9ED',
        dark: '#A7110E',
    },
    text: {
        primary: '#20343F',
        secondary: '#54656D',
        hint: '#A7B8C0',
        disabled: '#C1D0D7',
    },
};
var breakpointValues = {
    xs: 0,
    sm: 768,
    md: 1024,
    lg: 1440,
    xl: 1920,
};
exports.theme = {
    breakpoints: {
        values: breakpointValues,
    },
    typography: {
        fontFamily: 'Hiragino Kaku Gothic Pro',
        h1: (_a = {},
            _a["@media (max-width:" + breakpointValues.md + "px)"] = {
                fontSize: '32px',
            },
            _a.fontStyle = 'normal',
            _a.fontWeight = 'bold',
            _a.fontSize = '40px',
            _a),
        h2: (_b = {},
            _b["@media (max-width:" + breakpointValues.md + "px)"] = {
                fontSize: '20px',
            },
            _b.fontStyle = 'normal',
            _b.fontWeight = 'bold',
            _b.fontSize = '28px',
            _b),
        h3: (_c = {},
            _c["@media (max-width:" + breakpointValues.md + "px)"] = {
                fontSize: '18px',
            },
            _c.fontStyle = 'normal',
            _c.fontWeight = 'bold',
            _c.fontSize = '20px',
            _c),
        h4: (_d = {},
            _d["@media (max-width:" + breakpointValues.md + "px)"] = {
                fontSize: '16px',
            },
            _d.fontStyle = 'normal',
            _d.fontWeight = 'bold',
            _d.fontSize = '18px',
            _d),
        h5: (_e = {},
            _e["@media (max-width:" + breakpointValues.md + "px)"] = {
                fontSize: '15px',
            },
            _e.fontStyle = 'normal',
            _e.fontWeight = 'bold',
            _e.fontSize = '16px',
            _e),
        subtitle1: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        subtitle2: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '12px',
        },
        body1: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
        },
        body2: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '14px',
        },
        button: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '16px',
        },
        caption: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '12px',
        },
        overline: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '10px',
        },
    },
    palette: palette,
    spacing: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48],
    mixins: {
        toolbar: {
            height: 64,
            boxSizing: 'border-box',
        },
    },
    components: {
        MuiLoadingButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    borderRadius: 34,
                    '&.Mui-disabled': {
                        background: grey[100],
                    },
                },
                containedPrimary: {
                    background: palette.primary.gradient,
                },
                outlined: {
                    color: palette.text.primary,
                    borderColor: grey[300],
                },
                sizeMedium: {
                    width: 200,
                    height: 40,
                },
                sizeLarge: {
                    width: 320,
                    height: 56,
                },
                endIcon: {
                    /**
                     * @description ボタンのendIconPropsのNodeは、どのボタンサイズでも12px固定
                     */
                    '& > *:first-of-type': {
                        fontSize: 12,
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    borderRadius: 34,
                    '&.Mui-disabled': {
                        background: grey[100],
                    },
                },
                containedPrimary: {
                    background: palette.primary.gradient,
                },
                outlined: {
                    color: palette.text.primary,
                    borderColor: grey[300],
                },
                sizeMedium: {
                    width: 200,
                    height: 40,
                },
                sizeLarge: {
                    width: 320,
                    height: 56,
                },
                endIcon: {
                    /**
                     * @description ボタンのendIconPropsのNodeは、どのボタンサイズでも12px固定
                     */
                    '& > *:first-of-type': {
                        fontSize: 12,
                    },
                },
            },
        },
        MuiList: {
            defaultProps: {
                disablePadding: true,
            },
        },
        MuiDivider: {
            defaultProps: {
                light: true,
            },
            styleOverrides: {
                root: {
                    borderColor: grey[200],
                },
                light: { borderColor: grey[100] },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: 280,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 16,
                    color: 'inherit',
                },
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    width: 40,
                    height: 40,
                    fontSize: 20,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                color: 'secondary',
                inputProps: {
                    variant: 'body2',
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                color: 'secondary',
            },
            styleOverrides: {
                root: {
                    minWidth: 200,
                    minHeight: 56,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    padding: '0 16px',
                },
            },
            variants: [
                {
                    props: { variant: 'filled' },
                    style: {
                        minWidth: 88,
                        height: 24,
                        borderRadius: 4,
                    },
                },
                {
                    props: { variant: 'filled', color: 'default' },
                    style: {
                        backgroundColor: grey[50],
                        color: palette.text.hint,
                    },
                },
                {
                    props: { variant: 'filled', color: 'primary' },
                    style: {
                        backgroundColor: palette.primary.light,
                        color: palette.primary.main,
                    },
                },
                {
                    props: { variant: 'filled', color: 'secondary' },
                    style: {
                        backgroundColor: '#FFFFFF',
                        color: palette.secondary.main,
                        border: "1px solid " + palette.secondary.main,
                    },
                },
                {
                    /**
                     * @description [ClickUp] https://app.clickup.com/t/79thfj
                     * material-uiのcolor拡張PRがマージされ次第修正
                     * https://github.com/mui-org/material-ui/issues/13875#issuecomment-761156846
                     @ts-ignore */
                    props: { variant: 'filled', color: 'error' },
                    style: {
                        backgroundColor: palette.error.light,
                        color: palette.error.main,
                    },
                },
            ],
        },
        MuiIconButton: {
            defaultProps: {
                color: 'inherit',
            },
            variants: [
                {
                    props: { size: 'xSmall' },
                    style: {
                        svg: {
                            fontSize: 18,
                        },
                        width: 28,
                        height: 28,
                    },
                },
                {
                    props: { size: 'xLarge' },
                    style: {
                        svg: {
                            fontSize: 35,
                        },
                        width: 64,
                        height: 64,
                    },
                },
            ],
            styleOverrides: {
                sizeSmall: {
                    svg: {
                        fontSize: 20,
                    },
                    width: 30,
                    height: 30,
                },
                sizeMedium: {
                    svg: {
                        fontSize: 24,
                    },
                    width: 44,
                    height: 44,
                },
                sizeLarge: {
                    svg: {
                        fontSize: 28,
                    },
                    width: 52,
                    height: 52,
                },
            },
        },
        MuiSvgIcon: {
            defaultProps: {
                fontSize: 'medium',
            },
            styleOverrides: {
                fontSizeSmall: 20,
                fontSizeLarge: 35,
            },
            variants: [
                {
                    props: { color: 'default' },
                    style: {
                        color: palette.grey[600],
                    },
                },
                {
                    props: { fontSize: 'xSmall' },
                    style: { fontSize: 12 },
                },
                {
                    props: { fontSize: 'xLarge' },
                    style: { fontSize: 40 },
                },
            ],
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    whiteSpace: 'pre-wrap',
                },
            },
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    display: 'grid',
                    gridTemplateColumns: 'auto auto',
                    borderRadius: 4,
                    minHeight: 48,
                    flexGrow: 0,
                    padding: '12px 16px',
                    backgroundColor: grey[700],
                    boxSizing: 'border-box',
                },
                message: {
                    padding: '0 12px 0 0',
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                inputProps: {
                    variant: 'body2',
                },
            },
        },
    },
};
