"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../../components");
/**
 * 共通確認用ダイアログ
 * @param modalProps モーダルのProps
 * @param buttons ok/cancelの各々にonClickとchildren(文言)を渡す
 * @param title モーダルのタイトル文言
 * @param content titleの下のモーダルのcontentNode
 */
var ConfirmationModal = function (props) {
    var title = props.title, content = props.content, buttons = props.buttons, argProps = __rest(props, ["title", "content", "buttons"]);
    var mdUp = material_1.useMediaQuery(function (theme) { return theme.breakpoints.up('md'); });
    return (jsx_runtime_1.jsx(components_1.Modal, __assign({}, argProps, { children: jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                width: { xs: 300, md: 432 },
                px: { xs: 0, md: 4 },
                py: { xs: 0, md: 5 },
                boxSizing: 'border-box',
            } }, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h3", align: "center", sx: { lineHeight: '28px', mb: { xs: 2, md: 3 } } }, { children: title }), void 0), jsx_runtime_1.jsx(material_1.Box, __assign({ sx: { mb: { xs: 6, md: 7 } } }, { children: content }), void 0), jsx_runtime_1.jsx(components_1.ButtonSet, { variant: "vertical", size: "medium", primaryButton: jsx_runtime_1.jsx(components_1.Button
                    /**
                     * @todo [ClickUp] https://app.clickup.com/t/7e0v6q
                     * spサイズ時のボタンサイズ確認中、アイテム作成organisms作成時に一緒にfixさせる
                     */
                    , __assign({ 
                        /**
                         * @todo [ClickUp] https://app.clickup.com/t/7e0v6q
                         * spサイズ時のボタンサイズ確認中、アイテム作成organisms作成時に一緒にfixさせる
                         */
                        size: mdUp ? 'large' : 'medium' }, buttons.ok, { fullWidth: true }), void 0), secondaryButton: jsx_runtime_1.jsx(components_1.Button, __assign({ variant: "outlined", size: mdUp ? 'large' : 'medium' }, buttons.cancel, { fullWidth: true }), void 0) }, void 0)] }), void 0) }), void 0));
};
exports.ConfirmationModal = ConfirmationModal;
