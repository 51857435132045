"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectCard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../components");
/**
 * 選択用のカード
 *
 * @param variant 選択状態 default(初期値) | selecting (選択中) | selected（選択確定後）
 * @param content コンテンツ情報 value: 一意な値, label: { main: メイン文言, sub: サブ文言 }
 * @param onClickCard カード自体をクリックしたアクション
 * @param onClickSelectIcon 選択中のアイコンをクリックした時のアクション
 * @param onClickCloseIcon 選択確定後のアイコンをクリックした時のアクション
 */
var SelectCard = function (_a) {
    var variant = _a.variant, content = _a.content, _b = _a.onClickCard, onClickCard = _b === void 0 ? function () { } : _b, _c = _a.onClickSelectIcon, onClickSelectIcon = _c === void 0 ? function () { } : _c, _d = _a.onClickCloseIcon, onClickCloseIcon = _d === void 0 ? function () { } : _d;
    var handleClickCard = function () {
        onClickCard(content.value);
    };
    var handleClickSelectIcon = function (e) {
        e.stopPropagation();
        onClickSelectIcon(content.value);
    };
    var handleClickCloseIcon = function (e) {
        e.stopPropagation();
        onClickCloseIcon(content.value);
    };
    return (jsx_runtime_1.jsx(material_1.Card, __assign({ elevation: 2, sx: {
            position: 'relative',
            borderRadius: 1,
            bgcolor: variant === 'default' ? 'common.white' : 'primary.light',
            width: { xs: 295, md: 496 },
        } }, { children: jsx_runtime_1.jsxs(material_1.CardActionArea, __assign({ onClick: handleClickCard }, { children: [jsx_runtime_1.jsx(material_1.CardHeader, { title: content.label.main, titleTypographyProps: { variant: 'h4' }, subheader: content.label.sub, subheaderTypographyProps: { variant: 'body2', sx: { mt: 1 } }, sx: {
                        '&.MuiCardHeader-root': {
                            px: { xs: 4, md: 7 },
                            py: { xs: 3, md: 4 },
                            boxSizing: 'border-box',
                            height: { xs: 72, md: 88 },
                        },
                    } }, void 0), variant === 'selecting' ? (jsx_runtime_1.jsx(components_1.IconButton.Select, { color: "primary", sx: {
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }, onClick: handleClickSelectIcon }, void 0)) : variant === 'selected' ? (jsx_runtime_1.jsx(components_1.IconButton.Close, { sx: {
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    }, onClick: handleClickCloseIcon }, void 0)) : null] }), void 0) }), void 0));
};
exports.SelectCard = SelectCard;
