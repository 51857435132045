"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Switch = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
/**
 * [Reference]
 * Material-ui official IOS Switch styling
 * https://next.material-ui.com/components/switches/#main-content
 */
// @ts-ignore
exports.Switch = material_1.styled(function (props) { return (jsx_runtime_1.jsx(material_1.Switch, __assign({ focusVisibleClassName: ".Mui-focusVisible", disableRipple: true }, props), void 0)); })(function (_a) {
    var theme = _a.theme;
    return ({
        width: 48,
        height: 24,
        padding: 0,
        margin: theme.spacing(1),
        '& .MuiSwitch-switchBase': {
            padding: 4,
            '&.Mui-checked': {
                transform: 'translateX(24px)',
                color: theme.palette.grey[100],
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 1,
                    border: 0,
                },
                '& .MuiSwitch-thumb': {
                    backgroundColor: theme.palette.common.white,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: theme.palette.secondary.main,
                border: "6px solid " + theme.palette.common.white,
            },
        },
        '& .MuiSwitch-thumb': {
            width: 16,
            height: 16,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.grey[100],
            opacity: 1,
            transition: theme.transitions.create(['background-color', 'border-color']),
            boxSizing: 'border-box',
        },
    });
});
