"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Twitter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Twitter = function (props) { return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "17.5", cy: "17.5", r: "17", stroke: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M25.924 12.682a6.97 6.97 0 0 1-1.72.49c.277-.048.684-.546.846-.747a3.16 3.16 0 0 0 .554-1.023c0-.028.028-.067 0-.087a.095.095 0 0 0-.09 0 8.703 8.703 0 0 1-2.037.79.142.142 0 0 1-.143-.036 1.987 1.987 0 0 0-.177-.185 3.71 3.71 0 0 0-.973-.628 3.424 3.424 0 0 0-1.515-.25 3.67 3.67 0 0 0-1.44.396c-.44.241-.827.57-1.138.964a3.648 3.648 0 0 0-.692 1.382 3.79 3.79 0 0 0-.035 1.462c0 .083 0 .095-.072.083-2.819-.415-5.132-1.414-7.022-3.56-.083-.094-.127-.094-.194 0-.822 1.249-.423 3.224.605 4.2.138.13.28.257.431.375a3.532 3.532 0 0 1-1.352-.375c-.08-.051-.123-.024-.127.071a2.327 2.327 0 0 0 0 .395 3.585 3.585 0 0 0 2.215 2.86c.143.062.293.108.446.138a4.05 4.05 0 0 1-1.324.04c-.095-.02-.13.031-.095.122.581 1.58 1.842 2.062 2.768 2.331.126.02.253.02.395.051l-.024.024c-.272.498-1.376.834-1.882 1.008a6.772 6.772 0 0 1-2.886.37c-.155-.023-.19-.019-.23 0-.04.02 0 .064.044.104a9.465 9.465 0 0 0 2.53 1.146c3.472.955 7.379.252 9.985-2.336 2.048-2.03 2.768-4.83 2.768-7.636 0-.106.13-.17.205-.225.519-.403.975-.88 1.357-1.414.066-.08.1-.181.095-.284 0-.06 0-.048-.076-.016z", fill: "currentColor" }, void 0)] }), void 0) }), void 0)); };
exports.Twitter = Twitter;
