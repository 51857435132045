"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaskedInputField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_text_mask_1 = require("react-text-mask");
var __1 = require("../../..");
/**
 *
 * text input field component
 *
 * @param type html input type
 * @param error react-hook-form error
 * @param titleProps custom title typography
 * @param textFieldProps custom textfield
 * @param t i18next key function
 * @param args react-hook-form register params. ex:) {...register('fieldName')
 */
exports.MaskedInputField = react_1.forwardRef(function (_a, ref) {
    var _b;
    var type = _a.type, error = _a.error, titleProps = _a.titleProps, textFieldProps = _a.textFieldProps, t = _a.t, placeholder = _a.placeholder, mask = _a.mask, inputProps = _a.inputProps, _c = _a.placeholderChar, placeholderChar = _c === void 0 ? '\u2000' : _c, registers = __rest(_a, ["type", "error", "titleProps", "textFieldProps", "t", "placeholder", "mask", "inputProps", "placeholderChar"]);
    var name = registers.name;
    var _d = textFieldProps || {}, sx = _d.sx, textFieldArgs = __rest(_d, ["sx"]);
    var patternMask = react_1.useMemo(function () { return mask.split('').map(function (item) { var _a; return (_a = maskDefinitions[item]) !== null && _a !== void 0 ? _a : item; }); }, []);
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1", color: "text.secondary" }, titleProps, { children: (_b = titleProps === null || titleProps === void 0 ? void 0 : titleProps.children) !== null && _b !== void 0 ? _b : t(name + "Title") }), void 0), jsx_runtime_1.jsx(__1.TextField, __assign({ type: type, inputRef: ref }, registers, { placeholder: placeholder, error: !!error, helperText: (error === null || error === void 0 ? void 0 : error.message) || ' ', fullWidth: true, sx: __assign({}, sx) }, textFieldArgs, { InputProps: {
                    inputProps: __assign(__assign(__assign({}, inputProps), { inputRef: ref, mask: patternMask, placeholderChar: placeholderChar, sx: { px: 4, py: 3 } }), registers),
                    inputComponent: TextMaskInput,
                } }), void 0)] }, void 0));
});
var maskDefinitions = {
    '0': /\d/,
    a: /\w/,
};
var TextMaskInput = react_1.forwardRef(function (_a, ref) {
    var inputRef = _a.inputRef, props = __rest(_a, ["inputRef"]);
    return (jsx_runtime_1.jsx(react_text_mask_1.default, __assign({}, props, { ref: function (ref) {
            inputRef && inputRef(ref ? ref.inputElement : null);
        }, keepCharPositions: true }), void 0));
});
