"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var User = function (props) {
    var id = react_1.useMemo(function () { return Math.round(Math.random() * 10000); }, []);
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsxs("g", __assign({ clipPath: "url(#" + id + ")" }, { children: [jsx_runtime_1.jsx("path", { d: "M12 16C8.1 16 5 12.9 5 9C5 5.1 8.1 2 12 2C15.9 2 19 5.1 19 9C19 12.9 15.9 16 12 16ZM12 4C9.2 4 7 6.2 7 9C7 11.8 9.2 14 12 14C14.8 14 17 11.8 17 9C17 6.2 14.8 4 12 4Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M21.1 21.2C20.8 21.2 20.4 21 20.2 20.7C19.4 19.3 18.2 18.1 16.7 17.2C13.8 15.5 10.1 15.5 7.2 17.2C5.8 18 4.6 19.2 3.7 20.7C3.4 21.2 2.8 21.3 2.3 21.1C1.8 20.8 1.7 20.2 1.9 19.7C2.9 18 4.4 16.5 6.1 15.5C9.6 13.5 14.1 13.5 17.6 15.5C19.3 16.5 20.8 18 21.8 19.7C22.1 20.2 21.9 20.8 21.4 21.1C21.4 21.2 21.3 21.2 21.1 21.2Z", fill: "currentColor" }, void 0)] }), void 0), jsx_runtime_1.jsx("defs", { children: jsx_runtime_1.jsx("clipPath", __assign({ id: id.toString() }, { children: jsx_runtime_1.jsx("rect", { width: "24", height: "24", fill: "white" }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
exports.User = User;
