"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DownArrow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var DownArrow = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.16956 7.06574C3.68554 6.60709 4.47563 6.65356 4.93427 7.16954L12 15.1185L19.0658 7.16954C19.5244 6.65356 20.3145 6.60709 20.8305 7.06574C21.3464 7.52438 21.3929 8.31447 20.9343 8.83045L12 18.8815L3.06575 8.83045C2.6071 8.31447 2.65358 7.52438 3.16956 7.06574Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.DownArrow = DownArrow;
