"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transition = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Transition = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.06575 20.8304C6.6071 20.3145 6.65358 19.5244 7.16956 19.0657L15.1185 12L7.16956 4.93425C6.65358 4.4756 6.6071 3.68551 7.06575 3.16953C7.5244 2.65355 8.31449 2.60708 8.83047 3.06572L18.8815 12L8.83047 20.9342C8.31449 21.3929 7.5244 21.3464 7.06575 20.8304Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Transition = Transition;
