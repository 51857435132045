"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Share = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Share = function (props) { return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "17.5", cy: "17.5", r: "17", stroke: "#54656D" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.833 15.139c.46 0 .834.373.834.833v6.667a.694.694 0 0 0 .694.694H22.64a.694.694 0 0 0 .694-.694v-6.667a.833.833 0 0 1 1.667 0v6.667A2.361 2.361 0 0 1 22.639 25H12.36A2.36 2.36 0 0 1 10 22.639v-6.667c0-.46.373-.833.833-.833zM16.869 9.41a.939.939 0 0 1 1.262 0l3.274 3.056a.795.795 0 0 1 0 1.179.938.938 0 0 1-1.263 0L17.5 11.179l-2.642 2.466a.938.938 0 0 1-1.263 0 .795.795 0 0 1 0-1.179l3.274-3.055z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.5 9.167c.46 0 .833.373.833.833v9.097a.833.833 0 0 1-1.666 0V10c0-.46.373-.833.833-.833z", fill: "currentColor" }, void 0)] }), void 0) }), void 0)); };
exports.Share = Share;
