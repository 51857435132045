"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwipeGuide = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var system_1 = require("@mui/system");
/**
 *
 */
var SwipeGuide = function (_a) {
    var iconProps = _a.iconProps;
    var swing = system_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    20% {\n      transform: rotate3d(0, 0, 1, -20deg);\n    }\n    70% {\n      transform: rotate3d(0, 0, 1, 15deg);\n    }\n  "], ["\n    20% {\n      transform: rotate3d(0, 0, 1, -20deg);\n    }\n    70% {\n      transform: rotate3d(0, 0, 1, 15deg);\n    }\n  "])));
    return (jsx_runtime_1.jsx(material_1.Box, __assign({ sx: {
            '.thumb-up': {
                animation: swing + " 2s ease-in-out infinite",
                transformOrigin: 'bottom center',
            },
        } }, { children: jsx_runtime_1.jsx(Icon, __assign({ color: "primary" }, iconProps), void 0) }), void 0));
};
exports.SwipeGuide = SwipeGuide;
var Icon = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 130 130" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "115", height: "115", viewBox: "0 0 130 130", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M103.694 88.039L103.695 88.0369L111.658 63.0129C112.882 59.3919 110.759 55.3713 107.115 54.2417C103.994 53.1877 100.489 54.6495 98.9329 57.4607L98.9458 57.4215L98.9464 57.4195C100.129 53.8024 98.1372 49.8943 94.5193 48.7145L94.3978 48.6689L94.3336 48.6448L94.2683 48.6243C92.4972 48.0687 90.6186 48.2455 88.9988 49.0726L88.9801 49.0821L88.9617 49.092C87.6938 49.7663 86.6606 50.7978 86.0066 52.0815C86.8524 48.3021 84.5923 44.225 80.7897 43.0777C78.8504 42.4607 76.7685 42.6314 74.9371 43.5798L74.9289 43.584L74.9207 43.5883C73.4914 44.342 72.3372 45.5025 71.6107 46.9451L82.474 12.8574C82.4745 12.856 82.4749 12.8545 82.4754 12.8531C83.1075 10.8862 82.8981 8.80324 81.9883 7.01607L81.9802 7.0001L81.9718 6.98426C81.0187 5.17655 79.4283 3.85385 77.4987 3.23246C75.5306 2.59865 73.4458 2.80767 71.6573 3.71817L71.6414 3.7263L71.6255 3.73466C69.8178 4.68781 68.4951 6.27817 67.8737 8.20774L67.8715 8.21462L53.841 52.2185L50.1564 51.0342L50.1534 51.0332C46.5897 49.8929 42.8418 51.8936 41.7136 55.3839L41.7101 55.395L37.9926 67.0815C37.9925 67.0821 37.9923 67.0826 37.9921 67.0832C32.2072 85.2092 42.2415 104.626 60.3647 110.411C78.4912 116.197 97.909 106.163 103.694 88.039ZM53.8969 52.2365L53.8967 52.2364L53.8969 52.2365Z", fill: "white", stroke: "black", strokeWidth: "5", className: "thumb-up" }, void 0) }), void 0) }), void 0));
};
var templateObject_1;
