"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NarrowDown = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var NarrowDown = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M20.25 15.374h-1.985a2.623 2.623 0 0 0-5.03 0H3.75a.75.75 0 1 0 0 1.5h9.485a2.624 2.624 0 0 0 5.03 0h1.985a.75.75 0 1 0 0-1.5zm-4.5 1.876a1.125 1.125 0 1 1 0-2.25 1.125 1.125 0 0 1 0 2.25zm-12-8.626h3.485a2.625 2.625 0 0 0 5.03 0h7.985a.75.75 0 1 0 0-1.5h-7.985a2.624 2.624 0 0 0-5.03 0H3.75a.75.75 0 0 0 0 1.5zm6-1.875a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.NarrowDown = NarrowDown;
