"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioButtonsGroup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var __1 = require("../../..");
/**
 *
 * text input field component
 *
 * @param type html input type
 * @param error react-hook-form error
 * @param titleProps custom title typography
 * @param textFieldProps custom textfield
 * @param t i18next key function
 * @param args react-hook-form register params. ex:) {...register('fieldName')
 */
exports.RadioButtonsGroup = react_1.forwardRef(function (_a, ref) {
    var _b;
    var title = _a.title, value = _a.value, error = _a.error, titleProps = _a.titleProps, listItems = _a.listItems, onChange = _a.onChange, t = _a.t, registers = __rest(_a, ["title", "value", "error", "titleProps", "listItems", "onChange", "t"]);
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1", color: "text.secondary" }, titleProps, { children: (_b = titleProps === null || titleProps === void 0 ? void 0 : titleProps.children) !== null && _b !== void 0 ? _b : t(title + "Title") }), void 0), jsx_runtime_1.jsx(__1.RadioButtons, __assign({ ref: ref, value: value, onChange: onChange, listItems: listItems !== null && listItems !== void 0 ? listItems : [] }, registers), void 0), !!error && jsx_runtime_1.jsx(material_1.FormHelperText, __assign({ error: true }, { children: error === null || error === void 0 ? void 0 : error.message }), void 0)] }, void 0));
});
