"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wallpaper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Wallpaper = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.333 3.333h13.333c.92 0 1.667.748 1.667 1.667v10c0 .919-.748 1.666-1.667 1.666H3.333c-.92 0-1.667-.747-1.667-1.666V5c0-.92.748-1.667 1.667-1.667zm0 1.667v10h13.335l-.002-10H3.333zm2.916 4.166a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5zm2.5 2.5L7.5 10 5 13.333h10l-3.75-5-2.5 3.333z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Wallpaper = Wallpaper;
