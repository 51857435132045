"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateFontSize = exports.calculateSize = exports.ItemImageArea = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Icons_1 = require("../Icons");
/**
 * アイテムサムネイル用コンポーネント（長方形・正方形共通）
 * 60px x 96pxの枠の中に、画像を横幅を100%で合わせてセンタリングする
 *
 * @param src 画像のurl
 * @param fullWidth
 * @param noItem
 * @param size
 * @param href
 * @param onClick
 * @param align
 */
var ItemImageArea = function (_a) {
    var src = _a.src, noItem = _a.noItem, fullWidth = _a.fullWidth, _b = _a.size, size = _b === void 0 ? 'medium' : _b, href = _a.href, onClick = _a.onClick, _c = _a.alignItems, alignItems = _c === void 0 ? 'center' : _c;
    var _d = exports.calculateSize(size), width = _d.width, height = _d.height;
    return (jsx_runtime_1.jsxs(material_1.ButtonBase, __assign({ disableRipple: !href, 
        /**
         * @todo
         * component={!!href ? 'a' : 'button'}などでhrefがある時aタグ指定すればtype error消えるが、
         * アプリケーション側でcomponentを注入（RouterLinkBehavior）を注入しているのが上書きされてしまう。
         * よってSPA遷移せずにバグの原因になってしまうので、当コンポーネント側でprops:componentの指定はせずにhrefの型エラーはignoreする。
         * 改善タスクで整理予定 → https://app.clickup.com/t/201zrtg
         */
        // @ts-ignore
        href: href, sx: {
            position: 'relative',
            width: fullWidth ? '100%' : width !== null && width !== void 0 ? width : 95,
            height: height,
            alignItems: alignItems,
        }, onClick: onClick }, { children: [jsx_runtime_1.jsx(material_1.Box, { component: "img", src: src, alt: "item_image", width: "100%", sx: {
                    objectFit: 'contain',
                    objectPosition: 'center',
                    opacity: noItem ? '0.05' : 1,
                    maxHeight: '100%',
                } }, void 0), noItem && (jsx_runtime_1.jsx(material_1.Stack, __assign({ sx: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }, direction: "row", justifyContent: "center" }, { children: jsx_runtime_1.jsx(material_1.Stack, __assign({ justifyContent: "center" }, { children: jsx_runtime_1.jsx(Icons_1.Question, { sx: { color: 'grey.600' }, fontSize: exports.calculateFontSize(size) }, void 0) }), void 0) }), void 0))] }), void 0));
};
exports.ItemImageArea = ItemImageArea;
var calculateSize = function (size) {
    switch (size) {
        case 'xx-small':
            return {
                width: 30,
                height: 48,
            };
        case 'x-small':
            return {
                width: 48,
                height: 76,
            };
        case 'small':
            return {
                width: 60,
                height: 96,
            };
        case 'medium-small':
            return {
                width: 72,
                height: 115,
            };
        case 'medium':
            return {
                width: 104,
                height: 166,
            };
        case 'large':
            return {
                width: 175,
                height: 280,
            };
        case 'large-square':
            return {
                width: 280,
                height: 280,
            };
        case 'x-large':
            return {
                width: 220,
                height: 352,
            };
        case 'x-large-square':
            return {
                width: 280,
                height: 280,
            };
        case 'xx-large':
            return {
                width: 250,
                height: 400,
            };
    }
};
exports.calculateSize = calculateSize;
var calculateFontSize = function (size) {
    switch (size) {
        case 'xx-small':
        case 'x-small':
        case 'small':
            return 'small';
        case 'medium-small':
        case 'medium':
            return 'medium';
        case 'large':
        case 'large-square':
        case 'x-large':
        case 'x-large-square':
        case 'xx-large':
            return 'large';
    }
};
exports.calculateFontSize = calculateFontSize;
