"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnedItems = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var OwnedItems = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.55 19.5H13.3C14.4 19.5 15.3 18.6 15.2 17.5V6.07618L19 6.8L16.9 18.8C16.8 19.4 17.2 19.9 17.7 20H17.9C18.4 20 18.8 19.7 18.9 19.2L21 7.2C21.2 6.1 20.5 5 19.4 4.8L15.2 4C15.1992 3.99986 15.1983 3.99972 15.1975 3.99958C15.1447 2.94575 14.2663 2.1 13.2 2.1H5C3.9 2.1 3 3 3 4.1V17.5C3 18.6 3.9 19.5 5 19.5H6.05636C6.08649 19.7082 6.16108 19.9148 6.29997 20.1C6.59997 20.5 7.19997 20.9 7.79997 21L14.9 22H15C15.5 22 15.9 21.6 15.9 21.1C16 20.6 15.6 20.1 15.1 20L11.55 19.5ZM5 5.1V4.1H13.3V16.5V17.5H5V5.1Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.OwnedItems = OwnedItems;
