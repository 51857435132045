"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Check = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Check = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M9.5 19C9.2 19 9 18.9 8.8 18.7L2.3 12.5C1.9 12.1 1.9 11.5 2.3 11.1C2.7 10.7 3.3 10.7 3.7 11.1L9.5 16.6L20.3 4.7C20.7 4.3 21.3 4.3 21.7 4.6C22.1 5 22.1 5.6 21.8 6L10.2 18.7C10 18.9 9.8 19 9.5 19Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Check = Check;
