import { PaletteOptions } from '@mui/material'

import { defaultTheme } from './defaultTheme'

/**
 * Light Theme Palette
 */
export const palette: PaletteOptions = {
  mode: 'light',
  black: '#000000',
  white: '#FFFFFF',
  background: {
    default: '#FFFFFF',
  },
  like: {
    tap: '#F83D53',
  },
  icon: {
    line: '#798a94',
    background: '#f7f9fa',
  },
  bonus: {
    main: '#fad862',
  },
  grey: {
    50: '#F7F9FA',
    100: '#E5ECEF',
    200: '#D2DEE2',
    300: '#C1D0D7',
    400: '#A7B8C0',
    500: '#97A7B0',
    600: '#798A94',
    700: '#54656D',
    800: '#334752',
    900: '#20343F',
  },
  primary: {
    main: '#1970D4',
    light: '#F1F8FF',
    dark: '#1341A3',
    gradient:
      'linear-gradient(102.8deg, #7EC5A3 0.39%, #498CB8 62.89%, #296AC5 100.31%)',
  },
  secondary: {
    main: '#008484',
    light: '#e0f7f7',
    dark: '#04615b',
  },
  error: {
    main: '#D32F2F',
    light: '#FFCDD2',
  },
  info: {
    main: '#1990F6',
    light: '#e2f1fe',
    dark: '#1970D4',
  },
  warning: {
    main: '#D36500',
    light: '#EFDCB2',
    dark: '#B45501',
  },
  text: {
    primary: '#20343F',
    secondary: '#798A94',
    hint: '#A7B8C0',
    disabled: '#C1D0D7',
  },
}

export const defaultLightTheme = {
  ...defaultTheme,
  palette,
}
