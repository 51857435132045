"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rectangle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Rectangle = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M19.6804 7.56002H4.32004C3.78984 7.56002 3.36002 7.98984 3.36002 8.52004V15.4802C3.36002 16.0104 3.78984 16.4402 4.32004 16.4402H19.6804C20.2106 16.4402 20.6404 16.0104 20.6404 15.4802V8.52004C20.6404 7.98984 20.2106 7.56002 19.6804 7.56002Z", stroke: "currentColor", strokeWidth: "1.60003" }, void 0) }), void 0) }), void 0));
};
exports.Rectangle = Rectangle;
