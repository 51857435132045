"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberShip = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var MemberShip = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H11C11.2652 3 11.5196 3.10536 11.7071 3.29289C11.8946 3.48043 12 3.73478 12 4C12 4.26522 11.8946 4.51957 11.7071 4.70711C11.5196 4.89464 11.2652 5 11 5H6C5.73478 5 5.48043 5.10536 5.29289 5.29289C5.10536 5.48043 5 5.73478 5 6V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H11C11.2652 19 11.5196 19.1054 11.7071 19.2929C11.8946 19.4804 12 19.7348 12 20C12 20.2652 11.8946 20.5196 11.7071 20.7071C11.5196 20.8946 11.2652 21 11 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V6C3 5.20435 3.31607 4.44129 3.87868 3.87868ZM14 7.00021C14.2652 7.00021 14.5195 7.10553 14.707 7.293C14.8945 7.48053 14.9998 7.73484 14.9998 8C14.9998 8.26516 14.8945 8.51947 14.707 8.707L12.414 11H20C20.2652 11 20.5196 11.1054 20.7071 11.2929C20.8947 11.4804 21 11.7348 21 12C21 12.2652 20.8947 12.5196 20.7071 12.7071C20.5196 12.8946 20.2652 13 20 13H12.414L14.707 15.293C14.8892 15.4816 14.99 15.7342 14.9877 15.9964C14.9854 16.2586 14.8803 16.5094 14.6948 16.6948C14.5094 16.8802 14.2586 16.9854 13.9964 16.9877C13.7342 16.99 13.4816 16.8892 13.293 16.707L9.29303 12.707C9.10556 12.5195 9.00024 12.2652 9.00024 12C9.00024 11.7348 9.10556 11.4805 9.29303 11.293L13.293 7.293C13.4806 7.10553 13.7349 7.00021 14 7.00021Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.MemberShip = MemberShip;
