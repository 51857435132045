import { config } from '../../config'
import * as Adapters from './Adapter'
import { AuthAdapter, User } from './auth.interface'

export function generateAdapter(adapter: string): AuthAdapter<User | null> {
  if (adapter === 'firebase' && config.firebase) {
    return Adapters.adaptFirebaseAuth(config.firebase)
  } else if (adapter === 'auth0' && config.auth0) {
    return Adapters.adaptAuth0(config.auth0)
  }
  throw new Error('adapter is unknown: ' + adapter)
}
