"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Timer = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Timer = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M15.0703 1.01172H9.07032V3.01172H15.0703V1.01172ZM11.0703 14.0117H13.0703V8.01172H11.0703V14.0117ZM19.1003 7.39172L20.5204 5.97172C20.0904 5.46172 19.6203 4.98172 19.1103 4.56172L17.6903 5.98172C16.1403 4.74172 14.1903 4.00172 12.0703 4.00172C7.10032 4.00172 3.07032 8.03172 3.07032 13.0017C3.07032 17.9717 7.09032 22.0018 12.0703 22.0018C17.0503 22.0018 21.0704 17.9717 21.0704 13.0017C21.0704 10.8917 20.3304 8.94172 19.1003 7.39172ZM12.0703 20.0118C8.20032 20.0118 5.07032 16.8817 5.07032 13.0117C5.07032 9.14172 8.20032 6.01172 12.0703 6.01172C15.9403 6.01172 19.0703 9.14172 19.0703 13.0117C19.0703 16.8817 15.9403 20.0118 12.0703 20.0118Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Timer = Timer;
