"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonSet = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
/**
 * ボタンを2つ並べたコンポーネント
 *
 * @param variant 並び順、指定無しはhorizontal:水平並び
 * @param size ボタンと間隔を含めた全体サイズ（デフォルト:large）
 * @param primaryButton メインボタン
 * @param secondaryButton セカンドボタン
 */
var ButtonSet = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? 'horizontal' : _b, _c = _a.size, size = _c === void 0 ? 'large' : _c, primaryButton = _a.primaryButton, secondaryButton = _a.secondaryButton;
    return (jsx_runtime_1.jsxs(material_1.Grid, __assign({ container: true, direction: variant === 'horizontal' ? 'row-reverse' : 'column', justifyContent: "center", alignItems: "center", spacing: size === 'large' ? 6 : 4 }, { children: [jsx_runtime_1.jsx(material_1.Grid, __assign({ item: true }, { children: primaryButton }), void 0), jsx_runtime_1.jsx(material_1.Grid, __assign({ item: true }, { children: secondaryButton }), void 0)] }), void 0));
};
exports.ButtonSet = ButtonSet;
