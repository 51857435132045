"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QRCode = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var QRCode = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "25", height: "25", viewBox: "0 0 25 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M16.75 17.25V16.25H13.75V13.25H16.75V15.25H18.75V17.25H17.75V19.25H15.75V21.25H13.75V18.25H15.75V17.25H16.75ZM21.75 21.25H17.75V19.25H19.75V17.25H21.75V21.25ZM3.75 3.25H11.75V11.25H3.75V3.25ZM5.75 5.25V9.25H9.75V5.25H5.75ZM13.75 3.25H21.75V11.25H13.75V3.25ZM15.75 5.25V9.25H19.75V5.25H15.75ZM3.75 13.25H11.75V21.25H3.75V13.25ZM5.75 15.25V19.25H9.75V15.25H5.75ZM18.75 13.25H21.75V15.25H18.75V13.25ZM6.75 6.25H8.75V8.25H6.75V6.25ZM6.75 16.25H8.75V18.25H6.75V16.25ZM16.75 6.25H18.75V8.25H16.75V6.25Z", fill: "white" }, void 0) }), void 0) }), void 0));
};
exports.QRCode = QRCode;
