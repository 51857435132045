"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var MyPage = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.1324 5C10.3114 5 8.87061 6.44075 8.87061 8.17482C8.87061 9.90888 10.3114 11.3496 12.1324 11.3496C13.9534 11.3496 15.3942 9.90888 15.3942 8.17482C15.3942 6.44075 13.9534 5 12.1324 5ZM6.87061 8.17482C6.87061 5.29751 9.24593 3 12.1324 3C15.0189 3 17.3942 5.29751 17.3942 8.17482C17.3942 11.0521 15.0189 13.3496 12.1324 13.3496C9.24593 13.3496 6.87061 11.0521 6.87061 8.17482Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.87062 15.7352C5.72453 15.7352 4 17.4539 4 19.5064C4 20.0587 3.55228 20.5064 3 20.5064C2.44772 20.5064 2 20.0587 2 19.5064C2 16.3106 4.65903 13.7352 7.87062 13.7352H16.3942C19.6058 13.7352 22.2648 16.3106 22.2648 19.5064C22.2648 20.0587 21.8171 20.5064 21.2648 20.5064C20.7125 20.5064 20.2648 20.0587 20.2648 19.5064C20.2648 17.4539 18.5403 15.7352 16.3942 15.7352H7.87062Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.MyPage = MyPage;
