"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploader = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var __1 = require("../../..");
/**
 * Form用ファイルアップロードエリア
 * stateでアップロードファイル情報を持ち、それを元に描画を切り替える
 *
 * @param variant レイアウト（タイトル＋インプットエリアの並び）
 * horizontal(デフォルト値) 横並び、SP時は縦並び | vertical 縦並び（PC,SP時両方）
 * @param content { title: 見出しテキスト, contentNode: 見出し下に表示するNode }
 * @param fileUploadInfo ドロップゾーン + アップロード結果表示エリア用Props
 * @param sx
 * @param shape
 * @param error
 */
var FileUploader = function (_a) {
    var _b;
    var _c = _a.variant, variant = _c === void 0 ? 'horizontal' : _c, content = _a.content, fileUploadInfo = _a.fileUploadInfo, _d = _a.resource, resourceValue = _d === void 0 ? null : _d, sx = _a.sx, shape = _a.shape, _e = _a.error, error = _e === void 0 ? false : _e, disabled = _a.disabled, requireLabel = _a.requireLabel, t = _a.t;
    var isVertical = variant === 'vertical';
    var title = content.title, contentNode = content.contentNode;
    var type = fileUploadInfo.type, progress = fileUploadInfo.progress, description = fileUploadInfo.description, onDrop = fileUploadInfo.onDrop, onClose = fileUploadInfo.onClose, onConfirmClose = fileUploadInfo.onConfirmClose;
    var _f = react_1.useState(null), resource = _f[0], setResource = _f[1];
    react_1.useEffect(function () {
        if (onConfirmClose) {
            setResource(resourceValue);
        }
        else {
            setResource(function (prevState) { return (prevState ? prevState : resourceValue); });
        }
    }, [resourceValue]);
    var dropzoneSize = react_1.useMemo(function () {
        if (type !== 'image') {
            return { width: 280, height: 180 };
        }
        if (shape === 'square') {
            return { width: 250, height: 250 };
        }
        if (shape === 'collection') {
            return { width: 280, height: 160 };
        }
        return { width: 250, height: 400 };
    }, [type, shape]);
    var handleDrop = function (file) {
        return onDrop(file)
            .then(function (resource) {
            if (!resource) {
                return;
            }
            setResource(resource);
            return resource;
        })
            .catch(function () { });
    };
    var handleClose = function () {
        if (onConfirmClose) {
            onConfirmClose();
        }
        else {
            onClose();
            setResource(null);
        }
    };
    return (jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: __assign({ display: 'flex', flexDirection: 'column', py: 7, boxSizing: 'border-box' }, sx) }, { children: [jsx_runtime_1.jsx(material_1.Box, __assign({ sx: isVertical
                    ? {
                        width: '100%',
                        mb: 2,
                    }
                    : {
                        mb: { xs: 2, md: 0 },
                        boxSizing: 'border-box',
                        display: 'flex',
                        alignItems: 'center',
                    } }, { children: jsx_runtime_1.jsxs(material_1.Stack, __assign({ direction: "row", alignItems: "center", spacing: 1 }, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h5", color: disabled ? 'text.disabled' : 'text.primary' }, { children: title }), void 0), !!(requireLabel === null || requireLabel === void 0 ? void 0 : requireLabel.display) && (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "caption", color: "error.main" }, { children: (_b = requireLabel === null || requireLabel === void 0 ? void 0 : requireLabel.label) !== null && _b !== void 0 ? _b : t('labelRequired') }), void 0))] }), void 0) }), void 0), jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    width: '100%',
                } }, { children: [jsx_runtime_1.jsx(material_1.Box, __assign({ sx: { mb: 2 } }, { children: contentNode }), void 0), resource ? (jsx_runtime_1.jsx(__1.MaterialArea, { type: type, disabled: disabled, resource: resource, onClose: handleClose, sx: dropzoneSize }, void 0)) : (jsx_runtime_1.jsx(__1.Dropzone, { type: type, progress: progress, onDrop: handleDrop, disabled: disabled, description: description, error: error, dropzoneProps: { sx: __assign({}, dropzoneSize) }, t: t }, void 0))] }), void 0)] }), void 0));
};
exports.FileUploader = FileUploader;
