"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchInputField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var __1 = require("../../..");
var Icons_1 = require("../../../DataDisplay/Icons");
/**
 *
 * search input field component
 *
 * @param error react-hook-form error
 * @param titleProps custom title typography
 * @param textFieldProps custom textfield
 * @param t i18next key function
 * @param args react-hook-form register params. ex:) {...register('fieldName')
 */
exports.SearchInputField = react_1.forwardRef(function (_a, ref) {
    var error = _a.error, titleProps = _a.titleProps, textFieldProps = _a.textFieldProps, t = _a.t, registers = __rest(_a, ["error", "titleProps", "textFieldProps", "t"]);
    var name = registers.name;
    var _b = textFieldProps || {}, sx = _b.sx, InputProps = _b.InputProps, textFieldArgs = __rest(_b, ["sx", "InputProps"]);
    return (jsx_runtime_1.jsx(__1.TextField, __assign({ inputRef: ref }, registers, { placeholder: t(name + "Placeholder"), error: !!error, helperText: null, fullWidth: true, InputProps: __assign({ startAdornment: (jsx_runtime_1.jsx(material_1.InputAdornment, __assign({ position: "start" }, { children: jsx_runtime_1.jsx(Icons_1.Search, { fontSize: "small", sx: { color: 'grey.700' } }, void 0) }), void 0)), sx: {
                borderRadius: '22px',
                backgroundColor: 'grey.50',
                '.MuiInputBase-input': {
                    backgroundColor: 'grey.50',
                    borderTopRightRadius: '22px',
                    borderBottomRightRadius: '22px',
                },
                '.MuiOutlinedInput-input': {
                    padding: '10px 8px',
                },
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            } }, InputProps), sx: __assign({ backgroundColor: 'unset', margin: 0, py: 2 }, sx) }, textFieldArgs), void 0));
});
