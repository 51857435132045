import { FieldFunctionOptions } from '@apollo/client'
import { Reference, relayStylePagination } from '@apollo/client/utilities'
import {
  RelayFieldPolicy,
  TIncomingRelay,
} from '@apollo/client/utilities/policies/pagination'

export const utoniqPagination = <T = Reference>(
  /** 変更があったときにキャッシュを更新するためのキー引数 */
  keyArgs?: string[],
  /** 戻り値に処理を加える場合に用いる関数 */
  filterOption?: (
    value: TIncomingRelay<T>,
    options: FieldFunctionOptions<Record<string, any>, Record<string, any>>
  ) => TIncomingRelay<T>
): RelayFieldPolicy<T> => {
  const { read, merge } = relayStylePagination<T>()

  return {
    keyArgs,
    read(existing, options) {
      const value = read?.(existing, {
        ...options,
        args: {
          offset: options.args?.pageInput?.limit,
          after: options.args?.pageInput?.cursor,
        },
      })

      return filterOption
        ? filterOption(value as TIncomingRelay<T>, options)
        : value
    },
    merge:
      typeof merge === 'boolean'
        ? merge
        : merge === undefined
        ? undefined
        : (existing, incoming, options) => {
            return merge(existing, incoming, {
              ...options,
              args: {
                offset: options.args?.pageInput?.limit,
                after: options.args?.pageInput?.cursor,
              },
            })
          },
  }
}
