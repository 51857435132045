"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniqueProtect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var UniqueProtect = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "17.5", cy: "17.5", r: "17.5", fill: "#1640A1" }, void 0), jsx_runtime_1.jsx("path", { d: "M17.5 7.875L9.625 11.375V16.625C9.625 21.4812 12.985 26.0225 17.5 27.125C22.015 26.0225 25.375 21.4812 25.375 16.625V11.375L17.5 7.875ZM23.625 16.625C23.625 20.58 21.0175 24.2288 17.5 25.3138C13.9825 24.2288 11.375 20.58 11.375 16.625V12.5125L17.5 9.79125L23.625 12.5125V16.625ZM13.4837 17.1413L12.25 18.375L15.75 21.875L22.75 14.875L21.5163 13.6325L15.75 19.3988L13.4837 17.1413Z", fill: "#F1F5F6" }, void 0)] }), void 0) }), void 0));
};
exports.UniqueProtect = UniqueProtect;
