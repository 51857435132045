"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuProfile = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var MenuProfile = function (_a) {
    var avatarUrl = _a.avatarUrl, text = _a.text, divider = _a.divider, sx = _a.sx;
    return (jsx_runtime_1.jsxs(material_1.ListItem, __assign({ divider: divider, sx: __assign({ height: { xs: 88, md: 104 }, p: { xs: 6, md: 7 }, boxSizing: 'border-box' }, sx) }, { children: [jsx_runtime_1.jsx(material_1.ListItemAvatar, { children: jsx_runtime_1.jsx(material_1.Avatar, { src: avatarUrl, alt: "avatar_url" }, void 0) }, void 0), jsx_runtime_1.jsx(material_1.ListItemText, { primary: text, primaryTypographyProps: {
                    variant: 'caption',
                    color: 'grey.200',
                    whiteSpace: 'pre',
                } }, void 0)] }), void 0));
};
exports.MenuProfile = MenuProfile;
