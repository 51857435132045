"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuListItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var MenuListItem = function (_a) {
    var title = _a.title, subTitle = _a.subTitle, icon = _a.icon, sx = _a.sx, primaryTypographyProps = _a.primaryTypographyProps, secondaryTypographyProps = _a.secondaryTypographyProps, secondaryAction = _a.secondaryAction, props = __rest(_a, ["title", "subTitle", "icon", "sx", "primaryTypographyProps", "secondaryTypographyProps", "secondaryAction"]);
    return (jsx_runtime_1.jsxs(material_1.ListItemButton, __assign({ sx: __assign({ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: 16, height: { xs: 48, md: 56 }, px: { xs: 6, md: 7 }, py: { xs: 2, md: 4 }, boxSizing: 'border-box' }, sx) }, props, { children: [icon && jsx_runtime_1.jsx(material_1.ListItemIcon, { children: icon }, void 0), jsx_runtime_1.jsx(material_1.ListItemText, { primary: title, primaryTypographyProps: __assign({ variant: 'body2' }, primaryTypographyProps), secondary: subTitle, secondaryTypographyProps: __assign({ variant: 'subtitle2' }, secondaryTypographyProps) }, void 0), secondaryAction && (jsx_runtime_1.jsx(material_1.ListItemSecondaryAction, { children: secondaryAction }, void 0))] }), void 0));
};
exports.MenuListItem = MenuListItem;
