import { Box } from '@mui/material'
import type { FC, FormHTMLAttributes } from 'react'

import { OrganismFrameProps } from '@/core/architecture'

/**
 * Form ComponentはOrganismとみなす。
 */
export const Form: FC<
  OrganismFrameProps & FormHTMLAttributes<HTMLFormElement>
> = ({ children, ...props }) => (
  <Box sx={{ bgcolor: 'background.default' }} {...props} component="form">
    {children}
  </Box>
)
