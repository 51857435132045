"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraOff = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var CameraOff = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M3.71 2.29a1.004 1.004 0 1 0-1.42 1.42l2.8 2.79H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h14.08l1.21 1.22a1.001 1.001 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095l-18-18.01zm6.49 9.33 2.68 2.68a2 2 0 0 1-.88.2 2 2 0 0 1-2-2 2 2 0 0 1 .2-.88zM5 18.5a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h2.07l1.7 1.69A3.92 3.92 0 0 0 8 12.5a4 4 0 0 0 4 4 3.92 3.92 0 0 0 2.32-.77l2.77 2.77H5zm14-12h-1.28l-.31-1a3 3 0 0 0-2.85-2h-4.4a1 1 0 0 0 0 2h4.4a1 1 0 0 1 .95.68l.54 1.63a1 1 0 0 0 .95.69h2a1 1 0 0 1 1 1v5.84a1 1 0 0 0 2 0V9.5a3 3 0 0 0-3-3z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.CameraOff = CameraOff;
