"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Facebook = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Facebook = function (props) { return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "17.5", cy: "17.5", r: "17", stroke: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "m21.321 18.398.526-3.344h-3.242V12.88c0-.914.453-1.808 1.9-1.808H22V8.225c-.87-.14-1.75-.214-2.632-.225-2.668 0-4.41 1.604-4.41 4.504v2.55H12v3.344h2.958v8.088h3.647v-8.088h2.716z", fill: "currentColor" }, void 0)] }), void 0) }), void 0)); };
exports.Facebook = Facebook;
