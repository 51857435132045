"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LongPressButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var lab_1 = require("@mui/lab");
var material_1 = require("@mui/material");
var react_1 = require("react");
var useLongPress_1 = require("./useLongPress");
var LongPressButton = function (_a) {
    var children = _a.children, sx = _a.sx, _b = _a.width, width = _b === void 0 ? 244 : _b, _c = _a.height, height = _c === void 0 ? 44 : _c, onSubmit = _a.onSubmit, props = __rest(_a, ["children", "sx", "width", "height", "onSubmit"]);
    var _d = react_1.useState(false), loading = _d[0], setLoading = _d[1];
    var _e = react_1.useState(false), isProgress = _e[0], setIsProgress = _e[1];
    var animationRef = react_1.useRef();
    var submittingRef = react_1.useRef(false);
    var progressRef = react_1.useRef();
    var handleTouchStart = react_1.useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        function progressBar() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            //speed of progress
                            offset -= 6;
                            progress.style.strokeDashoffset = offset.toString();
                            animationRef.current = window.requestAnimationFrame(progressBar);
                            if (!(offset < 0)) return [3 /*break*/, 2];
                            setIsProgress(false);
                            if (submittingRef.current) {
                                return [2 /*return*/];
                            }
                            submittingRef.current = true;
                            setLoading(true);
                            return [4 /*yield*/, onSubmit()];
                        case 1:
                            _a.sent();
                            setLoading(false);
                            submittingRef.current = false;
                            progress.style.stroke = 'transparent';
                            window.cancelAnimationFrame(animationRef.current);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        var progress, borderLen, offset;
        var _a;
        return __generator(this, function (_b) {
            setIsProgress(true);
            progress = (_a = progressRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.progress path');
            if (!progress)
                return [2 /*return*/];
            borderLen = progress.getTotalLength() + 2;
            offset = borderLen;
            progress.style.stroke = 'black';
            progress.style.strokeDashoffset = borderLen.toString();
            progress.style.strokeDasharray = borderLen + ',' + borderLen;
            animationRef.current = window.requestAnimationFrame(progressBar);
            return [2 /*return*/];
        });
    }); }, [loading]);
    var handleTouchEnd = react_1.useCallback(function () {
        var _a;
        setIsProgress(false);
        if (animationRef.current) {
            window.cancelAnimationFrame(animationRef.current);
        }
        var progress = (_a = progressRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('.progress path');
        if (!progress)
            return;
        progress.style.stroke = 'transparent';
    }, []);
    var longPressEvent = useLongPress_1.useLongPress(handleTouchStart, handleTouchEnd);
    return (jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: { position: 'relative' } }, { children: [jsx_runtime_1.jsx(ProgressStyled, { width: width, height: height, sx: {
                    zIndex: 0,
                    path: { stroke: function (theme) { return theme.palette.grey['100']; } },
                } }, void 0), jsx_runtime_1.jsx(ProgressStyled, { forwardRef: progressRef, width: width, height: height }, void 0), jsx_runtime_1.jsx(lab_1.LoadingButton, __assign({ loading: loading }, props, { sx: __assign(__assign({ borderRadius: 1 }, sx), { width: width - (isProgress ? 6 : 0), height: height - (isProgress ? 6 : 0), m: isProgress ? '3px' : 0, transition: 'width 200ms, height 200ms, margin 200ms' }) }, longPressEvent, { children: children }), void 0)] }), void 0));
};
exports.LongPressButton = LongPressButton;
var ProgressStyled = material_1.styled(function (props) { return (jsx_runtime_1.jsx(material_1.Box, __assign({ ref: props.forwardRef }, props, { children: jsx_runtime_1.jsx(BorderSvg, { className: "progress", width: props.width, height: props.height }, void 0) }), void 0)); })(function () { return ({
    position: 'absolute',
    inset: 0,
    zIndex: 0,
    path: {
        strokeWidth: '2',
        fill: 'none',
    },
}); });
var BorderSvg = function (_a) {
    var width = _a.width, height = _a.height, _b = _a.strokeWidth, strokeWidth = _b === void 0 ? 2 : _b, className = _a.className;
    return (jsx_runtime_1.jsx("svg", __assign({ width: width, height: height, viewBox: "0 0 " + width + " " + height, preserveAspectRatio: "none", className: className }, { children: jsx_runtime_1.jsx("path", { d: "M" + width / 2 + " 0.5H" + (width - strokeWidth * 2) + "C" + (width - strokeWidth) + " 0.5 " + (width - 0.5) + " 2 " + (width - 0.5) + " 4V" + (height - strokeWidth * 2) + "C" + (width - 0.5) + " " + (height - strokeWidth) + " " + (width - strokeWidth) + " " + (height - 0.5) + " " + (width - strokeWidth * 2) + " " + (height - 0.5) + "H4C2 " + (height - 0.5) + " 0.5 " + (height - strokeWidth) + " 0.5 " + (height - strokeWidth * 2) + "V4C0.5 2 2 0.5 4 0.5Z" }, void 0) }), void 0));
};
