"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Feedback_1 = require("../../Feedback");
var Fade_1 = require("../Fade");
var CampaignType;
(function (CampaignType) {
    CampaignType["SQUARE"] = "square";
    CampaignType["RECTANGLE"] = "rectangle";
})(CampaignType || (CampaignType = {}));
var bannerSize = (_a = {},
    _a[CampaignType.SQUARE] = {
        width: 280,
        height: 280,
    },
    _a[CampaignType.RECTANGLE] = {
        width: 280,
        height: 350,
    },
    _a);
var CampaignModal = function (_a) {
    var _b, _c;
    var type = _a.type, open = _a.open, content = _a.content, ok = _a.ok, cancel = _a.cancel, banner = _a.banner, onClose = _a.onClose;
    return (jsx_runtime_1.jsx(Feedback_1.Modal, __assign({ open: open, onClose: function () { return onClose(); } }, { children: jsx_runtime_1.jsxs(material_1.Box, __assign({ id: "modal-inner", sx: {
                width: 280,
                borderRadius: 3,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'grey.100',
                outline: 'none',
            } }, { children: [jsx_runtime_1.jsx(material_1.Stack, __assign({ sx: __assign(__assign({}, bannerSize[type]), { borderTopLeftRadius: 12, borderTopRightRadius: 12, overflow: 'hidden' }) }, { children: jsx_runtime_1.jsx(Fade_1.Fade, __assign({ style: __assign({}, bannerSize[type]) }, { children: banner }), void 0) }), void 0), jsx_runtime_1.jsx(material_1.Stack, { children: typeof content === 'string' ? (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2", sx: { pt: 4, px: 5 }, align: "center" }, { children: content }), void 0)) : (content) }, void 0), jsx_runtime_1.jsxs(material_1.Stack, __assign({ spacing: 4, alignItems: "center", sx: { py: 6 } }, { children: [ok && (jsx_runtime_1.jsx(material_1.Button, __assign({ color: "primary", size: "large", onClick: (_b = ok.onClick) !== null && _b !== void 0 ? _b : onClose, sx: { minWidth: 212 } }, { children: ok.title }), void 0)), cancel && (jsx_runtime_1.jsx(material_1.Button, __assign({ variant: "text", color: "primary", onClick: (_c = cancel.onClick) !== null && _c !== void 0 ? _c : onClose }, { children: cancel.title }), void 0))] }), void 0)] }), void 0) }), void 0));
};
exports.CampaignModal = CampaignModal;
