"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraRotate = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var CameraRotate = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.72 6.5H19a3 3 0 0 1 3 3.05v8a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-8a3 3 0 0 1 3-3h1.28l.32-1A3 3 0 0 1 9.44 3.5h5.12a3 3 0 0 1 2.84 2l.32 1zm1.987 11.707A1 1 0 0 0 20 17.5v-8a1 1 0 0 0-1-1h-2a1 1 0 0 1-.9-.68l-.54-1.64a1 1 0 0 0-.95-.68H9.49a1 1 0 0 0-.95.68L8 7.82a1 1 0 0 1-1 .68H5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 .707-.293zM13.288 7.668a4.997 4.997 0 0 0-3.432.317l.847 1.612A3.18 3.18 0 0 1 15.18 12.5l-.001.091h-1.59l1.888 3.498a4.997 4.997 0 0 0-2.189-8.421zm-2.597 9.659a4.996 4.996 0 0 0 3.414-.294l-.846-1.612A3.18 3.18 0 0 1 8.82 12.5h1.59L8.49 8.942a4.997 4.997 0 0 0 2.201 8.383z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.CameraRotate = CameraRotate;
