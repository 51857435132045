"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Thread = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Thread = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.923 17.17c.252.415.55.803.877 1.13.6.6 1.4 1.1 2.2 1.4.8.3 1.7.4 2.6.3.9-.1 1.7-.4 2.5-.8.2-.1.4-.1.6-.1l2 .6-.6-2c-.1-.2 0-.4.1-.6.5-.9.8-1.9.8-2.9s-.2-2.1-.7-3c-.5-.9-1.2-1.7-2.1-2.2a5.35 5.35 0 0 0-1.825-.783c.064.323.107.651.125.983.1 1.8-.4 3.6-1.6 5.1-1.1 1.4-2.7 2.4-4.5 2.8a4.73 4.73 0 0 1-.477.07zm5.944-10.52A7.695 7.695 0 0 0 14.3 4.4c-1.3-1.3-3-2.1-4.8-2.2-1.9-.1-3.7.5-5.1 1.6C3 5 2 6.6 1.6 8.4c-.3 1.6 0 3.4.8 4.9l-.6 2c-.1.2-.1.5 0 .7.1.2.2.4.3.7.067.034.122.078.178.123.11.088.222.177.422.177.3 0 .5 0 .8-.1l2-.6c.817.446 1.69.726 2.577.84.331.861.858 1.595 1.523 2.26.8.8 1.8 1.4 2.8 1.8.8.3 1.7.5 2.6.5.2 0 .5 0 .8-.1.9-.1 1.9-.4 2.8-.9l2 .6c.2.1.5.1.7 0 .2 0 .4-.1.6-.3l.3-.6c.1-.2.1-.5 0-.7l-.6-2c.6-1.1.9-2.2.9-3.4 0-1.3-.3-2.6-.9-3.7-.6-1.1-1.5-2.1-2.6-2.8a7.692 7.692 0 0 0-3.133-1.15zm-6.8 9.066A.83.83 0 0 0 8.4 15.6c-.04.02-.078.042-.114.066C7.493 15.56 6.73 15.294 6 14.9c-.1-.1-.3-.1-.4-.1h-.3l-2 .6.6-2c.1-.2 0-.4-.1-.6-.7-1.3-1-2.8-.7-4.2.3-1.4 1.1-2.7 2.2-3.6 1.2-.9 2.6-1.3 4.1-1.3 1.5 0 2.9.7 3.9 1.7 1 1.1 1.6 2.4 1.7 3.9.1 1.5-.3 2.9-1.2 4.1-.9 1.1-2.2 1.9-3.6 2.2a5.874 5.874 0 0 1-1.133.116z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Thread = Thread;
