"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarNoImage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var AvatarNoImage = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 40 40" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "20", cy: "20", r: "20", fill: "#C1D0D7" }, void 0), jsx_runtime_1.jsx("path", { d: "M19.8296 21.0909C22.5973 21.0909 24.841 18.8931 24.841 16.1819C24.841 13.4706 22.5973 11.2728 19.8296 11.2728C17.0619 11.2728 14.8182 13.4706 14.8182 16.1819C14.8182 18.8931 17.0619 21.0909 19.8296 21.0909Z", stroke: "white", "stroke-width": "1.5", "stroke-miterlimit": "10" }, void 0), jsx_runtime_1.jsx("path", { d: "M9.09094 29.5065C9.09094 26.4208 11.6682 23.8961 14.8182 23.8961H24.8409C27.9909 23.8961 30.5682 26.4208 30.5682 29.5065", stroke: "white", "stroke-width": "1.5", "stroke-miterlimit": "10", "stroke-linecap": "round", "stroke-linejoin": "round" }, void 0)] }), void 0) }), void 0));
};
exports.AvatarNoImage = AvatarNoImage;
