"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Favorite = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Favorite = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C12.4006 2 12.7624 2.23898 12.9197 2.60733L15.2129 7.97811L21.0855 8.48155C21.4864 8.51592 21.8277 8.78731 21.9515 9.17024C22.0754 9.55316 21.9576 9.97299 21.6526 10.2356L17.1981 14.072L18.5356 19.7715C18.6274 20.1628 18.4761 20.5709 18.1515 20.8078C17.8269 21.0447 17.3921 21.0642 17.0475 20.8575L12 17.8288L6.95256 20.8575C6.60821 21.0641 6.17366 21.0447 5.84911 20.8082C5.52455 20.5717 5.37297 20.164 5.46418 19.7729L6.79378 14.072L2.34678 10.235C2.0422 9.97223 1.92475 9.55251 2.04869 9.1698C2.17262 8.78709 2.51382 8.51591 2.91463 8.48155L8.78721 7.97811L11.0804 2.60733C11.2376 2.23898 11.5995 2 12 2ZM12 5.54665L10.3907 9.31583C10.2456 9.65578 9.92474 9.88793 9.55646 9.9195L5.45059 10.2715L8.55831 12.9529C8.83988 13.1958 8.96338 13.575 8.87891 13.9371L7.94821 17.9276L11.4855 15.8052C11.8022 15.6151 12.1979 15.6151 12.5146 15.8052L16.048 17.9254L15.1125 13.9385C15.0273 13.5756 15.1511 13.1955 15.4335 12.9523L18.5465 10.2712L14.4436 9.9195C14.0753 9.88793 13.7545 9.65578 13.6094 9.31583L12 5.54665Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Favorite = Favorite;
