"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Background = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Background = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.99902 4H19.999C21.102 4 21.999 4.897 21.999 6V18C21.999 19.103 21.102 20 19.999 20H3.99902C2.89602 20 1.99902 19.103 1.99902 18V6C1.99902 4.897 2.89602 4 3.99902 4ZM3.99902 6V18H20.001L19.999 6H3.99902ZM7.49902 11C8.32745 11 8.99902 10.3284 8.99902 9.5C8.99902 8.67157 8.32745 8 7.49902 8C6.6706 8 5.99902 8.67157 5.99902 9.5C5.99902 10.3284 6.6706 11 7.49902 11ZM10.499 14L8.99902 12L5.99902 16H17.999L13.499 10L10.499 14Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Background = Background;
