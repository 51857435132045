"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TradeArrow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var TradeNegotiationStatus;
(function (TradeNegotiationStatus) {
    /** 交渉中 */
    TradeNegotiationStatus["InProgress"] = "in_progress";
    /** 不成立 */
    TradeNegotiationStatus["BrokenOff"] = "broken_off";
    /** キャンセル */
    TradeNegotiationStatus["Dropped"] = "dropped";
    /** 成立 */
    TradeNegotiationStatus["Agreed"] = "agreed";
    /** 完了 */
    TradeNegotiationStatus["Completed"] = "completed";
    /** エラー */
    TradeNegotiationStatus["Error"] = "error";
})(TradeNegotiationStatus || (TradeNegotiationStatus = {}));
var arrowColor = {
    in_progress: {
        top: 'primary.main',
        bottom: 'inherit',
    },
    broken_off: {
        top: 'inherit',
        bottom: 'inherit',
    },
    dropped: {
        top: 'inherit',
        bottom: 'inherit',
    },
    agreed: {
        top: 'secondary.main',
        bottom: 'secondary.main',
    },
    completed: {
        top: 'inherit',
        bottom: 'inherit',
    },
    error: {
        top: 'error.main',
        bottom: 'inherit',
    },
    default: {
        top: 'inherit',
        bottom: 'inherit',
    },
};
var TradeArrow = function (_a) {
    var _b = _a.status, status = _b === void 0 ? 'default' : _b, sx = _a.sx, props = __rest(_a, ["status", "sx"]);
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { sx: __assign({ 'path:first-of-type': {
                color: arrowColor[status].top,
            }, 'path:last-of-type': {
                color: arrowColor[status].bottom,
            } }, sx) }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { fill: "currentColor", d: "M16.02 6L-2.18557e-07 6L-3.49691e-07 9L18.02 9L24 9L16.02 1L16.02 6Z" }, void 0), jsx_runtime_1.jsx("path", { fill: "currentColor", d: "M7.98 18L24 18L24 15L5.98 15L0 15L7.98 23L7.98 18Z" }, void 0)] }), void 0) }), void 0));
};
exports.TradeArrow = TradeArrow;
