import identity from 'lodash/identity'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import { useEffect, useMemo, useState } from 'react'
import { ExtractRouteParams } from 'react-router'
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom'

import { config } from '@/config'

import type { routes, standaloneRoutes } from '../../app/router/routes'
import { getRoutes } from '../../app/router/routes'
import { isNativePlatform } from '../native/app'

/**
 * Routing用hook
 *
 */
export function useRouter<Params = {}, State = {}>() {
  const params = useParams()
  const location = useLocation<State>()
  const history = useHistory()

  const routerPages = mapValues(
    getRoutes(config.applicationType),
    'path'
  ) as RouterPaths

  return {
    routerPages,

    routerNames: mapValues(
      getRoutes(config.applicationType),
      'name'
    ) as RouterPaths,

    router: useMemo(() => {
      return {
        push: history.push,
        replace: history.replace,
        pathname: location.pathname,
        /** ブラウザバックを実行する。ブラウザバックできない場合はデフォルトのページに遷移する */
        goBack: (defaultBackPage?: string) => {
          if (!document) return

          if (isNativePlatform()) {
            if (!location?.key) {
              // replace current router to home
              history.replace(
                config?.applicationType === 'standalone'
                  ? generatePath(routerPages.ArtistTop, {
                      artistId: config.artistId,
                    })
                  : generatePath(routerPages.GlobalHome)
              )
              if (defaultBackPage) {
                history.push(defaultBackPage)
              }
              return
            }
            history.goBack()
            return
          }

          // デフォルトページに遷移する処理
          const replaceToDefaultPage = () =>
            history.replace(
              defaultBackPage ??
                (location?.pathname?.includes('console')
                  ? routerPages.ConsoleDashboard
                  : config?.applicationType === 'standalone' ||
                    location?.pathname?.includes('artists')
                  ? generatePath(routerPages.ArtistTop, {
                      artistId: config.artistId ?? '#',
                    })
                  : generatePath(routerPages.GlobalHome))
            )

          const referrer = document.referrer

          /**
           * 以下の条件を満たす場合はブラウザバックを実行する
           * 1. referrerが存在しないが、location.keyはある
           * 2. 同アプリドメインかつ現在のページではない値のreferrerが存在し、かつhistoryが存在する
           */
          const canGoBack =
            (!referrer && !!location.key) ||
            (!!referrer &&
              referrer !== document.location.href &&
              referrer.includes(document.location.hostname) &&
              history.length > 0)

          if (!canGoBack) return replaceToDefaultPage()
          tryGoBack().catch(() => replaceToDefaultPage())
        },

        // Merge params and parsed query string into single "query" object
        // so that they can be used interchangeably.
        // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
        query: {
          ...Object.fromEntries(new URLSearchParams(location.search)), // Convert string to object
          ...params,
        } as Params,
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, location, history]),

    /**
     * @see https://reactrouter.com/web/api/generatePath
     */
    generatePath,
    generateQueryPath,
    location,
  }
}

type RouterPaths = Record<
  keyof typeof routes & keyof typeof standaloneRoutes,
  string
>

export function useTrackPageView(
  callback: (props: { location: ReturnType<typeof useLocation> }) => void
) {
  const location = useLocation()

  useEffect(() => {
    callback({ location })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}

export function usePreviousPageUrl() {
  const location = useLocation()
  const [previousPath, setPreviousPath] = useState<string | null>(null)

  useEffect(() => {
    setPreviousPath(location.pathname)
  }, [location])

  return previousPath
}

/** ブラウザバックを実行し、失敗した場合はエラーを投げる */
function tryGoBack() {
  return new Promise((resolve, reject) => {
    const initialHistoryLength = history.length

    // popstateイベントリスナーを追加
    const onPopState = () => {
      // タイムアウト処理、失敗時にはスルーされる
      clearTimeout(timeoutId)
      //成功時の処理、リスナーを削除してtrueを返す
      window.removeEventListener('popstate', onPopState)
      resolve(true)
    }

    // ブラウザバックできなかった場合の処理
    const timeoutId = setTimeout(() => {
      window.removeEventListener('popstate', onPopState)
      if (history.length === initialHistoryLength) {
        reject(new Error('ブラウザバックに失敗しました。'))
      } else {
        resolve(true)
      }
    }, 50)

    // popstateイベントリスナーを追加
    window.addEventListener('popstate', onPopState)
    // ブラウザバックを試みる
    history.back()
  })
}

/**
 * generate path with query string
 * @param path
 * @param params
 * @param query
 * @example
 * generateQueryPath('/test', {}, {skuId: 'xxxx'}) -> /test?skuId=xxxx
 * generateQueryPath('/test', {}, {skuId: ''}) -> /test
 */
function generateQueryPath<S extends string>(
  path: S,
  params: ExtractRouteParams<S> | undefined,
  query: Record<string, string>
): string {
  const queryUrl = new URLSearchParams(pickBy(query, identity))
  if (queryUrl.toString()) {
    return `${generatePath(path, params)}?${queryUrl.toString()}`
  }
  return generatePath(path, params)
}
