"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Wallet = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Wallet = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M20 3H5C3.346 3 2 4.346 2 6V18C2 19.654 3.346 21 5 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM5 19C4.448 19 4 18.551 4 18V6C4 5.449 4.448 5 5 5H20V8H14C12.897 8 12 8.897 12 10V14C12 15.103 12.897 16 14 16H20.001V19H5ZM20 10V14H14V10H20Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Wallet = Wallet;
