"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Right = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Right = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12a9.98 9.98 0 0 0 10 10 9.98 9.98 0 0 0 10-10A9.98 9.98 0 0 0 12 2 9.98 9.98 0 0 0 2 12zm7.298-8.025a8.438 8.438 0 0 0-4.845 4.18h3.276c.327-1.716.872-3.152 1.569-4.18zM7.503 9.693h-3.65A8.538 8.538 0 0 0 3.539 12c0 .8.11 1.575.316 2.308h3.649A22.026 22.026 0 0 1 7.385 12c0-.802.04-1.574.118-2.308zm1.555 4.615A19.54 19.54 0 0 1 8.923 12c0-.808.048-1.581.135-2.308h5.885c.086.727.134 1.5.134 2.308 0 .807-.048 1.58-.134 2.307H9.058zm-1.33 1.538H4.454a8.438 8.438 0 0 0 4.844 4.179c-.697-1.028-1.242-2.464-1.568-4.179zm6.976 4.179a8.438 8.438 0 0 0 4.843-4.179h-3.275c-.327 1.715-.872 3.15-1.568 4.179zm-.015-4.179c-.567 2.65-1.665 4.385-2.689 4.385-1.023 0-2.122-1.734-2.689-4.385h5.378zm1.808-1.538h3.65A8.535 8.535 0 0 0 20.461 12c0-.8-.11-1.575-.316-2.307h-3.649c.078.734.119 1.506.119 2.308 0 .801-.041 1.573-.119 2.307zm-.225-6.154h3.275a8.439 8.439 0 0 0-4.845-4.18c.697 1.03 1.243 2.465 1.57 4.18zm-1.583 0H9.31c.567-2.65 1.666-4.384 2.69-4.384 1.022 0 2.12 1.734 2.688 4.384z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Right = Right;
