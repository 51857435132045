import { createContext, useContext, useEffect, useState } from 'react'

import { createAnalytics } from './'
import type { AnalyticsModule } from './AnalyticsModule'

type Instance = InstanceType<typeof AnalyticsModule>

type ContextValue = {
  analytics: Instance | null
}

const Context = createContext<ContextValue>({} as ContextValue)

type Props = {
  children: React.ReactNode
  analytics: Instance | null
}

export function AnalyticsProvider({ children, analytics }: Props) {
  return <Context.Provider value={{ analytics }}>{children}</Context.Provider>
}

export function useAnalytics() {
  return useContext(Context)
}

// analyticsモジュールをクライアントで生成するためのフック
export function useAnalyticsModule() {
  const [analytics, setAnalytics] = useState<AnalyticsModule | null>(null)

  useEffect(() => {
    const analytics = createAnalytics()
    setAnalytics(analytics)
  }, [])

  return analytics
}
