"use strict";
/**
 * @description
 *
 * 色・サイズが固有のアイコンボタンはこのディレクトリに配置する。
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Twitter = exports.Pencil = exports.Google = exports.Apple = void 0;
var Apple_1 = require("./Apple");
Object.defineProperty(exports, "Apple", { enumerable: true, get: function () { return Apple_1.Apple; } });
var Google_1 = require("./Google");
Object.defineProperty(exports, "Google", { enumerable: true, get: function () { return Google_1.Google; } });
var Pencil_1 = require("./Pencil");
Object.defineProperty(exports, "Pencil", { enumerable: true, get: function () { return Pencil_1.Pencil; } });
var Twitter_1 = require("./Twitter");
Object.defineProperty(exports, "Twitter", { enumerable: true, get: function () { return Twitter_1.Twitter; } });
