import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'

import { getPlatform } from '@/core/native/app'

import { InitProps } from '../firebase'
/**
 * Platform: Web
 * Configure and initialize the firebase app.
 * @param options - firebase web app configuration options
 * */
export const init = async ({
  appId,
  projectId,
  apiKey,
  senderId,
  measurementId,
}: InitProps) => {
  if (getPlatform() !== 'web' || typeof window === 'undefined') return

  await FirebaseAnalytics.initializeFirebase({
    appId,
    projectId,
    apiKey,
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: senderId,
    measurementId,
  })
}

/**
 * Platform: Web/Android/iOS
 * Sets the user ID property.
 * @param userId - unique identifier of a user
 * @returns void
 * https://firebase.google.com/docs/analytics/userid
 */
export const setUserId = async (userId?: string) => {
  if (!userId) return

  await FirebaseAnalytics.setUserId({
    userId,
  })
  return
}

/**
 * Platform: Web/Android/iOS
 * Sets a user property to a given value.
 * @param options - property name and value to set
 * @returns void
 * https://firebase.google.com/docs/analytics/user-properties
 */
export const setUserProperty = async ({
  name,
  value,
}: {
  name: string
  value: string
}) => {
  await FirebaseAnalytics.setUserProperty({
    name,
    value,
  })

  return
}

/**
 * Platform: Android/iOS
 * Retrieves the app instance id from the service.
 * @param none
 * @returns instanceId - individual instance id value
 * https://firebase.google.com/docs/analytics/user-properties
 */
export const getAppInstanceId = async () => {
  await FirebaseAnalytics.getAppInstanceId()

  return
}

/**
 * Platform: Web/Android/iOS
 * Clears all analytics data for this app from the device and resets the app instance id.
 * @param none
 * @returns void
 */
export const reset = async () => {
  await FirebaseAnalytics.reset()

  return
}

/**
 * Platform: Web/Android/iOS
 * Logs an app event.
 * @param name - name of the event to log
 *        params - key/value pairs of properties (25 maximum per event)
 * @returns void
 */
export const logEvent = async (options: { name: string; params: object }) => {
  await FirebaseAnalytics.logEvent(options)

  return
}

/**
 * Platform: Web/Android/iOS
 * Sets whether analytics collection is enabled for this app on this device.
 * @param name - enabled - boolean true/false
 * @returns void
 */
export const setCollectionEnabled = async (enabled: boolean) => {
  await FirebaseAnalytics.setCollectionEnabled({
    enabled,
  })

  return
}

/**
 * Platform: Web/Android/iOS
 * Deprecated - use setCollectionEnabled() instead
 * Enable analytics collection for this app on this device.
 * @param none
 * @returns void
 */
export const enable = async () => {
  await FirebaseAnalytics.enable()

  return
}

/**
 * Platform: Web/Android/iOS
 * Deprecated - use setCollectionEnabled() instead
 * Disable analytics collection for this app on this device.
 * @param none
 * @returns void
 */
export const disable = async () => {
  await FirebaseAnalytics.disable()

  return
}

/**
 * Platform: Web/Android/iOS
 * Sets the duration of inactivity that terminates the current session.
 * @param duration - duration in seconds (default - 18000)
 * @returns void
 */
export const setSessionTimeoutDuration = async () => {
  await FirebaseAnalytics.setSessionTimeoutDuration({
    duration: 10000,
  })

  return
}
