"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Help = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Help = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 6.486 6.486 2 12 2C17.514 2 22 6.486 22 12C22 17.514 17.514 22 12 22C6.486 22 2 17.514 2 12ZM4 12C4 16.411 7.589 20 12 20C16.411 20 20 16.411 20 12C20 7.589 16.411 4 12 4C7.589 4 4 7.589 4 12ZM9.21974 7.1537C9.95723 6.41622 10.9571 6.00132 12 6C13.043 6.00106 14.0429 6.4159 14.7803 7.15347C15.5177 7.89104 15.9322 8.89106 15.933 9.934C15.933 11.53 14.757 12.4826 13.978 13.1136L13.975 13.116C13.9613 13.1271 13.9476 13.1381 13.934 13.1491C13.7528 13.295 13.5795 13.4345 13.44 13.574C13.032 13.981 13 14.351 12.999 14.367L13 15H11V14.333C11 14.215 11.029 13.156 12.027 12.159C12.2473 11.948 12.4779 11.7481 12.718 11.56C13.453 10.966 13.934 10.532 13.934 9.934C13.934 8.867 13.066 8 12 8C10.934 8 10.066 8.867 10.066 9.934H8.06604C8.06736 8.89104 8.48226 7.89118 9.21974 7.1537ZM13 18V16H11V18H13Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Help = Help;
