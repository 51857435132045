"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Audio = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Audio = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M10.8 19.1C10.8 19.1 10.7 19.1 10.8 19.1C10.4 19.1 9.99998 19 9.69998 18.8L5.69998 16.1H3.09998C2.59998 16.1 2.09998 15.9 1.69998 15.5C1.29998 15.1 1.09998 14.6 1.09998 14.1V10.3C1.09998 9.8 1.29998 9.3 1.69998 8.9C2.09998 8.5 2.59998 8.3 3.09998 8.3H5.69998L9.69998 5.6C9.99998 5.4 10.3 5.3 10.7 5.3C11.1 5.3 11.4 5.4 11.7 5.5C12 5.7 12.3 6 12.5 6.3C12.7 6.6 12.8 7 12.8 7.3V17.1C12.8 17.5 12.7 17.8 12.5 18.1C12.3 18.4 12.1 18.7 11.7 18.8C11.4 19 11.1 19.1 10.8 19.1ZM5.69998 14.1C6.09998 14.1 6.49998 14.2 6.79998 14.4L10.8 17.1L10.7 7.3L6.69998 10C6.39998 10.2 5.99998 10.3 5.59998 10.3H3.09998V14.1H5.69998Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M16.1 16.5C15.8 16.5 15.6 16.4 15.4 16.2C15 15.8 15 15.2 15.4 14.8C15.4 14.8 16.5 13.6 16.5 11.7C16.5 9.8 15.4 8.6 15.4 8.6C15 8.2 15 7.6 15.4 7.2C15.8 6.8 16.4 6.8 16.8 7.2C16.9 7.3 18.5 9 18.5 11.7C18.5 14.5 16.8 16.2 16.8 16.2C16.6 16.4 16.3 16.5 16.1 16.5Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M18.9 19.4C18.6 19.4 18.4 19.3 18.2 19.1C17.8 18.7 17.8 18.1 18.2 17.7C18.3 17.6 20.3 15.5 20.3 11.7C20.3 7.9 18.3 5.8 18.2 5.7C17.8 5.3 17.8 4.7 18.2 4.3C18.6 3.9 19.2 3.9 19.6 4.3C19.7 4.4 22.3 7 22.3 11.7C22.3 16.4 19.7 19 19.6 19.1C19.4 19.3 19.2 19.4 18.9 19.4Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Audio = Audio;
