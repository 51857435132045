"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextInputField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var __1 = require("../../..");
/**
 *
 * text input field component
 *
 * @param type html input type
 * @param error react-hook-form error
 * @param titleProps custom title typography
 * @param textFieldProps custom textfield
 * @param t i18next key function
 * @param args react-hook-form register params. ex:) {...register('fieldName')
 */
exports.TextInputField = react_1.forwardRef(function (_a, ref) {
    var _b, _c, _d;
    var type = _a.type, error = _a.error, titleProps = _a.titleProps, textFieldProps = _a.textFieldProps, t = _a.t, disabled = _a.disabled, displayRequire = _a.displayRequire, displayCount = _a.displayCount, registers = __rest(_a, ["type", "error", "titleProps", "textFieldProps", "t", "disabled", "displayRequire", "displayCount"]);
    var name = registers.name;
    var _e = textFieldProps || {}, sx = _e.sx, textFieldArgs = __rest(_e, ["sx"]);
    return (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsxs(material_1.Typography, __assign({ variant: "subtitle1", color: disabled ? 'text.disabled' : 'text.secondary' }, titleProps, { children: [(_b = titleProps === null || titleProps === void 0 ? void 0 : titleProps.children) !== null && _b !== void 0 ? _b : t(name + "Title"), !!(displayRequire === null || displayRequire === void 0 ? void 0 : displayRequire.display) && (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "caption", color: "error.main", ml: 1 }, { children: (_c = displayRequire === null || displayRequire === void 0 ? void 0 : displayRequire.label) !== null && _c !== void 0 ? _c : t('labelRequired') }), void 0))] }), void 0), jsx_runtime_1.jsx(__1.TextField, __assign({ type: type, inputRef: ref }, registers, { disabled: disabled, placeholder: (_d = textFieldProps === null || textFieldProps === void 0 ? void 0 : textFieldProps.placeholder) !== null && _d !== void 0 ? _d : t(name + "Placeholder"), error: !!error, helperText: jsx_runtime_1.jsxs(material_1.Stack, __assign({ direction: "row", alignItems: "center", justifyContent: "space-between" }, { children: [((error === null || error === void 0 ? void 0 : error.message) || ' ') && (jsx_runtime_1.jsx(material_1.FormHelperText, __assign({ error: true }, { children: (error === null || error === void 0 ? void 0 : error.message) || ' ' }), void 0)), (displayCount === null || displayCount === void 0 ? void 0 : displayCount.display) && (jsx_runtime_1.jsxs(material_1.FormHelperText, __assign({ error: !!(error === null || error === void 0 ? void 0 : error.message) }, { children: [(displayCount === null || displayCount === void 0 ? void 0 : displayCount.count) || '-', "/", displayCount.limit] }), void 0))] }), void 0), fullWidth: true, sx: sx }, textFieldArgs), void 0)] }, void 0));
});
