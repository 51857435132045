"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tips = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Tips = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M16.614 3.656a7.242 7.242 0 0 0-6.055-1.51 7.244 7.244 0 0 0-3.711 2.007 7.28 7.28 0 0 0-1.971 3.739 7.357 7.357 0 0 0 1.559 6.045 4.134 4.134 0 0 1 1.033 2.609v2.727c0 .723.286 1.417.796 1.928.51.512 1.202.799 1.923.799h3.625c.721 0 1.413-.287 1.923-.799.51-.511.796-1.205.796-1.928v-2.555a4.707 4.707 0 0 1 1.106-2.9 7.288 7.288 0 0 0-1.024-10.18v.018zM14.72 19.273a.91.91 0 0 1-.907.909h-3.625a.905.905 0 0 1-.906-.91v-.908h5.438v.909zm1.513-6.582a6.492 6.492 0 0 0-1.513 3.855h-1.813v-2.727a.91.91 0 0 0-.906-.91.905.905 0 0 0-.907.91v2.727H9.282a5.919 5.919 0 0 0-1.45-3.782 5.462 5.462 0 0 1-.96-5.29c.31-.89.843-1.685 1.55-2.306a5.423 5.423 0 0 1 7.016-.124 5.451 5.451 0 0 1 2 4.23 5.365 5.365 0 0 1-1.205 3.418z", fill: '#54656D' }, void 0) }), void 0) }), void 0));
};
exports.Tips = Tips;
