"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseHistory = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var PurchaseHistory = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2.19995 6C2.19995 5.6 2.59995 5.2 2.99995 5.2H4.69995C4.99995 5.2 5.29995 5.4 5.59995 5.6C5.79995 5.8 5.99995 6.1 6.09995 6.4L8.09995 14.3H17.5L19.2999 7.5H20.9L19 14.6C18.9 15.3 18.3 15.7 17.6 15.7H16.5H9.79995H8.19995C7.49995 15.7 6.89995 15.3 6.69995 14.6L4.69995 6.7H2.99995C2.59995 6.7 2.19995 6.4 2.19995 6ZM9.79995 15.7C11 15.7 12 16.8 12 17.9C12 19.1 11 20.1 9.79995 20.1C8.59995 20.1 7.59995 19.1 7.59995 17.9C7.59995 16.7 8.59995 15.7 9.79995 15.7ZM16.5 15.7C17.7 15.7 18.8 16.8 18.7 17.9C18.7 19.1 17.7 20.1 16.5 20.1C15.3 20.1 14.3 19.1 14.3 17.9C14.3 16.7 15.3 15.7 16.5 15.7ZM10.6 18C10.6 17.5 10.2 17.2 9.79995 17.2C9.29995 17.2 8.99995 17.6 8.99995 18C8.99995 18.4 9.29995 18.7 9.79995 18.8C10.3 18.8 10.6 18.4 10.6 18ZM17.3 18C17.3 17.5 16.9 17.2 16.5 17.2C16.1 17.2 15.8 17.6 15.7 18C15.7 18.5 16.1 18.8 16.5 18.8C17 18.8 17.3 18.4 17.3 18ZM11.3 11.7C11.5 11.9 11.7 12 12 12C12.3 12 12.5 11.9 12.7 11.7L16.7 7.7C17.1 7.3 17.1 6.7 16.7 6.3C16.3 5.9 15.7 5.9 15.3 6.3L12 9.6L10.7 8.3C10.3 7.9 9.7 7.9 9.3 8.3C8.9 8.7 8.9 9.3 9.3 9.7L11.3 11.7Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.PurchaseHistory = PurchaseHistory;
