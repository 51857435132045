"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReplyOff = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var ReplyOff = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.6 5.4c.7-.2 1.4-.4 2.1-.4 1.9 0 3.8.5 5.1 1.6 1.4 1.1 2.1 2.5 2.2 4.1.1 1.2-.2 2.3-.8 3.3l1.5 1.5c1-1.5 1.4-3.1 1.3-4.8-.1-2.1-1.1-4.2-2.9-5.6-1.8-1.4-4-2.2-6.4-2.1-1.3.1-2.6.4-3.7.8l1.6 1.6zm5.393 11.715c-.622.428-1.308.856-1.993 1.285v-.9c0-.3-.1-.5-.3-.7-.2-.2-.4-.3-.7-.3-2.1.1-3.5-.3-4.8-1.3-1.3-1-2.1-2.5-2.2-4-.09-1.167.304-2.254.892-3.187l9.101 9.102zm1.51 1.509 2.509 2.509a.942.942 0 1 0 1.332-1.332L3.998 3.455a.942.942 0 1 0-1.332 1.332l1.8 1.8A7.09 7.09 0 0 0 3 11.3c.2 2.1 1.2 4.1 3 5.5 1.4 1.1 2.9 1.6 5 1.7V20c0 .3.2.6.4.8.2.1.4.2.6.2h.4a21.298 21.298 0 0 0 4.103-2.376z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.ReplyOff = ReplyOff;
