import { Capacitor } from '@capacitor/core'
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor'

export const init = (apiKey: string) => {
  if (Capacitor.isNativePlatform()) {
    ;(async function () {
      await Purchases.setLogLevel({
        level:
          process.env.NODE_ENV !== 'production'
            ? LOG_LEVEL.DEBUG
            : LOG_LEVEL.INFO,
      })
      await Purchases.configure({ apiKey })
    })()
  }
}

export const getSubscriptionById = async (
  artistId: string,
  apiKey: string,
  userId: string,
  isIOs: boolean
) => {
  await Purchases.configure({ apiKey, appUserID: userId })

  const offering = await Purchases.getOfferings()

  const productId = offering?.all?.patrons?.availablePackages
    .map(({ identifier, product }) => {
      if (!identifier?.match(new RegExp(artistId))) return

      if (isIOs) {
        return product?.identifier
      }

      // @note IAPで複数プラン対応時、PlanIDからインデックスを取得できるようにする
      return product?.subscriptionOptions?.[0]?.productId
    })
    .filter(v => v)[0]
  if (!productId) throw new Error('productIdNotFound')

  return productId
}
