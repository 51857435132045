import { ThemeOptions, ThemeProvider } from '@mui/material'
import deepmerge from 'deepmerge'
import { createContext, FC, useContext, useEffect, useState } from 'react'

import { config } from '@/config'

import { defaultTheme } from './StyleProvider'

const AppThemeContext = createContext<ThemeOptions>({})
const THEME_URL = `${config.appStorageUrl}theme.json`

export const useAppTheme = () => useContext(AppThemeContext)

/**
 * @description AppThemeProvider
 * アプリ毎のテーマを適用するためのProvider。
 *
 * クラウドストレージ上の theme.jsonを参照し、ファイルがある場合、defaultTheme(共通テーマ)とマージしたテーマをThemeProviderで注入する。
 * URLからjsonを参照できなかった場合はdefaultThemeのスタイルを使用する。
 */

export const AppThemeProvider: FC = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme)

  useEffect(() => {
    ;(async () => {
      const appTheme = await fetch(THEME_URL)
        .then(res => res.json())
        .catch(e => {})

      setTheme(deepmerge(defaultTheme, appTheme || {}))
    })()
  }, [])

  return (
    <AppThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </AppThemeContext.Provider>
  )
}
