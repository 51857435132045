"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThinPlus = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var ThinPlus = function (props) {
    return (jsx_runtime_1.jsxs(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 28 28" }, { children: [jsx_runtime_1.jsx("svg", __assign({ width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M25.478 15.217a1.217 1.217 0 0 0 0-2.434h-10.26V2.523a1.217 1.217 0 0 0-2.435 0v10.26H2.523a1.217 1.217 0 0 0 0 2.434h10.26v10.26a1.217 1.217 0 0 0 2.434 0v-10.26h10.26z", fill: "currentColor" }, void 0) }), void 0), "v"] }), void 0));
};
exports.ThinPlus = ThinPlus;
