"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reply = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Reply = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M12 21C11.8 21 11.6 20.9 11.4 20.8C11.2 20.6 11 20.3 11 20V18.5C8.89996 18.4 7.39996 17.9 5.99996 16.8C4.19996 15.4 3.19996 13.4 2.99996 11.3C2.89996 9.1 3.79996 7 5.49996 5.5C7.09996 4 9.29996 3.1 11.7 3C14.1 2.9 16.3 3.7 18.1 5.1C19.9 6.5 20.9 8.6 21 10.7C21.1 12.8 20.4 14.9 18.8 16.6C17 18.5 14.8 19.9 12.4 21C12.3 21 12.1 21 12 21ZM12 16.5C12.3 16.5 12.5 16.6 12.7 16.8C12.9 17 13 17.2 13 17.5V18.4C14.7 17.5 16.1 16.4 17.4 15.1C18.5 13.9 19.1 12.3 19 10.7C18.9 9.1 18.2 7.7 16.8 6.6C15.5 5.5 13.6 5 11.7 5C9.89996 5.1 8.09996 5.8 6.79996 6.9C5.59996 8.1 4.89996 9.6 4.99996 11.2C5.09996 12.7 5.89996 14.2 7.19996 15.2C8.49996 16.2 9.89996 16.6 12 16.5Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Reply = Reply;
