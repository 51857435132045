"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Plus = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Plus = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.838 13.043a1.044 1.044 0 0 0 0-2.086h-8.795V2.162a1.043 1.043 0 0 0-2.086 0v8.795H2.162a1.043 1.043 0 0 0 0 2.086h8.795v8.795a1.044 1.044 0 0 0 2.086 0v-8.795h8.795z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Plus = Plus;
