import { BoxProps } from '@mui/material'
import isFunction from 'lodash/isFunction'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

type SafeAreaViewConfig = {
  headerProps?: BoxProps & { isFullScreenPage?: boolean }
  footerProps?: BoxProps & { safeArea?: boolean }
}

type NewValueType = {
  safeAreaViewConfig: SafeAreaViewConfig
  setSafeAreaViewConfig: Dispatch<SetStateAction<SafeAreaViewConfig>>
}

export const CONSOLE_HEADER: SafeAreaViewConfig = {
  headerProps: { sx: { bgcolor: 'primary.main' } },
}

export const CONSOLE_FOOTER: SafeAreaViewConfig = {
  footerProps: { sx: { bgcolor: 'grey.50' } },
}

export const GLOBAL_WITH_BOTTOM_TAB: SafeAreaViewConfig = {
  footerProps: { sx: { bgcolor: 'grey.50' } },
}

export const ARTIST_WITH_BOTTOM_TAB: SafeAreaViewConfig = {
  footerProps: { sx: { bgcolor: 'grey.50' } },
}

const newInitialState: NewValueType = {
  safeAreaViewConfig: {
    headerProps: {},
    footerProps: {},
  },
  setSafeAreaViewConfig() {},
}

const Context = createContext<NewValueType>(newInitialState)

export const SafeAreaViewProvider: FC = ({ children }) => {
  const [safeAreaViewConfig, setSafeAreaViewConfig] =
    useState<SafeAreaViewConfig>({ headerProps: {}, footerProps: {} })

  return (
    <Context.Provider value={{ safeAreaViewConfig, setSafeAreaViewConfig }}>
      {children}
    </Context.Provider>
  )
}

export const useSafeAreaView = () => useContext(Context)

/**
 * @description settings header and footer of SafeAreaView
 * @param {SetStateAction<SafeAreaViewConfig>} configs
 * @param {[]} dependency
 * @example useSafeAreaSetting(CONSOLE_WITH_FOOTER)
 */
export const useSafeAreaSetting = (
  configs: SetStateAction<SafeAreaViewConfig>,
  dependency: unknown[] = []
): void => {
  const previousStateRef = useRef<Record<string, SafeAreaViewConfig>>({})
  const { setSafeAreaViewConfig } = useSafeAreaView()

  useLayoutEffect(() => {
    setSafeAreaViewConfig(prevState => {
      previousStateRef.current[window.location.pathname] = prevState
      return isFunction(configs) ? configs(prevState) : configs
    })
    return () => {
      // reset safeAreaViewConfig to previous state
      setSafeAreaViewConfig({
        headerProps: {},
        footerProps: {},
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...previousStateRef.current?.[window.location.pathname],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency)
}

/**
 * @description settings header and footer of SafeAreaView
 * @param {SetStateAction<SafeAreaViewConfig>} footerProps
 * @param {SetStateAction<SafeAreaViewConfig>} defaultFooterProps
 * @param {[]} dependency
 * @example useSafeAreaSetting(CONSOLE_WITH_FOOTER)
 */
export const useSafeAreaFooterSetting = (
  footerProps: SafeAreaViewConfig['footerProps'],
  defaultFooterProps: SafeAreaViewConfig['footerProps'] = {},
  dependency: unknown[] = []
): void => {
  const previousStateRef = useRef<Record<string, SafeAreaViewConfig>>({})
  const { setSafeAreaViewConfig } = useSafeAreaView()

  useLayoutEffect(() => {
    setSafeAreaViewConfig(prevState => {
      previousStateRef.current[window.location.pathname] = prevState
      return { ...prevState, footerProps }
    })
    return () => {
      // reset safeAreaViewConfig to previous state
      setSafeAreaViewConfig(prevState => ({
        ...prevState,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ...previousStateRef.current?.[window.location.pathname],
        footerProps: defaultFooterProps,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency)
}
