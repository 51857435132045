"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateTimePickerInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var x_date_pickers_1 = require("@mui/x-date-pickers");
var AdapterDayjs_1 = require("@mui/x-date-pickers/AdapterDayjs");
var react_1 = require("react");
var index_1 = require("../../index");
var DateTimePickerInput = function (_a) {
    var error = _a.error, _b = _a.value, value = _b === void 0 ? null : _b, onChange = _a.onChange, _c = _a.inputFormat, inputFormat = _c === void 0 ? 'YYYY/MM/DD HH:mm' : _c, placeholder = _a.placeholder, _d = _a.mask, mask = _d === void 0 ? '____/__/__ __:__' : _d, textFieldProps = _a.textFieldProps, helperText = _a.helperText, props = __rest(_a, ["error", "value", "onChange", "inputFormat", "placeholder", "mask", "textFieldProps", "helperText"]);
    var _e = react_1.useState(null), date = _e[0], setDate = _e[1];
    react_1.useEffect(function () {
        setDate(function (prevState) { return (prevState ? prevState : value); });
    }, [value]);
    return (jsx_runtime_1.jsx(x_date_pickers_1.LocalizationProvider, __assign({ dateAdapter: AdapterDayjs_1.AdapterDayjs }, { children: jsx_runtime_1.jsx(x_date_pickers_1.DateTimePicker, __assign({ value: date, onChange: function (newDate) {
                setDate(newDate);
                onChange(newDate ? newDate.format(inputFormat) : null);
            }, inputFormat: inputFormat, mask: mask, renderInput: function (params) { return (jsx_runtime_1.jsx(index_1.TextField, __assign({ error: !!error, helperText: (error === null || error === void 0 ? void 0 : error.message) || ' ' }, params, textFieldProps, { inputProps: __assign(__assign(__assign({}, params.inputProps), textFieldProps === null || textFieldProps === void 0 ? void 0 : textFieldProps.inputProps), { placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : inputFormat }) }), void 0)); } }, props, { ampm: false, ampmInClock: false }), void 0) }), void 0));
};
exports.DateTimePickerInput = DateTimePickerInput;
