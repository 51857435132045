"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Back = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Back = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.9342 3.16954C17.3929 3.68552 17.3464 4.47561 16.8304 4.93426L8.88149 12L16.8304 19.0657C17.3464 19.5244 17.3929 20.3145 16.9342 20.8305C16.4756 21.3464 15.6855 21.3929 15.1695 20.9343L5.11849 12L15.1695 3.06574C15.6855 2.60709 16.4756 2.65356 16.9342 3.16954Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Back = Back;
