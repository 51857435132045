"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadFileMaxSize = exports.acceptMimeTypes = exports.Dropzone = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_dropzone_1 = require("react-dropzone");
var components_1 = require("../../../components");
/**
 * ファイルアップロード用ドロップゾーン
 *
 * @param onDrop ドロップ時に、ファイルシステムから受け取ったファイルを引数に実行する関数
 * @param type アップロードするファイルの形式 image | pdf | audio | video
 * @param progress アップロード中のプログレスバーの数値（0 ~ 100、0の時は非表示）
 * @param description アップロードエリアの説明文
 * @param dropzone ドラッグ・ドロップ機能を使用するかどうか
 * @param dropzoneProps
 */
var Dropzone = function (_a) {
    var onDrop = _a.onDrop, type = _a.type, _b = _a.progress, progress = _b === void 0 ? 0 : _b, _c = _a.description, description = _c === void 0 ? null : _c, _d = _a.error, error = _d === void 0 ? false : _d, _e = _a.dropzone, dropzone = _e === void 0 ? false : _e, disabled = _a.disabled, dropzoneProps = _a.dropzoneProps, t = _a.t;
    var _f = react_dropzone_1.useDropzone({
        disabled: disabled,
        maxSize: exports.uploadFileMaxSize[type],
        accept: exports.acceptMimeTypes[type],
        onDrop: function (acceptedFiles) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!acceptedFiles.length)
                            return [2 /*return*/];
                        _a = dropzone;
                        if (!_a) return [3 /*break*/, 2];
                        return [4 /*yield*/, onDrop(acceptedFiles[0])];
                    case 1:
                        _a = (_b.sent());
                        _b.label = 2;
                    case 2:
                        _a;
                        return [2 /*return*/];
                }
            });
        }); },
    }), getRootProps = _f.getRootProps, getInputProps = _f.getInputProps;
    return (jsx_runtime_1.jsxs(material_1.Box, __assign({}, getRootProps(), dropzoneProps, { sx: __assign({ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%', gap: 3, p: 7, mx: 'auto', boxSizing: 'border-box', border: '2px dashed', borderColor: error ? 'error.main' : 'grey.300', borderRadius: 1, color: 'grey.300', position: 'relative' }, dropzoneProps === null || dropzoneProps === void 0 ? void 0 : dropzoneProps.sx) }, { children: [!!progress && (jsx_runtime_1.jsx(material_1.LinearProgress, { variant: "determinate", value: progress, 
                /**
                 * @description LinerProgressにsxが無いのでstyle propsで当てる
                 */
                style: {
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                } }, void 0)), jsx_runtime_1.jsx(components_1.Icons.Drop, { sx: {
                    width: 56,
                    height: 46,
                    color: disabled ? 'grey.200' : 'grey.400',
                } }, void 0), jsx_runtime_1.jsx(material_1.Box, __assign({ sx: {
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '4px',
                    textAlign: 'center',
                } }, { children: error ? (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h5", color: "error.main" }, { children: "\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u30A8\u30E9\u30FC" }), void 0), jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle2", color: "error.main" }, { children: "\u30A8\u30E9\u30FC\u30E1\u30C3\u30BB\u30FC\u30B8\u3092\u78BA\u8A8D\u3057\u3001\u518D\u5EA6\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3057\u3066\u304F\u3060\u3055\u3044" }), void 0)] }, void 0)) : (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2", color: disabled ? 'text.disabled' : 'text.secondary' }, { children: description }), void 0)) }), void 0), jsx_runtime_1.jsx(material_1.Button, __assign({ type: "button", variant: "outlined", size: "medium", disabled: disabled, onClick: !dropzone ? function () { return onDrop(); } : undefined }, { children: t('selectFile') }), void 0), dropzone && jsx_runtime_1.jsx("input", __assign({}, getInputProps()), void 0)] }), void 0));
};
exports.Dropzone = Dropzone;
exports.acceptMimeTypes = {
    image: ['image/jpeg', 'image/png'],
    audio: ['audio/mp3', 'audio/wav', 'audio/mpeg'],
    video: ['video/mp4'],
    pdf: ['application/pdf'],
    csv: ['text/csv'],
};
exports.uploadFileMaxSize = {
    csv: 10 * Math.pow(10, 6),
    image: 10 * Math.pow(10, 6),
    pdf: 20 * Math.pow(10, 6),
    audio: 200 * Math.pow(10, 6),
    video: 500 * Math.pow(10, 6), // 500MB
};
