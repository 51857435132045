import { forwardRef } from 'react'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

/**
 * react routerに変更したLink Component
 */
export const RouterLinkBehavior = forwardRef<
  HTMLAnchorElement,
  RouterLinkProps
>((props, ref) => {
  const { href, isPrivate, ...other } = props

  return (
    <RouterLink
      data-testid="custom-router-link"
      ref={ref}
      to={href}
      {...other}
    />
  )
})

export type RouterLinkProps = Omit<LinkProps, 'to'> & {
  href: LinkProps['to']
} & { isPrivate?: boolean }
