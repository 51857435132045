import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'

import { AuthAdapterFn, User } from '@/core'
/**
 * auth0 adapter
 *
 * @todo 利用時にロジックを実装する
 * @returns
 */
export const adaptAuth0: AuthAdapterFn<Auth0Config, User | null> = config => {
  return {
    useAuth: () => {
      const { isAuthenticated, isLoading, logout, loginWithRedirect } =
        useAuth0()
      return {
        isAuthenticated,
        loading: isLoading,
        user: null,
        userClaims: null,
        login: loginWithRedirect,
        isBoundWithSNS: false,
        async loginWithEmail() {
          return null
        },
        async logout() {
          return logout()
        },
        async reloadUser() {},
        async getUserClaims() {
          return null
        },
        async signInAnonymously() {
          return null
        },
        async signInWithApple() {},
        async signInWithTwitter() {
          return null
        },
        async linkEmail() {
          return
        },
        async unlinkEmail() {
          return
        },
        async linkTwitter() {
          return null
        },
        async unLinkTwitter() {
          return null
        },
        async updateEmail() {},
        async updateEmailSocial() {},
        async updatePassword() {},
        async verifyPasswordResetCode() {
          return ''
        },
        async sendPasswordResetEmail() {},
        async confirmPasswordReset() {},
        async getRedirectResult() {
          return null
        },
      }
    },
    AuthProvider: () => {
      return (
        <Auth0Provider
          domain={config.domain}
          clientId={config.clientId}
        ></Auth0Provider>
      )
    },
    useCurrentUser: () => {
      return { user: null, loading: false, userClaims: null }
    },
    async getUser() {
      return null
    },
    async getAccessToken() {
      return null
    },
  }
}

type Auth0Config = {
  domain: string
  clientId: string
}
