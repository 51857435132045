"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Campaign = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Campaign = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M20 6.21053H17.82C17.93 5.88421 18 5.52632 18 5.15789C18 3.41053 16.66 2 15 2C13.95 2 13.04 2.56842 12.5 3.42105L12 4.12632L11.5 3.41053C10.96 2.56842 10.05 2 9 2C7.34 2 6 3.41053 6 5.15789C6 5.52632 6.07 5.88421 6.18 6.21053H4C2.89 6.21053 2.01 7.14737 2.01 8.31579L2 19.8947C2 21.0632 2.89 22 4 22H20C21.11 22 22 21.0632 22 19.8947V8.31579C22 7.14737 21.11 6.21053 20 6.21053ZM15 4.10526C15.55 4.10526 16 4.57895 16 5.15789C16 5.73684 15.55 6.21053 15 6.21053C14.45 6.21053 14 5.73684 14 5.15789C14 4.57895 14.45 4.10526 15 4.10526ZM9 4.10526C9.55 4.10526 10 4.57895 10 5.15789C10 5.73684 9.55 6.21053 9 6.21053C8.45 6.21053 8 5.73684 8 5.15789C8 4.57895 8.45 4.10526 9 4.10526ZM20 19.8947H4V17.7895H20V19.8947ZM20 14.6316H4V8.31579H9.08L7 11.2947L8.62 12.5263L12 7.68421L15.38 12.5263L17 11.2947L14.92 8.31579H20V14.6316Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Campaign = Campaign;
