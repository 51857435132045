"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Serial = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Serial = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M13.8 22C13.3226 22 12.8648 21.8084 12.5272 21.4675C12.1896 21.1265 12 20.664 12 20.1818V12C12 11 12.81 10.1818 13.8 10.1818H17.4L21 13.8182V20.1818C21 21.1909 20.199 22 19.2 22H13.8ZM13.8 20.1818H19.2V14.5727L16.653 12H13.8V20.1818ZM17.4 3.81818C18.39 3.81818 19.2 4.63636 19.2 5.63636V9.42727L18.147 8.36364H17.4V5.63636H15.6V7.45455H6.6V5.63636H4.8V18.3636H10.2V20.1818H4.8C3.81 20.1818 3 19.3636 3 18.3636V5.63636C3 4.63636 3.81 3.81818 4.8 3.81818H8.562C8.94 2.76364 9.93 2 11.1 2C12.27 2 13.26 2.76364 13.638 3.81818H17.4ZM11.1 3.81818C10.605 3.81818 10.2 4.22727 10.2 4.72727C10.2 5.22727 10.605 5.63636 11.1 5.63636C11.595 5.63636 12 5.22727 12 4.72727C12 4.22727 11.595 3.81818 11.1 3.81818Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Serial = Serial;
