"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Like = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
/**
 * いいねの♡アイコンボタン
 * @param {IconButtonProps} props
 * @param {boolean} liked いいねしているかどうか
 * @param {boolean} filled いいねしているかどうか
 */
var Like = function (_a) {
    var liked = _a.liked, filled = _a.filled, props = __rest(_a, ["liked", "filled"]);
    var palette = material_1.useTheme().palette;
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: liked ? (jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M12 21L10.55 19.7052C5.4 15.1243 2 12.103 2 8.3951C2 5.37384 4.42 3 7.5 3C9.24 3 10.91 3.79455 12 5.05014C13.09 3.79455 14.76 3 16.5 3C19.58 3 22 5.37384 22 8.3951C22 12.103 18.6 15.1243 13.45 19.715L12 21Z", fill: palette.like.tap }, void 0) }), void 0)) : filled ? (jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M12 21L10.55 19.7052C5.4 15.1243 2 12.103 2 8.3951C2 5.37384 4.42 3 7.5 3C9.24 3 10.91 3.79455 12 5.05014C13.09 3.79455 14.76 3 16.5 3C19.58 3 22 5.37384 22 8.3951C22 12.103 18.6 15.1243 13.45 19.715L12 21Z", fill: "currentColor" }, void 0) }), void 0)) : (jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M16.5 3C14.76 3 13.09 3.79455 12 5.05014C10.91 3.79455 9.24 3 7.5 3C4.42 3 2 5.37384 2 8.3951C2 12.103 5.4 15.1243 10.55 19.715L12 21L13.45 19.7052C18.6 15.1243 22 12.103 22 8.3951C22 5.37384 19.58 3 16.5 3ZM12.1 18.2534L12 18.3515L11.9 18.2534C7.14 14.0256 4 11.23 4 8.3951C4 6.43324 5.5 4.96185 7.5 4.96185C9.04 4.96185 10.54 5.93297 11.07 7.27684H12.94C13.46 5.93297 14.96 4.96185 16.5 4.96185C18.5 4.96185 20 6.43324 20 8.3951C20 11.23 16.86 14.0256 12.1 18.2534Z", fill: "currentColor" }, void 0) }), void 0)) }), void 0));
};
exports.Like = Like;
