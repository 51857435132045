"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Store = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Store = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M8.5 21.8c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5-1.5.6-1.5 1.4c0 .8.7 1.6 1.5 1.6zM18.2 21.8c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5-1.5.7-1.5 1.5.7 1.5 1.5 1.5zM17.9 17.4h-9c-.5 0-1-.2-1.4-.5-.4-.3-.7-.8-.9-1.3L4.1 7l-.9-3.1H1.8c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h1.6c.4 0 .7.1 1 .3.2.2.4.6.5.9l.7 2.6h16.1c.3 0 .5.1.7.4.2.3.2.5.1.8L20 15.8c-.1.5-.4.9-.9 1.3-.2.1-.7.3-1.2.3zM6.2 7.6l2.1 7.5c0 .1.1.2.2.3.1.1.2.1.4.1h9c.1 0 .3 0 .4-.1.1-.1.2-.2.2-.3l2.1-7.5H6.2z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Store = Store;
