"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleInputField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var __1 = require("../../..");
/**
 * Form用ToggleInputField
 *
 * @param content { title: 見出しテキスト, description: 見出し下の説明文　}
 * @param ...switchProps インプットフィールドProps
 */
var ToggleInputField = function (_a) {
    var content = _a.content, sx = _a.sx, switchProps = __rest(_a, ["content", "sx"]);
    var title = content.title, description = content.description;
    return (jsx_runtime_1.jsxs(material_1.Stack, __assign({ sx: __assign({ px: 2, pb: 7 }, sx) }, { children: [jsx_runtime_1.jsxs(material_1.Stack, __assign({ direction: "row", alignItems: "center" }, { children: [jsx_runtime_1.jsx(material_1.Stack, __assign({ flex: 1 }, { children: jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h5" }, { children: title }), void 0) }), void 0), jsx_runtime_1.jsx(material_1.Stack, { children: jsx_runtime_1.jsx(__1.Switch, __assign({}, switchProps, { onChange: !switchProps.readOnly ? switchProps.onChange : undefined }), void 0) }, void 0)] }), void 0), jsx_runtime_1.jsx(material_1.Stack, { children: typeof description === 'string' ? (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "caption", color: "text.secondary", sx: { pt: 3 } }, { children: description }), void 0)) : (description) }, void 0)] }), void 0));
};
exports.ToggleInputField = ToggleInputField;
