"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Youtube = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Youtube = function (props) { return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "17.5", cy: "17.5", r: "17", stroke: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24.533 11.876a2.26 2.26 0 0 1 1.592 1.596c.375 1.407.375 4.344.375 4.344s0 2.937-.375 4.343a2.261 2.261 0 0 1-1.592 1.597c-1.403.376-7.033.376-7.033.376s-5.627 0-7.033-.377a2.26 2.26 0 0 1-1.592-1.596C8.5 20.753 8.5 17.816 8.5 17.816s0-2.937.375-4.344a2.26 2.26 0 0 1 1.592-1.596C11.873 11.5 17.5 11.5 17.5 11.5s5.63 0 7.033.376zm-8.876 8.819 4.914-2.987-4.914-2.987v5.974z", fill: "currentColor" }, void 0)] }), void 0) }), void 0)); };
exports.Youtube = Youtube;
