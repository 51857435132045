"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Youtube = exports.Twitter = exports.Tiktok = exports.Share = exports.Instagram = exports.Facebook = void 0;
var Facebook_1 = require("./Facebook");
Object.defineProperty(exports, "Facebook", { enumerable: true, get: function () { return Facebook_1.Facebook; } });
var Instagram_1 = require("./Instagram");
Object.defineProperty(exports, "Instagram", { enumerable: true, get: function () { return Instagram_1.Instagram; } });
var Share_1 = require("./Share");
Object.defineProperty(exports, "Share", { enumerable: true, get: function () { return Share_1.Share; } });
var Tiktok_1 = require("./Tiktok");
Object.defineProperty(exports, "Tiktok", { enumerable: true, get: function () { return Tiktok_1.Tiktok; } });
var Twitter_1 = require("./Twitter");
Object.defineProperty(exports, "Twitter", { enumerable: true, get: function () { return Twitter_1.Twitter; } });
var Youtube_1 = require("./Youtube");
Object.defineProperty(exports, "Youtube", { enumerable: true, get: function () { return Youtube_1.Youtube; } });
