"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniqueSpot = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var UniqueSpot = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 40 40" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "40", height: "40", viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M20 2C12.26 2 6 8.26 6 16C6 26.5 20 38 20 38C20 38 34 26.5 34 16C34 8.26 27.74 2 20 2ZM20 21C17.24 21 15 18.76 15 16C15 13.24 17.24 11 20 11C22.76 11 25 13.24 25 16C25 18.76 22.76 21 20 21Z", fill: "#1640A1" }, void 0) }), void 0) }), void 0));
};
exports.UniqueSpot = UniqueSpot;
