"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Video = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Video = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19 6H5C3.89543 6 3 6.89543 3 8V16C3 17.1046 3.89543 18 5 18H19C20.1046 18 21 17.1046 21 16V8C21 6.89543 20.1046 6 19 6ZM5 4C2.79086 4 1 5.79086 1 8V16C1 18.2091 2.79086 20 5 20H19C21.2091 20 23 18.2091 23 16V8C23 5.79086 21.2091 4 19 4H5Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M14 12L11 10V14L14 12Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.5281 9.11833C10.8533 8.94431 11.2478 8.96338 11.5547 9.16795L14.5547 11.1679C14.8329 11.3534 15 11.6656 15 12C15 12.3344 14.8329 12.6466 14.5547 12.8321L11.5547 14.832C11.2478 15.0366 10.8533 15.0557 10.5281 14.8817C10.203 14.7077 10 14.3688 10 14V10C10 9.6312 10.203 9.29234 10.5281 9.11833ZM12 11.8685V12.1315L12.1972 12L12 11.8685Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Video = Video;
