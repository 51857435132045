export const ja = {
  'any.unknown': '{{#label}}は許可されていません。',
  'any.invalid': '{{#label}}は無効な値が含まれています。',
  'any.empty': '{{#label}}は空白にできません。',
  'any.required': '{{#label}}は必須です。',
  'any.allowOnly': '{{#label}}は{{#valids}}のみ許可されています。',
  'any.default': '{{#label}}は初期値が正しく設定できません。',
  'any.only': '{{#label}}は{{#valids}}のみ許可されています。',
  'any.valid': '{{#label}}は{{#valids}}のみ許可されています。',
  'alternatives.base': '{{#label}}は許可されたもののいずれにも一致しません。',

  'array.base': '{{#label}}は配列である必要があります。',
  'array.includes': '{{#pos}} は許可されているタイプのいずれとも一致しません',
  'array.includesSingle':
    '"{{#label}}" は許可されているタイプのいずれとも一致しません',
  'array.includesOne': '{{#pos}} では {{#reason}} で失敗しています。',
  'array.includesOneSingle': ' "{{#label}}" は {{#reason}} で失敗しています。',
  'array.includesRequiredUnknowns':
    '{{#unknownMisses}} の必須値が含まれていません。',
  'array.includesRequiredKnowns': '{{#knownMisses}} が存在しません。',
  'array.includesRequiredBoth':
    '{{#knownMisses}} 及び {{#unknownMisses}} の他の必要な値が含まれていません。',
  'array.excludes': '{{#pos}} には除外された値が含まれています。',
  'array.excludesSingle': '"{{#label}}" の単一値に除外値が含まれています。',
  'array.hasKnown':
    ' "{{#patternLabel}}" に必要な一致が少なくとも1つ含まれていません。',
  'array.hasUnknown': '{{#label}}は少なくとも1つの必須一致が含まれていません。',
  'array.min':
    '{{#label}}は少なくとも {{#limit}} 個のアイテムが含まれている必要があります。',
  'array.max': ' {{#limit}} 個以下のアイテムを含める必要があります。',
  'array.length': ' {{l#imit}} 個のアイテムを含める必要があります。',
  'array.ordered': ' {{#pos}} では {{#reason}} が原因で失敗しています。',
  'array.orderedLength':
    '配列には最大 {{#limit}} 個のアイテムを含める必要があるため、{{#pos}}で失敗しています。',
  'array.ref': '正の整数ではない "{{#ref}}" を参照しています。',
  'array.sparse': '{{#label}}はスパース配列であってはなりません。',
  'array.unique': ' {{#pos}} には重複した値が含まれています。',

  'boolean.base': '{{#label}}は真偽値でなければなりません。',

  'binary.base': '{{#label}}はバッファまたは文字列でなければなりません。',
  'binary.min': '{{#label}}は少なくとも {{#limit}} バイトである必要があります',
  'binary.max': '{{#limit}} バイト以下でなければなりません。',
  'binary.length': ' {{#limit}} バイトである必要があります。',

  'date.base': '{{#label}}は有効な日時か秒数の形式を入力してください。',
  'date.strict': '{{#label}}は無効な日時の形式です。',
  'date.min': '{{#label}}は"{{#limit}}"と同じか後の日時を入力してください。',
  'date.max': '{{#label}}は"{{#limit}}"と同じか前の日時を入力してください。',
  'date.less': '{{#label}}は"{{limit}}"より前の日時を入力してください。',
  'date.greater': '{{#label}}は"{{#limit}}"より後の日時を入力してください。',
  'date.format': '{{#label}}は有効な日時の形式を入力してください。',
  'date.ref': '{{#label}}は日付ではない"{{#ref}}"を参照しています。',

  'function.base': '{{#label}}は関数でなければなりません。',
  'function.arity': ' {{#n}} のアリティが必要です。',
  'function.minArity': '{{#n}} 以上のアリティが必要です。',
  'function.maxArity': '{{#n}} 以下のアリティが必要です。',
  'function.ref': '{{#label}}はJoi参照である必要があります。',
  'function.class': '{{#label}}はクラスでなければなりません。',

  'lazy.base': '遅延スキーマを設定する必要があります。',
  'lazy.schema': '遅延スキーマ関数はスキーマを返す必要があります。',

  'object.base': '{{#label}}はオブジェクトでなければなりません。',
  'object.child':
    '!!child "{{!child}}" は {{#reason}} が原因で失敗しています。',
  'object.min': '少なくとも {{#limit}} の子要素が必要です。',
  'object.max': '{{#limit}} 以下の子要素が必要です。',
  'object.length': ' {{#limit}} の子要素が必要です。',
  'object.allowUnknown': '!!"{{!child}}"は許可されていない値です。',
  'object.with':
    '!!"{{#mainWithLabel}}" に必要なペア "{{#peerWithLabel}}" が見つかりません。',
  'object.without':
    '!!"{{#mainWithLabel}}" は禁止されたペア "{{#peerWithLabel}}" と競合します。',
  'object.missing':
    '{{#peersWithLabels}} の少なくとも1つを含める必要があります。',
  'object.xor': '排他的なピア間の競合が含まれています。 {{#peersWithLabels}}',
  'object.oxor':
    'オプションの排他的ペア {{#peersWithLabels}} 間の競合が含まれています。',
  'object.and':
    '必要なピア {{#missingWithLabels}} のない {{#presentWithLabels}} が含まれています。',
  'object.nand':
    '!!"{{#mainWithLabel}}" は {{#peersWithLabels}} と同時に存在してはいけません。',
  'object.assert':
    '!!"{{#ref}}" が {{#message}}に失敗したため、 "{{#ref}}" の検証に失敗しました。',
  'object.type': '"{{#type}}" のインスタンスでなければなりません。',
  'object.schema': 'はJoiインスタンスである必要があります。',

  'number.base': '{{#label}}は数値です。',
  'number.unsafe': '{{#label}}は安全な番号でなければなりません。',
  'number.min': '{{#label}}は{{#limit}}以上の値です。',
  'number.max': '{{#label}}は{{#limit}}以下の値です。',
  'number.less': '{{#label}}は{{#limit}}より小さな値です。',
  'number.greater': '{{#label}}は{{#limit}}より大きな値です。',
  'number.integer': '{{#label}}は整数です。',
  'number.negative': '{{#label}}は負の数です。',
  'number.positive': '{{#label}}は正の数です。',
  'number.precision':
    '{{#label}}は小数点以下 {{#limit}} 以下にする必要があります。',
  'number.ref': '{{#label}}は数字ではない "{{#ref}}" を参照します。',
  'number.multiple': '{{#multiple}} の倍数でなければなりません。',
  'number.port': '{{#label}}は有効なポートである必要があります。',

  'string.base': '{{#label}}は文字列です。',
  'string.empty': '{{#label}}は空白にできません',
  'string.min': '{{#label}}は {{#limit}} 文字以上です。',
  'string.max': '{{#label}}は {{#limit}} 文字以下です。',
  'string.length': '{{#label}}は {{#limit}} 文字です。',
  'string.alphanum': '{{#label}}は英数字のみです。',
  'string.token': '{{#label}}は英数字またはアンダースコア(_)のみです。',
  'string.regex': '{{#label}}は形式が異なります。',
  'string.email': '{{#label}}は無効なメールアドレスです。',
  'string.uri': '{{#label}}は無効なuriです。',
  'string.uriRelativeOnly': '{{#label}}有効な相対URIでなければなりません。',
  'string.uriCustomScheme':
    '{{#scheme}} パターンに一致するスキームを持つ有効なURIでなければなりません。',
  'string.isoDate': '{{#label}}は有効なISO 8601日付でなければなりません。',
  'string.guid': '{{#label}}は有効なGUIDである必要があります',
  'string.hex': '{{#label}}は16進文字のみを含める必要があります',
  'string.hexAlign':
    '{{#label}}は16進数でデコードされた表現はバイトアラインする必要があります。',
  'string.base64': '{{#label}}は有効なbase64文字列である必要があります。',
  'string.dataUri': '{{#label}}は有効なデータURI文字列である必要があります。',
  'string.hostname': '{{#label}}は有効なホスト名でなければなりません。',
  'string.normalize': '{{#form}} 形式でUnicode正規化する必要があります',
  'string.lowercase': '{{#label}}は小文字のみである必要があります。',
  'string.uppercase': '{{#label}}は大文字のみである必要があります。',
  'string.trim': '{{#label}}は先頭または末尾に空白があってはなりません。',
  'string.creditCard': '{{#label}}はクレジットカードでなければなりません。',
  'string.ref': '{{#label}}は数字ではない "{{#ref}}" を参照します',
  'string.ip': '{{#cidr}} CIDRを持つ有効なIPアドレスである必要があります',
  'string.ipVersion':
    '{{#cidr}} CIDRを含む次のバージョン {{#version}} のいずれかの有効なIPアドレスである必要があります',

  'symbol.base': '{{#label}}はシンボルでなければなりません。',
  'symbol.map': '{{#map}} のいずれかでなければなりません。',
  'string.pattern.base': '{{#label}} : "{[.]}" は形式が誤っています。',
  'string.pattern.name': '{{#label}} : "{[.]}" は形式が誤っています。',
  'string.pattern.invert.base': '{{#label}} : "{[.]}" は形式が誤っています。',
  'string.pattern.invert.name': '{{#label}} : "{[.]}" は形式が誤っています。',
}

export const en = {
  'any.unknown': '{{#label}} is not allowed.',
  'any.invalid': '{{#label}} contains an invalid value.',
  'any.empty': '{{#label}} is not allowd to be empty.',
  'any.required': '{{#label}} is required.',
  'any.allowOnly': '{{#label}} is only allowed for {{#valids}}.',
  'any.default': '{{#label}} is not able to set correct initial value.',
  'any.only': '{{#label}} is only allowed for {{#valids}}.',
  'any.valid': '{{#label}} is only allowed for {{#valids}}.',
  'alternatives.base': '{{#label}} does not match any of the allowed types.',

  'array.base': '{{#label}} must be an array.',
  'array.includes': '{{#pos}} does not match any of the allowed types.',
  'array.includesSingle':
    '"{{#label}}" does not match any of the allowed types.',
  'array.includesOne': '{{#pos}} fails with {{#reason}}.',
  'array.includesOneSingle': ' "{{#label}}" fails with {{#reason}}.',
  'array.includesRequiredUnknowns':
    'Required value of {{#unknownMisses}} is not contained.',
  'array.includesRequiredKnowns': '{{#knownMisses}} does not exist.',
  'array.includesRequiredBoth':
    'Other required values of {{#knownMisses}} and {{#unknownMisses}} are not contained.',
  'array.excludes': '{{#label}} contains an excluded value.',
  'array.excludesSingle':
    '"A single value of "{{#label}}" contains an excluded value.',
  'array.hasKnown':
    '"{{#patternLabel}}" does not contain at least one required match.',
  'array.hasUnknown':
    '{{#label}} does not contain at least one required match.',
  'array.min': '{{#label}} need to contain at least {{#limit}} items.',
  'array.max': '{{#limit}} items or less need to be contained.',
  'array.length': '{{#limit}} items need to be contained.',
  'array.ordered': ' {{#pos}} fails due to {{#reason}}.',
  'array.orderedLength':
    'The array need to contain maximum {{#limit}} items, so it fails with {{#pos}}.',
  'array.ref': 'Refers to "{{#ref}}" which is not a positive integer.',
  'array.sparse': '{{#label}} should not be a sparse array.',
  'array.unique': ' {{#pos}} contains duplicate values.',

  'boolean.base': '{{#label}} must be a boolean value.',

  'binary.base': '{{#label}} must be a buffer or a string.',
  'binary.min': '{{#label}} need to be at least {{#limit}} bytes.',
  'binary.max': '{{#label}} must be less than or equal to {{#limit}} bytes.',
  'binary.length': 'Need to be {{#limit}} bytes.',

  'date.base':
    '{{#label}} should be in the form of valid date/time or seconds.',
  'date.strict': '{{#label}} is invalid date/time format.',
  'date.min': '{{#label}} should be same as or after "{{#limit}}".',
  'date.max': '{{#label}} should be same as or before "{{#limit}}".',
  'date.less': '{{#label}} should be before "{{limit}}".',
  'date.greater': '{{#label}} should be after "{{#limit}}".',
  'date.format': 'Enter a valid date and time format for {{#label}}',
  'date.ref': '{{#label}} refers to "{{#ref}}" which is not a date.',

  'function.base': '{{#label}} mus be a function.',
  'function.arity': '{{#n}} arity is required.',
  'function.minArity': 'More than {{#n}} arity is required.',
  'function.maxArity': 'Less than {{#n}} arity is required.',
  'function.ref': '{{#label}} need to be Joi reference.',
  'function.class': '{{#label}} must be class.',

  'lazy.base': 'Lazy schema needs to be set up.',
  'lazy.schema': 'Lazy schema functions need to return a schema.',

  'object.base': '{{#label}} must be an object.',
  'object.child': '! !child "{{!child}}" is failing due to {{#reason}}.',
  'object.min': ' At least {{#limit}} child elements are required.',
  'object.max': 'Less than {{#limit}} child elements are required',
  'object.length': ' Child element of {{#limit}} is required.',
  'object.allowUnknown': '!!!" {{!child}}" is not an allowed value.',
  'object.with':
    'Missing required pair "{{#peerWithLabel}}" for !!"{{#mainWithLabel}}"',
  'object.without':
    '!!" {{#mainWithLabel}}" conflicts with prohibited pair "{{#peerWithLabel}}".',
  'object.missing':
    'At least one of {{#peersWithLabels}} needs to be included.',
  'object.xor':
    'Conflicts between exclusive peers are contained. {{#peersWithLabels}}.',
  'object.oxor':
    'Conflicts between optional exclusive pairs {{#peersWithLabels}} are contained.',
  'object.and':
    '{{#presentWithLabels}} is contained without required peer {{#missingWithLabels}}.',
  'object.nand':
    '!!!" {{#mainWithLabel}}" must not exist at the same time as {{#peersWithLabels}}.',
  'object.assert':
    ' "{{#ref}}" validation failed because !!!" {{#ref}}" failed to {{#message}}.',
  'object.type': '" Must be an instance of "{{#type}}".',
  'object.schema': 'needs to be a Joi instance.',

  'number.base': '{{#label}} is a numerical value.',
  'number.unsafe': '{{#label}} must be a safe number.',
  'number.min': '{{#label}} shall be greater than or equal to {{#limit}}.',
  'number.max': '{{#label}} shall be less than or equal to {{#limit}}.',
  'number.less': '{{#label}} shall be less than {{#limit}}.',
  'number.greater': '{{#label}} shall be greater than {{#limit}}.',
  'number.integer': '{{#label}} shall be an integer.',
  'number.negative': '{{#label}} shall be a negative number.',
  'number.positive': '{{#label}} shall be a positive number.',
  'number.precision':
    '{{#label}} needs to be less than or equal to the decimal point {{#limit}}.',
  'number.ref': '{{#label}} refers to "{{#ref}}" which is not a number.',
  'number.multiple': ' Must be a multiple of {{#multiple}}.',
  'number.port': '{{#label}} needs to be a valid port.',

  'string.base': '{{#label}} is a string.',
  'string.empty': '{{#label}} is not allowed to be empty.',
  'string.min': '{{#label}} shall be more than {{#limit}} characters long.',
  'string.max': '{{#label}} shall be less than {{#limit}} characters long.',
  'string.length': '{{#label}} shall be {{#limit}} characters long.',
  'string.alphanum': '{{#label}} shall only contain alpha-numeric characters.',
  'string.token':
    '{{#label}} can contain alpha-numeric characters and underscore(_) only.',
  'string.regex': '{Format for {{#label}} is different.',
  'string.email': '{{#label}} is an invalid e-mail address.',
  'string.uri': '{{#label}} is an invalid uri.',
  'string.uriRelativeOnly': '{{#label}} must be a valid relative uri.',
  'string.uriCustomScheme':
    'Must be a valid URI with a scheme matching the {{#scheme}} pattern.',
  'string.isoDate': '{{#label}} must be a valid ISO 8601 date.',
  'string.guid': '{{#label}} need to be a valid GUID.',
  'string.hex': '{{#label}} need to contain only hexadecimal characters.',
  'string.hexAlign':
    '{{#label}} hex decoded representation need to be byte-aligned.',
  'string.base64': '{{#label}} need to be a valid base64 string.',
  'string.dataUri': '{{#label}} need to be a valid dataUri string.',
  'string.hostname': '{{#label}} must be a valid hostname.',
  'string.normalize': 'Need to Unicode normalize in {{#form}} format.',
  'string.lowercase': '{{#label}} needs to contain only lowercase characters.',
  'string.uppercase': '{{#label}} needs to contain only uppercase letters.',
  'string.trim':
    '{{#label}} must not have any spaces at the beginning and end.',
  'string.creditCard': '{{#label}} must be a credit card.',
  'string.ref': '{{#label}} refers to "{{#ref}}" which is not a number.',
  'string.ip': 'Must be a valid IP address with {{#cidr}} CIDR.',
  'string.ipVersion':
    'Must be a valid IP address for one of the following versions {{#version}} including {{#cidr}} CIDR}}.',

  'symbol.base': '{{#label}} must be a symbol.',
  'symbol.map': 'Must be one of {{#map}}.',
  'string.pattern.base': '{{#label}} : "{[.]}" is incorrectly formatted.',
  'string.pattern.name': '{{#label}} : "{[.]}" is incorrectly formatted.',
  'string.pattern.invert.base':
    '{{#label}} : "{[.]}" is incorrectly formatted.',
  'string.pattern.invert.name':
    '{{#label}} : "{[.]}" is incorrectly formatted.',
}
