"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioForward = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var AudioForward = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 12L12 17.6864C12.0045 17.8038 12.0779 17.9099 12.1921 17.9638C12.3063 18.0177 12.4433 18.011 12.5506 17.9463L21.8573 12.2762C21.9476 12.2083 22 12.107 22 12C22 11.893 21.9476 11.7917 21.8573 11.7238L12.5506 6.05369C12.4433 5.98898 12.3063 5.98229 12.1921 6.03619C12.0779 6.09009 12.0045 6.19619 12 6.31364L12 12ZM2 6.31364L2 17.6864C2.00447 17.8038 2.07792 17.9099 2.19208 17.9638C2.30625 18.0177 2.44334 18.011 2.55059 17.9463L11.8573 12.2762C11.9476 12.2083 12 12.107 12 12C12 11.893 11.9476 11.7917 11.8573 11.7238L2.55059 6.05369C2.44334 5.98898 2.30625 5.98229 2.19208 6.03619C2.07792 6.09009 2.00447 6.19619 2 6.31364Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.AudioForward = AudioForward;
