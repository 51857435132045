import { gql, useMutation } from '@apollo/client'
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from '@web3auth/base'
import { Web3AuthCore } from '@web3auth/core'
import { OpenloginAdapter } from '@web3auth/openlogin-adapter'
import { TorusWalletConnectorPlugin } from '@web3auth/torus-wallet-connector-plugin'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'

import { config } from '@/config'
import { useMessage, useTranslation } from '@/core'
import { setRedirectAfterLoginPath } from '@/core/native/storage'

const chainConfigs = {
  /**
   * Integrate Web3Auth with the Polygon Blockchain
   * web3auth doc: https://web3auth.io/docs/connect-blockchain/polygon#initializing-provider-polygon
   */
  matic: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x89', // hex of 137, polygon mainnet
    rpcTarget: 'https://rpc.ankr.com/polygon',
    displayName: 'Polygon Mainnet',
    blockExplorer: 'https://polygonscan.com',
    ticker: 'MATIC',
    tickerName: 'Matic',
  },
  mumbai: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x13881', // hex of 80001, polygon testnet
    rpcTarget: 'https://rpc.ankr.com/polygon_mumbai',
    displayName: 'Polygon Testnet (Mumbai)',
    blockExplorer: 'https://mumbai.polygonscan.com/',
    ticker: 'MUMBAI',
    tickerName: 'Mumbai',
  },
}

const web3auth = new Web3AuthCore({
  clientId: config.nft.web3auth.clientId || '',
  chainConfig:
    config.environment === 'production'
      ? chainConfigs.matic
      : chainConfigs.mumbai,
})

const openloginAdapter = new OpenloginAdapter({
  adapterSettings: {
    clientId: config.nft.web3auth.clientId,
    network: config.environment === 'production' ? 'mainnet' : 'testnet',
    uxMode: 'redirect',
    loginConfig: {
      jwt: {
        name: config.nft.web3auth.verifier || '',
        verifier: config.nft.web3auth.verifier || '',
        typeOfLogin: 'jwt',
        clientId: config.nft.web3auth.clientId,
      },
    },
  },
})

export const torusPlugin = new TorusWalletConnectorPlugin({
  /**
   * HACK:
   * TorusWalletConnectorPluginのconfigのshowTorusButtonは以下理由で現状動作しないため、
   * ボタンサイズを0にしてTorusWalletConnectorPluginのデフォルトのボタンをUI上隠す。
   * https://github.com/Web3Auth/web3auth-web/discussions/974
   */
  torusWalletOpts: { buttonSize: 0 },
  walletInitOptions: {
    whiteLabel: {
      theme: { isDark: true, colors: { primary: '#00a8ff' } },
      logoDark: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
      logoLight: 'https://web3auth.io/images/w3a-D-Favicon-1.svg',
    },
    useWalletConnect: true,
    enableLogging: true,
  },
})

export const openTorusWallet = async (idToken: string) => {
  await connectToWeb3Auth(idToken)

  torusPlugin.torusWalletInstance.showWallet('home')
}

const initWeb3auth = async () => {
  web3auth.configureAdapter(openloginAdapter)

  await web3auth.addPlugin(torusPlugin)
  await web3auth.init()
}

const connectToWeb3Auth = async (idToken: string) => {
  await setRedirectAfterLoginPath(location?.pathname)

  return await web3auth
    .connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider: 'jwt',
      extraLoginOptions: {
        id_token: idToken,
        domain: config.domain, // domain http://localhost:3000 for local startup
        verifierIdField: 'sub',
        response_type: 'token',
        scope: 'email',
      },
    })
    .catch(e => {
      // 接続済みの場合のエラーは投げない
      if (e.message === 'Failed to connect with wallet. Already connected') {
        return
      }

      throw e
    })
}

export const getUserInfo = async () => {
  return await web3auth.getUserInfo()
}

export const logout = async () => {
  return await web3auth.logout()
}

export const signWalletBySignatureMessage = async (
  signer: ethers.Signer,
  signatureMessage: string
) => {
  return await signer.signMessage(signatureMessage)
}

/**
 * @description
 * providerに紐づくウォレットがない場合ウォレット新規作成してaddressを返す
 */
export const getWalletAddress = async () => {
  if (!web3auth.provider) throw new Error('web3auth provider not found')

  const provider = new ethers.providers.Web3Provider(web3auth.provider)
  const signer = provider.getSigner()
  const walletAddress = await signer.getAddress()

  return walletAddress
}

export const useWeb3authWalletConnect = ({
  loading,
  walletAddress,
  isArtist,
  refetch,
}: {
  loading: boolean
  walletAddress?: string | null
  isArtist: boolean
  refetch?: any
}) => {
  const { t } = useTranslation('wallet')
  const { showMessage, showError } = useMessage()

  /**
   * user用ウォレット連携認証メッセージ発行・ウォレット連携
   */
  const [generateWalletSignatureMessage] = useMutation(
    GENERATE_WALLET_SIGNATURE_MESSAGE
  )
  const [authorizeWallet] = useMutation(AUTHORIZE_WALLET)

  /**
   * artist用ウォレット連携認証メッセージ発行・ウォレット連携
   */
  const [artistGenerateWalletSignatureMessage] = useMutation(
    ARTIST_GENERATE_WALLET_SIGNATURE_MESSAGE
  )
  const [artistAuthorizeWallet] = useMutation(ARTIST_AUTHORIZE_WALLET)

  /**
   * 認証メッセージによるウォレット認証・firestore userDocへのウォレットアドレス登録
   * @return true: 完了 error: 各種エラー
   */
  const userAuthorizeWalletBySignatureMessage = async () => {
    if (!web3auth.provider) throw new Error('web3auth provider not found')

    const provider = new ethers.providers.Web3Provider(web3auth.provider)
    const signer = provider.getSigner()

    const account = await getWalletAddress()

    /**
     * 署名用メッセージ取得mutation（ユーザー用）
     */
    const { data } = await generateWalletSignatureMessage()
    const signatureMessage =
      data?.generateWalletSignatureMessage?.authToken?.signatureMessage

    if (!signatureMessage) throw new Error(t('signatureError'))

    /**
     * 署名用メッセージにて署名
     */
    const signature = await signWalletBySignatureMessage(
      signer,
      signatureMessage
    )

    /**
     * バックエンド側にてアドレス登録（ユーザー用）
     */
    await authorizeWallet({
      variables: { inputs: { publicAddress: account, signature } },
    })

    return true
  }

  /**
   * 認証メッセージによるウォレット認証・firestore artistDocへのウォレットアドレス登録
   * @return true: 完了 error: 各種エラー
   */
  const artistAuthorizeWalletBySignatureMessage = async () => {
    if (!web3auth.provider) throw new Error('web3auth provider not found')

    const provider = new ethers.providers.Web3Provider(web3auth.provider)
    const signer = provider.getSigner()

    const account = await getWalletAddress()

    /**
     * 署名用メッセージ取得mutation（アーティスト用）
     */
    const { data } = await artistGenerateWalletSignatureMessage()

    const signatureMessage =
      data?.artistGenerateWalletSignatureMessage?.authToken?.signatureMessage

    if (!signatureMessage) throw new Error(t('signatureError'))

    /**
     * 署名用メッセージにて署名
     */
    const signature = await signWalletBySignatureMessage(
      signer,
      signatureMessage
    )

    /**
     * バックエンド側にてアドレス登録（アーティスト用）
     */
    await artistAuthorizeWallet({
      variables: { inputs: { publicAddress: account, signature } },
    })

    return true
  }

  const [web3authWalletLoading, setWeb3authWalletLoading] = useState(false)
  useEffect(() => {
    const setCryptoWalletAddressFromWeb3auth = async () => {
      if (!web3auth.provider) return

      if (loading || walletAddress) return

      setWeb3authWalletLoading(true)
      try {
        isArtist
          ? await artistAuthorizeWalletBySignatureMessage()
          : await userAuthorizeWalletBySignatureMessage()
        refetch && (await refetch())

        showMessage(t('walletCreated'))
      } catch (e) {
        showError(e as Error)
      } finally {
        setWeb3authWalletLoading(false)
      }
    }

    setCryptoWalletAddressFromWeb3auth()
  }, [loading, walletAddress, web3auth.provider])

  return {
    web3authWalletLoading,
  }
}

export { connectToWeb3Auth, initWeb3auth, openloginAdapter, web3auth }

const GENERATE_WALLET_SIGNATURE_MESSAGE = gql`
  mutation GenerateWalletSignatureMessage {
    generateWalletSignatureMessage(inputs: { cryptoWalletType: web3auth }) {
      authToken {
        signatureMessage
      }
    }
  }
`

const ARTIST_GENERATE_WALLET_SIGNATURE_MESSAGE = gql`
  mutation GenerateWalletSignatureMessage {
    artistGenerateWalletSignatureMessage(
      inputs: { cryptoWalletType: web3auth }
    ) {
      authToken {
        signatureMessage
      }
    }
  }
`

const AUTHORIZE_WALLET = gql`
  mutation AuthorizeWallet($inputs: AuthorizeWalletInput!) {
    authorizeWallet(inputs: $inputs) {
      address
      authToken {
        nonce
        used
        createdAt
        expiredAt
      }
      connectedAt
      status
      walletType
    }
  }
`

const ARTIST_AUTHORIZE_WALLET = gql`
  mutation AuthorizeWallet($inputs: AuthorizeWalletInput!) {
    artistAuthorizeWallet(inputs: $inputs) {
      address
      authToken {
        nonce
        used
        createdAt
        expiredAt
      }
      connectedAt
      status
      walletType
    }
  }
`
