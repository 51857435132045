"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../components");
var Search = function (props) {
    return (jsx_runtime_1.jsx(material_1.TextField, __assign({}, props, { sx: __assign({ bgcolor: 'grey.50', color: 'grey.300', border: 'none', borderRadius: 22, '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            } }, props.sx), InputProps: {
            startAdornment: (jsx_runtime_1.jsx(material_1.InputAdornment, __assign({ position: "start" }, { children: jsx_runtime_1.jsx(components_1.Icons.Search, { sx: { fontSize: 16 } }, void 0) }), void 0)),
        } }), void 0));
};
exports.Search = Search;
