"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
/**
 * セレクトコンポーネント
 *
 * @param listItems 選択項目情報 { value: inputの値 label: 表示文言 }
 * @param onClick リスト要素選択イベント（引数は選択中のvalue）
 */
var Select = function (_a) {
    var listItems = _a.listItems, value = _a.value, onChange = _a.onChange, placeholder = _a.placeholder, formControlProps = _a.formControlProps, disabled = _a.disabled, error = _a.error, helperText = _a.helperText;
    var handleChange = function (event) {
        onChange && onChange(event.target.value);
    };
    return (jsx_runtime_1.jsxs(material_1.FormControl, __assign({ variant: "outlined", hiddenLabel: true }, formControlProps, { children: [jsx_runtime_1.jsx(material_1.Select, __assign({ color: "secondary", disabled: disabled, inputProps: { 'aria-label': 'Without label', sx: { py: 3, px: 4 } }, value: value !== null && value !== void 0 ? value : (placeholder ? '' : listItems[0].value), onChange: handleChange, displayEmpty: true, renderValue: function (val) {
                    if (!val) {
                        return (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2", color: "text.hint" }, { children: placeholder }), void 0));
                    }
                    var option = listItems.find(function (i) { return i.value === val; });
                    return jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2" }, { children: option === null || option === void 0 ? void 0 : option.label }), void 0);
                } }, { children: listItems.map(function (_a, i) {
                    var value = _a.value, label = _a.label;
                    return (jsx_runtime_1.jsx(material_1.MenuItem, __assign({ value: value }, { children: label }), value + i));
                }) }), void 0), !!helperText && (jsx_runtime_1.jsx(material_1.FormHelperText, __assign({ error: error }, { children: helperText }), void 0))] }), void 0));
};
exports.Select = Select;
