"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioRewind = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var AudioRewind = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 12L12 17.6864C11.9955 17.8038 11.9221 17.9099 11.8079 17.9638C11.6937 18.0177 11.5567 18.011 11.4494 17.9463L2.14272 12.2762C2.05237 12.2083 2 12.107 2 12C2 11.893 2.05237 11.7917 2.14272 11.7238L11.4494 6.05369C11.5567 5.98898 11.6937 5.98229 11.8079 6.03619C11.9221 6.09009 11.9955 6.19619 12 6.31364L12 12ZM22 6.31364V17.6864C21.9955 17.8038 21.9221 17.9099 21.8079 17.9638C21.6937 18.0177 21.5567 18.011 21.4494 17.9463L12.1427 12.2762C12.0524 12.2083 12 12.107 12 12C12 11.893 12.0524 11.7917 12.1427 11.7238L21.4494 6.05369C21.5567 5.98898 21.6937 5.98229 21.8079 6.03619C21.9221 6.09009 21.9955 6.19619 22 6.31364Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.AudioRewind = AudioRewind;
