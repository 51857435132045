import { App, BackButtonListener, StateChangeListener } from '@capacitor/app'
import { Capacitor, PluginListenerHandle } from '@capacitor/core'
import { Market } from '@ionic-native/market'
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from 'capacitor-native-settings'
import { useEffect, useRef, useState } from 'react'

import { config } from '@/config'
import { useRouter } from '@/core'
import { removeRegisterNewMembership } from '@/core/native/storage'
import { getLatestVersion } from '@/lib/firebase/remoteConfig'

/**
 * The App API handles high level App state and events. For example, this API emits events when the app enters and leaves the foreground, handles deeplinks, opens other apps, and manages persisted plugin state.
 */

/**
 * @description
 * Listen for the hardware back button event (Android only).
 */
const backButtonListener = (
  onBackButton: BackButtonListener
): Promise<PluginListenerHandle> | undefined => {
  if (Capacitor.getPlatform() === 'android') {
    return App.addListener('backButton', onBackButton)
  }
}

/**
 * @description
 * Remove all Listener @capacitor/app
 */
const removeAllNativeAppListeners = () => {
  App.removeAllListeners()

  return
}

/**
 * @description
 * ネイティブバックボタンリスナーとの接続用hook
 */
export const useBackButtonListener = () => {
  const { router } = useRouter()
  const eventRef = useRef<Promise<PluginListenerHandle>>()

  useEffect(() => {
    ;(async () => {
      eventRef.current = backButtonListener(({ canGoBack }) => {
        if (!canGoBack) return App.exitApp()
        router.goBack()
      })
    })()

    return () => {
      ;(async () => {
        ;(await eventRef.current)?.remove()
      })()
    }
  }, [router])

  return
}

/**
 * @description
 * アプリ起動時に強制アップデートが必要かチェックするhook
 * Remote Config変数の設定はこちらのDocを参照すること
 * https://app.clickup.com/t/908127/UT-14913?comment=90180032106192
 *
 * @return {boolean} forceUpdate
 */
export const useCheckShouldForceUpdate = () => {
  // 今のデバイスに登録されているアプリのバージョンを取得する
  const currentVersion = useAppVersion()

  const [shouldForceUpdate, setShouldForceUpdate] = useState(false)

  useEffect(() => {
    const platform = getPlatform()
    if (platform === 'web' || !currentVersion) {
      return
    }
    /**
     * リモートの最新バージョンとアプリの現在のバージョンを比較し、現在のバージョンがリモートで設定した値よりも古い場合は強制アップデートフラグをtrueにする。
     */
    ;(async () => {
      const remoteVersion = await getLatestVersion({
        platform,
        applicationKey: config.applicationKey,
      })

      if (!remoteVersion) return

      const [currentMajor, currentMinor, currentPatch] =
        currentVersion.split('.')
      const [remoteMajor, remoteMinor, remotePatch] = remoteVersion.split('.')

      // メジャーバージョン比較
      if (Number(remoteMajor) > Number(currentMajor)) {
        setShouldForceUpdate(true)
        return
      }
      if (Number(remoteMajor) < Number(currentMajor)) return

      // メジャーバージョンが同じ場合、マイナーバージョンを比較
      if (Number(remoteMinor) > Number(currentMinor)) {
        setShouldForceUpdate(true)
        return
      }
      if (Number(remoteMinor) < Number(currentMinor)) return

      // マイナーバージョンが同じ場合、パッチバージョンを比較
      if (Number(remotePatch) > Number(currentPatch)) {
        setShouldForceUpdate(true)
      }
    })()
  }, [currentVersion])

  return shouldForceUpdate
}

/**
 * @description
 * ネイティブ機能リスナーの解除用hook
 */
export const useRemoveAllNativeAppListeners = () => {
  useEffect(() => {
    return removeAllNativeAppListeners
  }, [])

  return
}

/**
 * @description
 * hook for remove storage when app close
 */
export const useRemoveStorageWhenClose = () => {
  const platform = Capacitor.getPlatform()
  useEffect(() => {
    if (platform === 'web') return
    let listener: PluginListenerHandle
    ;(async () => {
      listener = await App.addListener('appStateChange', ({ isActive }) => {
        if (!isActive) removeRegisterNewMembership()
      })
    })()

    return () => {
      listener?.remove()
    }
  }, [])

  return
}

/**
 * @description
 * 現在のプラットフォームに対応したストアのURLを開く（ios/androidのみ）
 */
export const openStoreUrl = () => {
  /**
   * @see https://stackoverflow.com/questions/37292809/opening-market-from-ionic-fails?rq=1
   */
  const getStoreOpenKey = () => {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        return `id${config.appInfo.ios.storeId}`
      case 'android':
        return config.appInfo.android.bundleId
      default:
        throw new Error('Non-native platform is not supported')
    }
  }

  return Market.open(getStoreOpenKey())
}

/**
 * @description
 * ios/android設定画面を開く、webの場合は引数で実行する関数を渡す
 */
export const openSetting = ({ webSetting = () => {} }) => {
  const platform = Capacitor.getPlatform()

  if (platform === 'web') return webSetting()

  return platform === 'ios'
    ? NativeSettings.openIOS({
        option: IOSSettings.App,
      })
    : NativeSettings.openAndroid({
        option: AndroidSettings.ApplicationDetails,
      })
}

/**
 * @description
 * アプリのフォアグラウンド/バックグラウンド切り替えリスナー
 */
export const appStateChangeListener = async (
  onAppStateChange: StateChangeListener
) => {
  const plugin = await App.addListener('appStateChange', onAppStateChange)

  return { remove: plugin.remove }
}

type Platform = 'web' | 'ios' | 'android'
export const getPlatform = () => {
  const platform = Capacitor.getPlatform()

  return platform as Platform
}
export const isNativePlatform = () => Capacitor.isNativePlatform()

/**
 * @description
 * アプリのバージョンの取得
 */
export const useAppVersion = () => {
  const [version, setVersion] = useState<string | null>(null)

  useEffect(() => {
    ;(async () => {
      if (Capacitor.getPlatform() == 'web') return

      const appInfo = await App.getInfo()
      setVersion(appInfo.version)
    })()
  }, [])

  return version
}

/**
 * @description
 * 引数で渡した値から、プラットフォーム毎に適切な値を返す
 */
type PlatformResult<T, U, V> = {
  ios: T
  android: U
  web: V
}
export const getPlatformResult = <T, U = T, V = T>({
  ios,
  android,
  web,
}: PlatformResult<T, U, V>): T | U | V => {
  const platform = getPlatform()

  return platform === 'ios' ? ios : platform === 'android' ? android : web
}

export const marketOpen = ({
  iosAppId,
  androidAppId,
}: {
  iosAppId: string
  androidAppId: string
}) => {
  const platform = getPlatform()

  return Market.open(platform === 'ios' ? `id${iosAppId}` : androidAppId)
}
