"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var components_1 = require("../../../components");
/**
 * アラート表示用コンポーネント
 * @param severity 重要度 info | error (デフォルト:info)
 * @param message 表示メッセージ
 * @param onClose クローズボタンイベント
 * @param buttons メッセージ下のアクション用ボタン（複数許容、ボタンエレメントを配列で渡す）
 */
var Alert = function (_a) {
    var _b = _a.severity, severity = _b === void 0 ? 'info' : _b, message = _a.message, _c = _a.onClose, onClose = _c === void 0 ? null : _c, _d = _a.buttons, buttons = _d === void 0 ? [] : _d;
    var _e = react_1.useState(false), closed = _e[0], setClosed = _e[1];
    var handleClose = function (e) {
        onClose && onClose(e);
        setClosed(true);
    };
    return !closed ? (jsx_runtime_1.jsxs(material_1.Alert, __assign({ icon: false, severity: severity, sx: {
            position: 'relative',
            py: 7,
            pl: { xs: 6, md: 7 },
            pr: { xs: 5, md: 9 },
            m: { xs: 4, md: 7 },
            borderRadius: 3,
            boxSizing: 'border-box',
        } }, { children: [jsx_runtime_1.jsx(components_1.IconButton.Close, { onClick: handleClose, sx: {
                    position: 'absolute',
                    top: 12,
                    right: 12,
                } }, void 0), jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gridGap: 20,
                } }, { children: [jsx_runtime_1.jsx(material_1.AlertTitle, __assign({ sx: {
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                        } }, { children: message }), void 0), buttons.length >= 1 && (jsx_runtime_1.jsx(material_1.Box, __assign({ sx: {
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 4,
                            width: '100%',
                            justifyContent: { xs: 'center', md: 'start' },
                        } }, { children: buttons.map(function (el) { return el; }) }), void 0))] }), void 0)] }), void 0)) : null;
};
exports.Alert = Alert;
