import 'swiper/swiper.min.css'

import { Capacitor } from '@capacitor/core'
import {
  createTheme as createMuiTheme,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
} from '@mui/material'
import { FC } from 'react'
import { isIOS } from 'react-device-detect'

import { config } from '@/config'

import {
  defaultDarkTheme,
  defaultLightTheme,
  generateComponentsStyle,
} from './themes'
/**
 * @description defaultTheme
 * 最も基礎となるデザインテーマのProvider。
 * utoniq-appで共通して用いる。
 */

const createTheme = () => {
  const theme = config.theme.dark ? defaultDarkTheme : defaultLightTheme

  return createMuiTheme(theme, {
    components: generateComponentsStyle(createMuiTheme(theme)),
  })
}

export const defaultTheme = createTheme()

export const StyleProvider: FC = ({ children }) => {
  const isNative = Capacitor.isNativePlatform()

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--sat': 'env(safe-area-inset-top)',
            backgroundColor: defaultTheme.palette.background.default,
          },
          '.ion-page': {
            contain: 'initial',
            overflow: 'unset',
          },
          body: {
            ...(isMobileDevice()
              ? {
                  userSelect: 'none',
                  WebkitUserSelect: 'none',
                  msUserSelect: 'none',
                }
              : {}),
            overflowX: 'hidden',
            overscrollBehaviorY: 'none',
            height: isIOS && isNative ? 'calc(100vh + 0.5px)' : 'auto',
            scrollbarWidth: 'none',
          },
          '::-webkit-scrollbar, body::-webkit-scrollbar': {
            height: 0,
            width: 0,
            display: 'none',
            MozAppearance: 'none',
            WebkitAppearance: 'none',
          },
          img: {
            userSelect: 'none',
            WebkitUserSelect: 'none',
            msUserSelect: 'none',
            pointerEvents: 'none',
          },
          'input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button':
            {
              '-webkit-appearance': 'none',
              margin: 0,
            },
        }}
      />
      {children}
    </ThemeProvider>
  )
}

/**
 * detect is mobile base on userAgent
 */
export function isMobileDevice() {
  if (typeof window === 'undefined') {
    return false
  }
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}
