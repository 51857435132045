"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Delivery = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Delivery = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.6115 2.34301L22.1115 6.46801C22.2291 6.53273 22.3273 6.62783 22.3956 6.7434C22.4639 6.85896 22.5 6.99075 22.5 7.12501V16.875C22.5 17.0093 22.4639 17.1411 22.3956 17.2566C22.3273 17.3722 22.2291 17.4673 22.1115 17.532L14.6115 21.657C14.5008 21.7181 14.3764 21.7501 14.25 21.7501C14.1236 21.7501 13.9992 21.7181 13.8885 21.657L10.5 19.7933L11.223 18.48L13.5 19.7325V11.6933L6.38851 7.78201C6.27092 7.71727 6.17286 7.62215 6.10457 7.50659C6.03627 7.39102 6.00024 7.25925 6.00024 7.12501C6.00024 6.99078 6.03627 6.859 6.10457 6.74344C6.17286 6.62787 6.27092 6.53276 6.38851 6.46801L13.8885 2.34301C13.9993 2.28209 14.1236 2.25014 14.25 2.25014C14.3764 2.25014 14.5008 2.28209 14.6115 2.34301ZM20.1938 7.12501L14.25 3.85651L8.30626 7.12501L14.25 10.3935L20.1938 7.12501ZM15 19.7318L21 16.4318V8.39251L15 11.6925V19.7318ZM1.5 12H7.5V10.5H1.5V12ZM9 18H3V16.5H9V18ZM4.5 15H10.5V13.5H4.5V15Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Delivery = Delivery;
