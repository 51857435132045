"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Close = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Close = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M19.6944 5.78133C20.1019 5.37383 20.1019 4.71313 19.6944 4.30563C19.2869 3.89812 18.6262 3.89812 18.2187 4.30563L12 10.5243L5.78133 4.30563C5.37383 3.89813 4.71313 3.89813 4.30563 4.30563C3.89812 4.71313 3.89812 5.37383 4.30563 5.78133L10.5243 12L4.30563 18.2187C3.89812 18.6262 3.89812 19.2869 4.30563 19.6944C4.71313 20.1019 5.37382 20.1019 5.78133 19.6944L12 13.4757L18.2187 19.6944C18.6262 20.1019 19.2869 20.1019 19.6944 19.6944C20.1019 19.2869 20.1019 18.6262 19.6944 18.2187L13.4757 12L19.6944 5.78133Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Close = Close;
