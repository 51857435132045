"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Search = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Search = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 10.9C2 15.8 6 19.8 10.9 19.8C12.9949 19.8 14.9253 19.0689 16.4489 17.8489L20.3 21.7C20.5 21.9 20.7 22 21 22C21.3 22 21.5 21.9 21.7 21.7C22.1 21.3 22.1 20.7 21.7 20.3L17.8489 16.4489C19.0689 14.9252 19.8 12.9949 19.8 10.9C19.8 6 15.8 2 10.9 2C6 2 2 6 2 10.9ZM16.072 15.4589C17.1466 14.2417 17.8 12.6446 17.8 10.9C17.8 7.1 14.7 4 10.9 4C7.1 4 4 7.1 4 10.9C4 14.7 7.1 17.8 10.9 17.8C12.6446 17.8 14.2417 17.1466 15.4589 16.072C15.5083 15.9382 15.5887 15.8114 15.7 15.7C15.8114 15.5886 15.9383 15.5083 16.072 15.4589Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Search = Search;
