"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarUploadField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
/**
 *
 * avatar upload field component
 *
 * @param error react-hook-form error
 * @param onClick avatar click event
 * @param titleProps custom title typography
 * @param avatarProps custom avatar
 * @param t i18next key function
 * @param sx custom sx
 * @param args react-hook-form register params. ex:) {...register('fieldName')
 */
exports.AvatarUploadField = react_1.forwardRef(function (_a, ref) {
    var type = _a.type, error = _a.error, onClick = _a.onClick, titleProps = _a.titleProps, avatarProps = _a.avatarProps, t = _a.t, sx = _a.sx, register = __rest(_a, ["type", "error", "onClick", "titleProps", "avatarProps", "t", "sx"]);
    var name = register.name;
    var _b = react_1.useState(null), avatar = _b[0], setAvatar = _b[1];
    var handleClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var file;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onClick()];
                case 1:
                    file = _a.sent();
                    if (!file) {
                        return [2 /*return*/];
                    }
                    setAvatar(file);
                    return [2 /*return*/];
            }
        });
    }); };
    return (jsx_runtime_1.jsxs(material_1.Stack, __assign({ alignItems: "center", justifyContent: "center", sx: sx, onClick: handleClick }, { children: [jsx_runtime_1.jsx(material_1.Avatar, __assign({ sx: { width: 64, height: 64 } }, avatarProps, { src: avatar ? avatar.fileUrl : avatarProps === null || avatarProps === void 0 ? void 0 : avatarProps.src }), void 0), jsx_runtime_1.jsx("input", __assign({}, register, { type: "hidden" }), void 0), jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1", color: "text.secondary" }, titleProps, { children: t(name + "Title") }), void 0), error && jsx_runtime_1.jsx(material_1.FormHelperText, __assign({ error: true }, { children: error.message }), void 0)] }), void 0));
});
