"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Trash = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Trash = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M20.2 6.2H3.7c-.6 0-1-.4-1-1s.4-1 1-1h16.5c.6 0 1 .4 1 1s-.4 1-1 1zM9.8 16.8c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6c0 .5-.5 1-1 1zM14.2 16.8c-.6 0-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1v6c0 .5-.4 1-1 1z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M18 21.2H6c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.8-.5-1.2V5.2c0-.6.4-1 1-1s1 .4 1 1v14h11.5v-14c0-.6.4-1 1-1s1 .4 1 1v14.2c0 .5-.2.9-.5 1.2-.4.5-.8.6-1.3.6z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M15.8 6.2c-.6 0-1-.4-1-1V3.8c0-.1-.1-.3-.1-.4-.1-.1-.2-.1-.4-.1H9.8c-.1 0-.3.1-.4.1-.1.1-.2.2-.2.4v1.5c0 .6-.4 1-1 1s-1-.4-1-1V3.8c0-.7.3-1.3.8-1.8s1.1-.7 1.8-.7h4.5c.7 0 1.3.3 1.8.7.5.5.7 1.1.7 1.8v1.5c0 .5-.5.9-1 .9z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Trash = Trash;
