"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpArrow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var UpArrow = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.8304 16.9342C20.3145 17.3929 19.5244 17.3464 19.0657 16.8304L12 8.88149L4.93425 16.8304C4.4756 17.3464 3.68551 17.3929 3.16953 16.9342C2.65355 16.4756 2.60708 15.6855 3.06572 15.1695L12 5.11849L20.9342 15.1695C21.3929 15.6855 21.3464 16.4756 20.8304 16.9342Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.UpArrow = UpArrow;
