"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Next = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Next = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.33579 6.27215C9.03829 6.60683 9.06844 7.11932 9.40313 7.41682L14.5592 12L9.40313 16.5832C9.06844 16.8807 9.03829 17.3932 9.33579 17.7279C9.63329 18.0625 10.1458 18.0927 10.4805 17.7952L17.0001 12L10.4805 6.20481C10.1458 5.90731 9.63329 5.93746 9.33579 6.27215Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Next = Next;
