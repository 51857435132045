"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Image = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M4.71429 4C4.3198 4 4 4.3198 4 4.71429V14.7286L8.76004 9.96852C9.15056 9.578 9.78373 9.578 10.1742 9.96852L13.53 13.3243L15.5272 11.3271C15.9152 10.9391 16.5434 10.9362 16.9349 11.3207L20 14.3306V4.71429C20 4.3198 19.6802 4 19.2857 4H4.71429ZM9.46714 12.0898L4 17.557V19.2857C4 19.6802 4.3198 20 4.71429 20H19.2857C19.6802 20 20 19.6802 20 19.2857V17.1337L16.2407 13.442L14.2371 15.4456C13.8466 15.8361 13.2134 15.8361 12.8229 15.4456L9.46714 12.0898ZM2 4.71429V17.1428V19.2857C2 20.7848 3.21523 22 4.71429 22H19.2857C20.7848 22 22 20.7848 22 19.2857V16.7143V4.71429C22 3.21523 20.7848 2 19.2857 2H4.71429C3.21523 2 2 3.21523 2 4.71429ZM14.2855 7.71413C14.2855 7.31964 14.6053 6.99985 14.9998 6.99985C15.3943 6.99985 15.7141 7.31964 15.7141 7.71413C15.7141 8.10862 15.3943 8.42842 14.9998 8.42842C14.6053 8.42842 14.2855 8.10862 14.2855 7.71413ZM14.9998 4.99985C13.5007 4.99985 12.2855 6.21507 12.2855 7.71413C12.2855 9.21319 13.5007 10.4284 14.9998 10.4284C16.4989 10.4284 17.7141 9.21319 17.7141 7.71413C17.7141 6.21507 16.4989 4.99985 14.9998 4.99985Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Image = Image;
