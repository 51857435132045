"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadioButtons = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
/**
 * ラジオボタンを複数並べて1つを選択するコンポーネント
 *
 * @param listItems 選択項目情報 { value: inputの値 label: 表示文言 }
 * @param onClick ラジオボタン選択イベント（引数は選択中のvalue）
 */
var RadioButtons = function (_a) {
    var listItems = _a.listItems, onChange = _a.onChange, _b = _a.value, value = _b === void 0 ? '' : _b, error = _a.error, props = __rest(_a, ["listItems", "onChange", "value", "error"]);
    return (jsx_runtime_1.jsxs(material_1.FormControl, { children: [jsx_runtime_1.jsx(material_1.RadioGroup, __assign({ value: value, onChange: function (value) { return onChange(value.target.value); } }, props, { children: listItems.map(function (_a) {
                    var value = _a.value, label = _a.label, disabled = _a.disabled;
                    return (jsx_runtime_1.jsx(material_1.FormControlLabel, { value: value, disabled: disabled, control: jsx_runtime_1.jsx(material_1.Radio, {}, void 0), label: jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body1" }, { children: label }), void 0) }, value));
                }) }), void 0), !!(error === null || error === void 0 ? void 0 : error.message) && (jsx_runtime_1.jsx(material_1.FormHelperText, __assign({ error: true }, { children: error === null || error === void 0 ? void 0 : error.message }), void 0))] }, void 0));
};
exports.RadioButtons = RadioButtons;
