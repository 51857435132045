"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reload = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Reload = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M18.3649 5.64576C16.9991 4.27577 15.2282 3.38232 13.3147 3.09785C11.4012 2.81337 9.44702 3.15301 7.74174 4.06641C6.03646 4.97982 4.67086 6.41837 3.84738 8.16885C3.02389 9.91933 2.78635 11.8886 3.16995 13.7846C3.55355 15.6807 4.53788 17.4028 5.97704 18.6955C7.4162 19.9882 9.23359 20.7828 11.1598 20.9615C13.0861 21.1402 15.0186 20.6935 16.671 19.6876C18.3234 18.6817 19.6078 17.1702 20.3336 15.377H17.8361C17.1408 16.6052 16.078 17.5845 14.7971 18.1772C13.5163 18.77 12.0818 18.9463 10.6956 18.6815C9.3093 18.4166 8.04091 17.7239 7.06877 16.7008C6.09664 15.6776 5.46968 14.3755 5.27605 12.9775C5.08242 11.5795 5.33187 10.1559 5.98931 8.90707C6.64676 7.6582 7.67913 6.64682 8.94124 6.01517C10.2033 5.38352 11.6317 5.16336 13.0254 5.38567C14.4192 5.60798 15.7081 6.26156 16.7111 7.2545L13.1336 10.877H21.0086V3.00201L18.3649 5.64576Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Reload = Reload;
