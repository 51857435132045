"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CautionText = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var Icons_1 = require("../Icons");
var CautionText = function (_a) {
    var children = _a.children, type = _a.type, hiddenIcon = _a.hiddenIcon, _b = _a.sx, sx = _b === void 0 ? {} : _b, typographyProps = _a.typographyProps;
    var newSx = react_1.useMemo(function () {
        switch (type) {
            case 'normal':
                return __assign({}, sx);
            case 'info':
                return __assign({ p: 2 }, sx);
            default:
                return __assign({ p: 2, borderWidth: 1, borderStyle: 'solid', borderColor: 'error.main' }, sx);
        }
    }, [type]);
    return (jsx_runtime_1.jsxs(material_1.Stack, __assign({ sx: newSx, direction: "row", spacing: 1 }, { children: [type !== 'normal' && !hiddenIcon && (jsx_runtime_1.jsx(Icons_1.Info, { sx: {
                    width: 16,
                    height: 16,
                    color: type === 'info' ? 'primary.main' : 'error.main',
                } }, void 0)), jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle2" }, typographyProps, { children: children }), void 0)] }), void 0));
};
exports.CautionText = CautionText;
