"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pencil = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Pencil = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { htmlColor: props.color }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M8.19998 21C7.89998 21 7.69998 20.9 7.49998 20.7C7.09998 20.3 7.09998 19.7 7.49998 19.3L19.7 7.1C19.8 7 19.9 6.9 19.9 6.8C20 6.6 20 6.5 20 6.3C20 6.1 20 6 19.9 5.9C19.8 5.8 19.8 5.7 19.7 5.6L18.4 4.3C18.3 4.2 18.2 4.1 18.1 4.1C17.8 4 17.6 4 17.3 4.1C17.2 4.2 17.1 4.2 17 4.3L4.79998 16.5C4.39998 16.9 3.79998 16.9 3.39998 16.5C2.99998 16.1 2.99998 15.5 3.39998 15.1L15.5 2.9C15.8 2.6 16.1 2.4 16.5 2.2C17.2 1.9 18.1 1.9 18.8 2.2C19.2 2.4 19.5 2.6 19.8 2.9L21.1 4.2C21.4 4.5 21.6 4.8 21.8 5.2C21.9 5.5 22 5.9 22 6.3C22 6.7 21.9 7.1 21.8 7.5C21.6 7.9 21.4 8.2 21.1 8.5L8.89998 20.7C8.69998 20.9 8.49998 21 8.19998 21Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M3 22C2.7 22 2.5 21.9 2.3 21.7C2.1 21.5 2 21.1 2 20.8L3 15.6C3.1 15.1 3.6 14.7 4.2 14.8C4.8 14.9 5.1 15.4 5 16L4.2 19.8L8 19C8.5 18.9 9.1 19.2 9.2 19.8C9.3 20.3 9 20.9 8.4 21L3.2 22C3.1 22 3.1 22 3 22Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M18.9 10.3C18.6 10.3 18.4 10.2 18.2 10L14 5.8C13.6 5.4 13.6 4.8 14 4.4C14.4 4 15 4 15.4 4.4L19.6 8.6C20 9 20 9.6 19.6 10C19.4 10.2 19.2 10.3 18.9 10.3Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Pencil = Pencil;
