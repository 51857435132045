import type { ApolloError } from '@apollo/client'
import { captureException, getCurrentScope } from '@sentry/capacitor'
import { AuthError } from 'firebase/auth'
export type Metadata = {
  error?: Error
  userMessage?: String
  [key: string]: unknown
}

export const errors = {
  noImplemented(message: string, metadata?: Metadata) {
    return new (class NoImplementedError extends Error {
      constructor(message: string, metadata?: Metadata) {
        super(message)
      }
    })(message, metadata)
  },
}

type ErrorObj =
  | Error
  | THEOplayer.ErrorEvent
  | AuthError
  | ApolloError
  | [event: THEOplayer.ContentProtectionErrorEvent]

/**
 * SentryのcaptureExceptionでエラー出力を行う関数
 * withScopeのsetExtraでエラーに追加情報を設定する。
 * @param error エラーオブジェクト
 * @param occured どこでエラー出力が行われたかを示す文字列
 */
export function captureError(error: ErrorObj, occured: string) {
  const scope = getCurrentScope()
  scope?.setTag('occured', occured)
  captureException(error)
  return null
}
