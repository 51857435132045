/**
 * クライアント認証処理を共通化したモジュール
 */

import { useEffect, useState } from 'react'

import { config } from '@/config'

import type { User } from './auth.interface'
import { makeAuthHook } from './makeAuthHook'

const { AuthProvider, useAuth, useCurrentUser, getUser, getAccessToken } =
  makeAuthHook(config.auth.driver)

export { AuthProvider, getAccessToken, getUser, useAuth, useCurrentUser }
export * from './auth.interface'
export * from './useLoginModal'

export const useTrackIdentify = (
  callback: (props: {
    user: User | null
    applicationKey: string | null
  }) => void
) => {
  const { user } = useCurrentUser()
  const applicationKey = config.applicationKey

  useEffect(() => {
    callback({ user, applicationKey })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
}

/**
 * @description useAccessToken
 * DRM認証で使用するアクセストークン取得hook
 */
export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null)

  useEffect(() => {
    getAccessToken().then(token => {
      setAccessToken(token)
    })
  }, [])

  return { accessToken }
}
