"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLongPress = void 0;
var react_1 = require("react");
/**
 * hook for long press
 * @param onLongPress
 * @param onClearLongPress
 * @param shouldPreventDefault
 * @param delay
 */
var useLongPress = function (onLongPress, onClearLongPress, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.shouldPreventDefault, shouldPreventDefault = _c === void 0 ? true : _c, _d = _b.delay, delay = _d === void 0 ? 300 : _d;
    var _e = react_1.useState(false), longPressTriggered = _e[0], setLongPressTriggered = _e[1];
    var timeout = react_1.useRef();
    var target = react_1.useRef();
    var handleOnStart = react_1.useCallback(function (event) {
        if (shouldPreventDefault && event.target) {
            event.target.addEventListener('touchend', preventDefault, {
                passive: false,
            });
            target.current = event.target;
        }
        timeout.current = setTimeout(function () {
            onLongPress();
            setLongPressTriggered(true);
        }, delay);
    }, [onLongPress, delay, shouldPreventDefault]);
    var handleOnClear = react_1.useCallback(function () {
        var _a;
        onClearLongPress();
        timeout.current && clearTimeout(timeout.current);
        setLongPressTriggered(false);
        if (shouldPreventDefault && target.current) {
            (_a = target.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('touchend', preventDefault);
        }
    }, [shouldPreventDefault, onClearLongPress, longPressTriggered]);
    return {
        onMouseDown: handleOnStart,
        onTouchStart: handleOnStart,
        onMouseUp: handleOnClear,
        onMouseLeave: handleOnClear,
        onTouchEnd: handleOnClear,
    };
};
exports.useLongPress = useLongPress;
var preventDefault = function (event) {
    if (!('touches' in event))
        return;
    //@ts-ignore
    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};
