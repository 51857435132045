import { getAppLanguage, setAppLanguage } from '@/core/native/storage'

export * from './init'
import { Device } from '@capacitor/device'
import i18n from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation as useI18NextTranslation } from 'react-i18next'

import { SupportedLanguage } from '@/types/config'

import { dayjs } from '../../lib/dayjs'

export function useTranslation(
  ...props: Parameters<typeof useI18NextTranslation>
) {
  const res = useI18NextTranslation(...props)

  return {
    ...res,
    format(data: Date) {
      dayjs(data).fromNow()
    },
  }
}

/**
 * @description
 * Switch the app language by referring to the language setting of localStorage or device browser
 */
export function useLocalLanguage() {
  const [translated, setTranslated] = useState(false)
  useEffect(() => {
    if (translated) return

    const changeLanguage = async () => {
      const languageStorage = await getAppLanguage()
      const deviceLanguageCode = await Device.getLanguageCode()

      const languageCode = getLongLanguageCode(
        languageStorage ?? deviceLanguageCode.value
      )
      const [shortLanguageCode] = languageCode.split('-')

      // i18nの言語情報をdayJSに設定
      dayjs.locale(shortLanguageCode)
      await i18n.changeLanguage(shortLanguageCode)
      await setAppLanguage(languageCode)
    }
    changeLanguage()
    setTranslated(true)
  }, [translated])
}

/**
 * return long language code
 * @param language
 *
 * @example
 * getLongLanguageCode('ja-JP') // return 'ja-JP'
 * getLongLanguageCode('ja') // return 'ja-JP'
 */
export function getLongLanguageCode(
  language: SupportedLanguage | string
): SupportedLanguage {
  const languageCodes: Record<SupportedLanguage | string, SupportedLanguage> = {
    en: 'en-US',
    'en-US': 'en-US',
    ja: 'ja-JP',
    'ja-JP': 'ja-JP',
  }
  return languageCodes[language] ?? 'ja-JP'
}
