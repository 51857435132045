"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialArea = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../components");
/**
 * アップロードリソース表示エリア
 * 画像アップロード時、画像をエリアにフィットさせて表示
 * 画像以外をアップロード時 アイコン＋アップロードリソース名を表示
 *
 * @param type リソースのタイプ
 * @param resource { id: リソースのID, resourceName: リソースの名前, imageUrl: リソース（画像）表示用url, imageUploadPath: 画像アップロード用パス}
 * @param sx
 * @param onClose クローズアイコンのクリックイベント
 */
var MaterialArea = function (_a) {
    var type = _a.type, resource = _a.resource, sx = _a.sx, onClose = _a.onClose, disabled = _a.disabled;
    return (jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
            position: 'relative',
            bgcolor: 'grey.100',
            width: type === 'image' ? 'fit-content' : '100%',
            borderRadius: 1,
            overflow: 'hidden',
            margin: '0 auto',
            opacity: disabled ? '0.6' : 1,
        } }, { children: [jsx_runtime_1.jsx(components_1.IconButton.Close, { size: "small", disabled: disabled, sx: {
                    position: 'absolute',
                    top: 6,
                    right: 6,
                    bgcolor: type === 'image' ? 'grey.50' : 'unset',
                }, onClick: onClose }, void 0), type === 'image' ? (
            // 画像リソース用描画エリア
            jsx_runtime_1.jsx(material_1.Box, __assign({ sx: sx }, { children: jsx_runtime_1.jsx(material_1.Box, { component: "img", src: resource === null || resource === void 0 ? void 0 : resource.imageUrl, alt: "item_image", width: "100%", height: "100%", sx: {
                        objectFit: 'contain',
                        objectPosition: 'center',
                    } }, void 0) }), void 0)) : (
            // 画像リソース以外用描画エリア
            jsx_runtime_1.jsxs(material_1.Stack, __assign({ direction: "row", alignItems: "center", spacing: 2, flex: 1, sx: { px: 5, py: 3, boxSizing: 'border-box' } }, { children: [jsx_runtime_1.jsx(IconSelector, { type: type }, void 0), jsx_runtime_1.jsx(ClampTypography, __assign({ variant: "body2" }, { children: resource === null || resource === void 0 ? void 0 : resource.resourceName }), void 0)] }), void 0))] }), void 0));
};
exports.MaterialArea = MaterialArea;
var IconSelector = function (_a) {
    var type = _a.type;
    switch (type) {
        case 'pdf':
            return jsx_runtime_1.jsx(components_1.Icons.Magazine, { fontSize: "small", sx: { color: 'grey.700' } }, void 0);
        case 'audio':
            return jsx_runtime_1.jsx(components_1.Icons.Audio, { fontSize: "small", sx: { color: 'grey.700' } }, void 0);
        case 'video':
            return jsx_runtime_1.jsx(components_1.Icons.Video, { fontSize: "small", sx: { color: 'grey.700' } }, void 0);
        default:
            return null;
    }
};
var ClampTypography = material_1.styled(material_1.Typography)({
    paddingRight: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
});
