import { createContext, ReactNode, useContext, useState } from 'react'

/**
 * @description
 * アプリ内で現在再生されている動画・音声メディアについての状態。
 * ReactPlayerコンポーネントを再生する際にplayingResourceを更新し、他のメディアを停止する。
 */

type ResourceUri = string | false

type PlayingResourceContext = {
  playingResource: ResourceUri
  playResource: (uri: ResourceUri) => void
  pauseResource: () => void
}

const Context = createContext({} as PlayingResourceContext)

export const usePlayingResource = () => {
  return useContext(Context)
}

export const PlayingResourceProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [playingResource, setPlayingResource] = useState<ResourceUri>(false)

  const playResource = (uri: ResourceUri) => {
    setPlayingResource(uri || false)
  }

  const pauseResource = () => setPlayingResource(false)

  return (
    <Context.Provider value={{ playingResource, playResource, pauseResource }}>
      {children}
    </Context.Provider>
  )
}
