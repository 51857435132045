"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rank = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Rank = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M24 7.29091C24 6.30909 23.175 5.52727 22.1813 5.52727C21.1688 5.52727 20.3625 6.32727 20.3625 7.29091C20.3625 7.76364 20.55 8.2 20.8688 8.52727C19.6313 11.0182 17.25 11.8909 15.4313 11.0909C13.8 10.3818 13.2 8.38182 12.9938 6.83636C13.6688 6.49091 14.1188 5.81818 14.1188 5.03636C14.1 3.90909 13.1625 3 12 3C10.8375 3 9.9 3.90909 9.9 5.03636C9.9 5.81818 10.35 6.49091 11.025 6.83636C10.8187 8.38182 10.2188 10.4 8.5875 11.0909C6.75 11.8727 4.3875 11.0182 3.13125 8.52727C3.45 8.21818 3.6375 7.78182 3.6375 7.29091C3.6375 6.30909 2.8125 5.52727 1.81875 5.52727C0.80625 5.52727 0 6.32727 0 7.29091C0 8.18182 0.69375 8.92727 1.59375 9.03636L3.69375 16.4909H20.3063L22.4062 9.03636C23.3062 8.92727 24 8.2 24 7.29091Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M21.3374 19.6909C21.3374 20.4182 20.7374 21 19.9874 21H4.04995C3.29995 21 2.69995 20.4182 2.69995 19.6909V19.5818C2.69995 18.8546 3.29995 18.2727 4.04995 18.2727H19.9874C20.7374 18.2727 21.3374 18.8546 21.3374 19.5818V19.6909Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Rank = Rank;
