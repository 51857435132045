"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Zoom = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Zoom = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M3.25 5.19444V9.08333H5.19444V5.19444H9.08333V3.25H5.19444C4.125 3.25 3.25 4.125 3.25 5.19444ZM5.19444 14.9167H3.25V18.8056C3.25 19.875 4.125 20.75 5.19444 20.75H9.08333V18.8056H5.19444V14.9167ZM18.8056 18.8056H14.9167V20.75H18.8056C19.875 20.75 20.75 19.875 20.75 18.8056V14.9167H18.8056V18.8056ZM18.8056 3.25H14.9167V5.19444H18.8056V9.08333H20.75V5.19444C20.75 4.125 19.875 3.25 18.8056 3.25Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Zoom = Zoom;
