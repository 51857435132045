"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InformationModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Feedback_1 = require("../../Feedback");
var InformationModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, title = _a.title, content = _a.content, buttons = _a.buttons, cancelText = _a.cancelText, disableEnforceFocus = _a.disableEnforceFocus, disablePortal = _a.disablePortal, sx = _a.sx, innerSx = _a.innerSx;
    return (jsx_runtime_1.jsx(Feedback_1.Modal, __assign({ open: open, onClose: function () { return onClose(); }, disablePortal: disablePortal, disableEnforceFocus: disableEnforceFocus, sx: sx }, { children: jsx_runtime_1.jsxs(material_1.Box, __assign({ id: "modal-inner", sx: __assign({ width: 280, borderRadius: 3, px: 5, py: 7, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'grey.100', outline: 'none', textAlign: 'center' }, Object.assign({}, sx, innerSx)) }, { children: [typeof title === 'string' ? (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h5", sx: { pb: 3 } }, { children: title }), void 0)) : (title), typeof content === 'string' ? (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2", sx: { pb: 7 } }, { children: content }), void 0)) : (content), jsx_runtime_1.jsxs(material_1.Stack, __assign({ spacing: 4, alignItems: "center" }, { children: [buttons, cancelText && (jsx_runtime_1.jsx(material_1.Button, __assign({ variant: "text", color: "primary", onClick: onClose }, { children: cancelText }), void 0))] }), void 0)] }), void 0) }), void 0));
};
exports.InformationModal = InformationModal;
