"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Success = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Success = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12V11C22 10.4 21.6 10 21 10C20.4 10 20 10.4 20 11V12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12C4 7.6 7.6 4 12 4C13.1 4 13.9 4.1 14.8 4.5C15.3 4.7 15.9 4.4 16.1 3.9C16.3 3.4 16 2.8 15.5 2.6C14.4 2.2 13.3 2 12 2C6.5 2 2 6.5 2 12ZM10.5 14.8C10.7 15 11 15.1 11.2 15.1C11.5 15.1 11.7 15 11.9 14.8L19.6 7.1C20 6.7 20 6.1 19.6 5.7C19.2 5.3 18.6 5.3 18.2 5.7L11.2 12.7L8.79998 10.4C8.39998 10 7.79998 10 7.39998 10.4C6.99998 10.8 6.99998 11.4 7.39998 11.8L10.5 14.8Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Success = Success;
