import { Config } from '@/types/config'

import currentConfig from '../../apps/current/config'
import { previewConfig } from '../../apps/previewConfig'

const appConfig =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'development' &&
  process.env.NEXT_PUBLIC_APPLICATION_TYPE !== 'standalone'
    ? previewConfig
    : currentConfig

export const config: Config = {
  auth: {
    driver: 'firebase',
  },
  theme: {
    dark: appConfig.theme?.dark,
  },
  appName: appConfig.appName,
  applicationType: (appConfig.applicationType ??
    'organization') as Config['applicationType'],
  artistId: process.env.NEXT_PUBLIC_ARTIST_ID || appConfig.artistId || '',
  modules: appConfig.custom?.modules as Config['modules'],
  tutorials: (appConfig.custom?.tutorials ?? []) as Config['tutorials'],
  globalTabs: (appConfig.custom?.globalTabs ?? []) as Config['globalTabs'],
  artistTabs: (appConfig.custom?.artistTabs ?? []) as Config['artistTabs'],
  firebase: {
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || '',
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || '',
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || '',
    senderId: process.env.NEXT_PUBLIC_FIREBASE_SENDER_ID || '',
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || '',
  },
  coreApiUrl: process.env.NEXT_PUBLIC_UTONIQ_CORE_API_URL || '',
  coreApiSubscriptionUrl:
    process.env.NEXT_PUBLIC_UTONIQ_CORE_API_SUBSCRIPTION_URL || '',
  appStorageUrl: `${process.env.NEXT_PUBLIC_STORAGE_URL}${appConfig.storagePath}/`,
  commonStorageUrl: `${process.env.NEXT_PUBLIC_STORAGE_URL}common/`,
  applicationKey: appConfig.applicationKey ?? '',
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'development',
  organizationId: process.env.NEXT_PUBLIC_ORGANIZATION_ID || '',
  stripeApiKey: process.env.NEXT_PUBLIC_STRIPE_API_KEY || '',
  revenueCatApiKey: {
    ios: process.env.NEXT_PUBLIC_REVENUECAT_API_KEY_IOS || '',
    android: process.env.NEXT_PUBLIC_REVENUECAT_API_KEY_ANDROID || '',
  },
  domain: process.env.NEXT_PUBLIC_DOMAIN || '',
  appInfo: {
    ios: {
      bundleId: appConfig.ios?.bundleId || '',
      storeId: appConfig.ios?.appId || '',
    },
    android: {
      bundleId: appConfig.android.bundleId || '',
    },
  },
  platform: appConfig.platform,
  googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY || '',
  functionsEndpoint: process.env.NEXT_PUBLIC_FUNCTIONS_ENDPOINT || '',
  theoPlayerLibraryLocation:
    process.env.NEXT_PUBLIC_THEOPLAYER_LIBRARY_LOCATION || '',
  theoPlayerLicenseKey: process.env.NEXT_PUBLIC_THEOPLAYER_LICENSE_KEY || '',
  agoraAppId: process.env.NEXT_PUBLIC_AGORA_APP_ID || '',
  deepLinkUrl: appConfig.deeplinkUrl || '',
  dynamicLinkUrl: process.env.NEXT_PUBLIC_DYNAMIC_LINK_URL || '',
  fairplayCertificateUrl: process.env.NEXT_PUBLIC_FAIRPLAY_CETIFICATE_URL || '',
  helpUrl: appConfig.helpUrl || '',
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID || '',
  buildId: process.env.NEXT_PUBLIC_BUILD_ID || '',
  releaseVersion: process.env.NEXT_PUBLIC_RELEASE_VERSION || '',
  meta: {
    ogImage:
      process.env.NEXT_PUBLIC_STORAGE_URL +
      appConfig.applicationKey +
      '/images/og.png',
    url: appConfig.deeplinkUrl,
  },
  upload: {
    limit: {
      image: 10 * 1024 * 1024, // 10MB
      audio: 200 * 1024 * 1024, // 200MB
      /**
       * @description
       * ライブラリのバグ起因で現在はネイティブは50MB・web500MBの制限
       * https://app.clickup.com/t/908127/UT-8560
       */
      video: {
        android: 50 * 1024 * 1024, // 50MB
        ios: 50 * 1024 * 1024, // 50MB
        web: 500 * 1024 * 1024, // 500MB
      },
      pdf: 20 * 1024 * 1024, // 20MB
      csv: 10 * 1024 * 1024, // 10MB
    },
    mimeTypes: {
      image: ['image/png', 'image/jpeg', 'image/heic'],
      wallpaper: ['image/png', 'image/jpeg'],
      audio: [
        'audio/mpeg',
        'audio/mp3',
        'audio/wav',
        'audio/x-wav',
        'audio/x-m4a',
      ],
      video: ['video/mp4', 'video/quicktime'],
      pdf: ['application/pdf'],
      csv: ['text/csv'],
    },
  },
  abyssaleApiKey: process.env.NEXT_PUBLIC_ABYSSALE_API_KEY || '',
  nft: {
    tokenURIPrefix: process.env.NEXT_PUBLIC_TOKEN_URI_PREFIX || '',
    contractAddress: {
      ItemRegisterer:
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
          ? '0x6ddeC20a4AD35d39e85b542aBbf3E5A6683c189A'
          : '0xDeb17217DA8401a7Ce2e60630d6A31EB10796443',
      forwarderAddress: process.env.NEXT_PUBLIC_FORWARDER_ADDRESS,
    },
    metaTransactionEndpoint: process.env.NEXT_PUBLIC_META_TRANSACTION_ENDPOINT,
    web3auth: {
      clientId: process.env.NEXT_PUBLIC_UTONIQ_CORE_WEB3AUTH_CLIENTID,
      verifier: process.env.NEXT_PUBLIC_UTONIQ_CORE_WEB3AUTH_VERIFIER,
    },
  },
  dynamicLinkPrefix: appConfig?.dynamicLinkPrefix,
  dynamicLinkWebApiKey:
    process.env.NEXT_PUBLIC_FIREBASE_DYNAMIC_LINK_WEB_API_KEY || '',
}
