"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Send = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../../components");
var Send = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'small' : _b, color = _a.color, bgcolor = _a.bgcolor, sx = _a.sx, badge = _a.badge, props = __rest(_a, ["size", "color", "bgcolor", "sx", "badge"]);
    var palette = material_1.useTheme().palette;
    return (jsx_runtime_1.jsx(material_1.IconButton, __assign({ size: size, sx: __assign({ '&, :hover': {
                bgcolor: bgcolor !== null && bgcolor !== void 0 ? bgcolor : 'transparent',
                svg: {
                    color: color !== null && color !== void 0 ? color : palette.grey['600'],
                },
            } }, sx) }, props, { children: jsx_runtime_1.jsx(material_1.Badge, __assign({}, badge, { children: jsx_runtime_1.jsx(components_1.Icons.Send, {}, void 0) }), void 0) }), void 0));
};
exports.Send = Send;
