import { Box, Typography } from '@mui/material'
import { BoxProps } from '@mui/system'
import { FC } from 'react'

import { Loading } from '@/components/organisms/loading'

export type Page<P = {}> = FC<P>

export type Layout<P = {}> = FC<P>

/**
 * @typeparam S state object
 * @typeparam A action object
 * @typeparam P props object
 */
export type Template<
  S = {},
  A extends {
    [key in `on${Uppercase<string>}`]: (...props: unknown[]) => unknown
  } = {},
  P = {}
> = FC<P & { state: S } & A>
export type Organism<P = {}> = FC<P>

export type Cell<P = {}> = FC<P>

/**
 * Wrap Organization / Cell Component with each Frame Component.
 *
 * Unionのとき、Omitがうまく効かずOmitしたパラメータを使ってもエラーが出ないため注意
 */

export type OrganismFrameProps = Omit<BoxProps, 'sx'> & {
  sx?: Omit<BoxProps['sx'], MarginSxParams>
}

/**
 * Formを利用する場合、FormはOrganismとみなすので、OrganismFrameを使用しなくてよい。
 */
export const OrganismFrame: FC<OrganismFrameProps> = ({
  children,
  ...props
}) => {
  return (
    <Box component="section" {...props}>
      {children}
    </Box>
  )
}

export type CellFrameProps = Omit<BoxProps, 'sx'> & {
  sx?: Omit<BoxProps['sx'], MarginSxParams>
}

export const CellFrame: FC<CellFrameProps> = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryResultCell<P = any> = FC<{
  nodes: P[]
  loading: boolean
  renderItem: FC<{ node: P }>
  notFoundMessage: string
}>
export const QueryResultCell: QueryResultCell = ({
  nodes,
  loading,
  renderItem,
  notFoundMessage,
}) => {
  return (
    <CellFrame>
      {loading ? (
        <Loading />
      ) : nodes?.length > 0 ? (
        nodes.map((node, index) => renderItem(node, index))
      ) : (
        <Typography align="center">{notFoundMessage}</Typography>
      )}
    </CellFrame>
  )
}

type MarginSxParams =
  | 'm'
  | 'mx'
  | 'my'
  | 'mt'
  | 'mb'
  | 'mr'
  | 'ml'
  | 'margin'
  | 'marginTop'
  | 'marginBottom'
  | 'marginRight'
  | 'marginLeft'
