import { useEffect } from 'react'

import { useAnalytics } from '@/core/analytics'

import p from '../../../package.json'
import { FirebaseAnalyticsPlugin, IntercomPlugin } from '../../lib/analytics'
import { AnalyticsModule, ECommerceItem } from './AnalyticsModule'

export {
  AnalyticsProvider,
  useAnalytics,
  useAnalyticsModule,
} from '../../lib/analytics'

export function createAnalytics() {
  return new AnalyticsModule({
    app: p.name,
    version: p.version,
    plugins: [IntercomPlugin({}), FirebaseAnalyticsPlugin()],
    debug: true,
  })
}

export const useTrackViewItem = <T>(
  data: T | undefined,
  callback: (data: T) => ECommerceItem[]
) => {
  const { analytics } = useAnalytics()
  useEffect(() => {
    if (!data) return
    analytics?.track('view_item', { items: callback(data) })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
}
