import {
  Box,
  createTheme as createMuiTheme,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material'
import deepmerge from 'deepmerge'
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

import { generateComponentsStyle } from '@/components/styles/themes'
import { config } from '@/config'
import { useRouter } from '@/core'

import { useAppTheme } from './AppThemeProvider'

type ArtistThemeContextType = {
  artistId: string | null
  bgImage: string | null
  theme: ThemeOptions
}

type DispathAction = Dispatch<SetStateAction<ArtistThemeContextType>>

const ArtistThemeContext = createContext([
  {
    artistId: null,
    bgImage: null,
    theme: {},
  },
  function () {},
] as [ArtistThemeContextType, DispathAction])

export const ArtistThemeContextProvider: FC = ({ children }) => {
  const appTheme = useAppTheme()

  const artistThemeInfo = useState<ArtistThemeContextType>({
    artistId: null,
    bgImage: null,
    theme: appTheme,
  })

  return (
    <ArtistThemeContext.Provider value={artistThemeInfo}>
      {children}
    </ArtistThemeContext.Provider>
  )
}

type ArtistThemeProviderProps = {
  artistId?: string
}

export const useArtistThemeContext = () => useContext(ArtistThemeContext)

/**
 * @description ArtistThemeProvider
 *
 * ArtistThemeProviderで囲った特定のページのテンプレートにて、アーティストのテーマを表示・更新する。
 * 使い方: テーマを適用したいページのテンプレートをArtistThemeProviderで囲う。
 * cf https://app.clickup.com/908127/v/dc/vpuz-719/vpuz-4445
 */

export const ArtistThemeProvider: FC<ArtistThemeProviderProps> = ({
  children,
  artistId: propsArtistId,
}) => {
  const [themeContext, setThemeContext] = useArtistThemeContext()

  const { router } = useRouter<{ artistId: string }>()
  const artistId =
    propsArtistId ??
    router.query.artistId ??
    themeContext.artistId ??
    config.artistId

  useArtistTheme(artistId)

  return (
    <ArtistThemeContext.Provider value={[themeContext, setThemeContext]}>
      <ThemeProvider theme={themeContext.theme}>
        <Box
          className="artist-theme-provider"
          sx={{
            minHeight: '100%',
          }}
        >
          {children}
        </Box>
      </ThemeProvider>
    </ArtistThemeContext.Provider>
  )
}

/**
 * @description useArtistTheme
 * アーティストテーマ・背景画像の更新
 * アーティストIDに対応するテーマファイルを参照し、appTheme(アプリ毎テーマ)とマージしてArtistThemeコンテキストを更新する。
 */
const useArtistTheme = (artistId: string) => {
  const appTheme = useAppTheme()
  const [themeContext, setThemeContext] = useArtistThemeContext()
  const THEME_URL = `${config.appStorageUrl}artists/${artistId}/theme.json`
  const BG_URL = `${config.appStorageUrl}artists/${artistId}/background.svg`

  useEffect(() => {
    ;(async () => {
      // コンテキストと同じアーティストだった場合、コンテキストの更新は行わない
      if (!artistId || artistId === themeContext.artistId) return

      /**
       * @description
       * artistThemeがなかった場合、appThemeでオーバーライドする
       * （appThemeはdデフォルトのdark or lightテーマとアプリ固有のテーマをマージしたテーマ）
       */
      const artistTheme = await fetch(THEME_URL)
        .then(res => res.json())
        .catch(() => appTheme)

      const bgImage = await fetch(BG_URL)
        .then(({ status }) => (status === 200 ? BG_URL : null))
        .catch(() => null)

      setThemeContext({
        artistId,
        bgImage,
        theme: deepmerge(appTheme, {
          ...artistTheme,
          components: generateComponentsStyle(createMuiTheme(artistTheme)),
        }),
      })
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistId])
}

/**
 * スタンドアロンでアーティストテーマを適用する場合に用いる。
 */
export const StandAloneThemeProvider: FC = ({ children }) =>
  config.applicationType === 'standalone' ? (
    <ArtistThemeProvider artistId={config.artistId}>
      {children}
    </ArtistThemeProvider>
  ) : (
    <>{children}</>
  )
