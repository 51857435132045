"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollTag = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
function ScrollTag(_a) {
    var tags = _a.tags, value = _a.value, onClick = _a.onClick;
    var isActive = react_1.useCallback(function (tagValue) { return tagValue === value; }, [value]);
    return (jsx_runtime_1.jsx(material_1.Stack, __assign({ flexWrap: "wrap", sx: {
            py: 2,
            overflowY: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '::-webkit-scrollbar': {
                height: 0,
                width: 0,
                display: 'none',
                MozAppearance: 'none',
                WebkitAppearance: 'none',
            },
        } }, { children: jsx_runtime_1.jsx(material_1.Stack, __assign({ spacing: 2, direction: "row" }, { children: tags.map(function (tag, index) { return (jsx_runtime_1.jsx(material_1.Chip, { disabled: tag.disabled, label: jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1", color: "inherit", align: "justify", sx: {
                        whiteSpace: 'nowrap',
                    } }, { children: tag.label }), void 0), onClick: function () { return onClick(tag.value); }, color: isActive(tag.value) ? 'primary' : 'default', variant: isActive(tag.value) ? 'filled' : 'outlined', sx: {
                    height: 28,
                    borderRadius: 4,
                    bgcolor: isActive(tag.value)
                        ? 'primary.main'
                        : tag.disabled
                            ? 'transparent'
                            : 'background.default',
                    color: isActive(tag.value) ? 'background.default' : 'text.hint',
                    borderColor: isActive(tag.value) ? 'primary.main' : 'grey.100',
                    borderWidth: 1,
                    borderStyle: 'solid',
                } }, index)); }) }), void 0) }), void 0));
}
exports.ScrollTag = ScrollTag;
