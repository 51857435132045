"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reset = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Reset = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M12 16.0009C13.6711 16.0009 15.0001 14.6699 15.0001 13.0008C15.0001 11.3318 13.6711 10.0008 12 10.0008C10.329 10.0008 9 11.3318 9 13.0008C9 14.6699 10.329 16.0009 12 16.0009Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M20.8174 11.1877C20.5817 10.0361 20.1212 8.94217 19.4623 7.96867C18.8149 7.01094 17.99 6.18606 17.0323 5.53864C16.0586 4.87994 14.9648 4.4195 13.8132 4.18361C13.2083 4.06093 12.5924 4.00061 11.9752 4.0036V2.00156L8.0001 5.00162L11.9752 8.00167V6.00364C12.4592 6.00164 12.9432 6.04764 13.4102 6.14364C14.3052 6.32705 15.1554 6.68486 15.9122 7.19666C16.6586 7.69991 17.301 8.3423 17.8043 9.0887C18.5856 10.2439 19.0022 11.6071 19.0003 13.0018C19.0001 13.9376 18.8131 14.8642 18.4502 15.7268C18.2738 16.1424 18.0582 16.5402 17.8063 16.9148C17.5534 17.2872 17.2661 17.6351 16.9483 17.9539C15.9803 18.92 14.7514 19.5828 13.4122 19.861C12.481 20.0489 11.5214 20.0489 10.5901 19.861C9.69469 19.6774 8.84416 19.3192 8.0871 18.8069C7.34156 18.3041 6.69986 17.6623 6.19706 16.9169C5.41661 15.7603 4.99974 14.397 5.00004 13.0018H3C3.00107 14.7955 3.53689 16.5482 4.53902 18.0359C5.18689 18.992 6.01092 19.8161 6.96708 20.464C8.45273 21.4694 10.2063 22.0052 12.0001 22.002C12.6095 22.0019 13.2172 21.9406 13.8142 21.819C14.965 21.5813 16.0582 21.121 17.0323 20.464C17.5105 20.1416 17.9563 19.7736 18.3632 19.3649C18.7708 18.9563 19.139 18.5102 19.4633 18.0329C20.4679 16.5476 21.0035 14.7949 21.0004 13.0018C21.0002 12.3925 20.939 11.7848 20.8174 11.1877Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Reset = Reset;
