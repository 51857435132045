"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Icons_1 = require("../../DataDisplay/Icons");
var Feedback_1 = require("../../Feedback");
var IconButton_1 = require("../../Input/IconButton");
var InfoModal = function (_a) {
    var _b = _a.type, type = _b === void 0 ? 'info' : _b, open = _a.open, onClose = _a.onClose, title = _a.title, content = _a.content;
    var Icon = Icons[type];
    return (jsx_runtime_1.jsx(Feedback_1.Modal, __assign({ open: open, onClose: function () { return onClose(); } }, { children: jsx_runtime_1.jsxs(material_1.Box, __assign({ id: "modal-inner", sx: {
                width: 280,
                borderRadius: 3,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'grey.100',
                outline: 'none',
            } }, { children: [jsx_runtime_1.jsx(material_1.Stack, __assign({ direction: "row-reverse", sx: { pt: 1, px: 1 } }, { children: jsx_runtime_1.jsx(IconButton_1.Close, { onClick: onClose, size: "small" }, void 0) }), void 0), jsx_runtime_1.jsxs(material_1.Stack, __assign({ justifyContent: "center", alignItems: "center", sx: { px: 5 } }, { children: [jsx_runtime_1.jsx(Icon, { fontSize: "large", sx: { color: 'text.primary' } }, void 0), typeof title === 'string' ? (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h6", sx: { pt: 1 } }, { children: title }), void 0)) : (title)] }), void 0), jsx_runtime_1.jsx(material_1.Stack, __assign({ sx: { pt: 4, px: 5, pb: 7 } }, { children: content }), void 0)] }), void 0) }), void 0));
};
exports.InfoModal = InfoModal;
var Icons = {
    info: Icons_1.Info,
    help: Icons_1.Help,
};
