"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nft = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Nft = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "17.5", cy: "17.5", r: "17.5", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.905 13.157c1.015 2.845 2.19 6.2 2.604 7.34V13.13H24.5v1.795H22.553V21.875H20.93v-3.329l-.017-3.621h-3.83V16.24h2.605v1.796h-2.601l-.017 2.305v1.533h-1.333l-1.126-.003-1.172-3.206c-.855-2.185-1.336-3.324-1.409-3.503 0 0-.012.154 0 3.503v3.209H10.5v-8.75l1.39.032h1.015z", fill: "#F1F5F6" }, void 0), jsx_runtime_1.jsx("path", { d: "M17.5 28c-.235 0-.47-.077-.704-.154l-8.217-4.61a1.813 1.813 0 0 1-.548-.539c-.156-.23-.156-.461-.156-.691v-9.07c0-.23.078-.46.156-.69.079-.231.314-.385.548-.539l8.217-4.534c.391-.23.939-.23 1.408 0l8.217 4.534c.235.154.391.308.547.538.157.23.157.461.157.692v9.069c0 .23-.078.46-.157.691-.156.23-.313.384-.547.538l-8.217 4.534c-.235.154-.47.231-.704.231zm0-19.828s-.078.077 0 0l-8.295 4.611s-.078 0-.078.077V22.082s0 .077.078.077l8.217 4.535h.156l8.217-4.535s.078 0 .078-.077V12.86s0-.077-.078-.077L17.5 8.172s.078.077 0 0z", fill: "#fff" }, void 0)] }), void 0) }), void 0));
};
exports.Nft = Nft;
