import { getApps } from 'firebase/app'
import isEmpty from 'lodash/isEmpty'

import { config } from '@/config'

import * as FirebaseAnalytics from '../../../firebase/analytics'
import { AnalyticsPlugin } from '../plugin.interface'

export default function FirebaseAnalyticsPlugin(): AnalyticsPlugin {
  return {
    name: 'firebase-analytics-plugin',
    config: {},
    initialize: async () => {
      await FirebaseAnalytics.init({
        appId: config.firebase.appId,
        projectId: config.firebase.projectId,
        apiKey: config.firebase.apiKey,
        senderId: config.firebase.senderId,
        measurementId: config.firebase.measurementId,
      })

      return
    },
    /**
     * ページ遷移毎に呼び出される計測イベント
     * utnq_page_view というカスタムイベントが呼び出される
     */
    page: ({
      payload: {
        properties: { title, path },
      },
    }) => {
      // ドキュメントのIDをパスから取得
      const ids = [
        'artists',
        'gachas',
        'items',
        'serials',
        'nftItems',
        'gachaTickets',
        'userProfile',
        'users',
        'membership',
        'notifications',
        'officialInformation',
        'collections',
        'missions',
        'posts',
        'threads',
        'comments',
        'myTrade',
        'trade',
        'live',
        'events',
        'preSales',
        'lotteryApplications',
        'cartTransactionOrders',
      ].reduce((acc, key) => {
        const id =
          // スタンドアロンの場合、configからアーティストIDを取得する
          key === 'artists' && config.applicationType === 'standalone'
            ? config.artistId
            : getIdsFromPath(path, key)
        if (id !== null) {
          // @ts-ignore
          acc[(key + 'Id').replace(/sId$/, 'Id')] = id
        }
        return acc
      }, {} as { [key: string]: string })

      // ネイティブ対応として、ホスト名 localhost を空白文字に置換する
      const replacedPath = path.replace('//localhost', '')

      FirebaseAnalytics.logEvent({
        name: 'utnq_page_view',
        params: {
          applicationKey: config.applicationKey,
          ...ids,
          pageTitle: title,
          pagePath: Object.entries(ids).reduce((acc, [key, value]) => {
            return acc.replace(`${value}`, `:${key.replace(/sId$/, 'Id')}`)
          }, replacedPath),
          testParam: 'test',
          testArtistId:
            (config.applicationType === 'standalone'
              ? config.artistId
              : getIdsFromPath(path, 'artists')) || 'not artist page',
        },
      })
    },
    track: ({ payload: { event, properties } }) => {
      FirebaseAnalytics.logEvent({
        name: event,
        params: properties,
      })
    },
    identify: ({ payload }) => {
      FirebaseAnalytics.setUserId(payload?.userId ?? payload?.anonymousId)

      if (!isEmpty(payload.traits)) {
        Object.entries(payload.traits).map(([name, value]) => {
          FirebaseAnalytics.setUserProperty({ name, value } as {
            name: string
            value: string
          })
        })
      }
    },
    loaded: () => {
      return !!getApps().length
    },
  }
}

/**
 * ルーティングに関するIDを取得する関数
 * /word/:id というパスからidの値を取得する
 * @param path パス文字列
 * @param word idを取得したいパスの1つ前の階層名
 */
function getIdsFromPath(path: string, word: string) {
  const regex = new RegExp(`${word}/([^/]+)`, 'i')
  const match = regex.exec(path)

  return match ? match[1] : null
}
