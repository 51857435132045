import { ThemeOptions } from '@mui/material'

export const defaultTheme: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: 'Hiragino Kaku Gothic Pro',
    h1: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '36px',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '32px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
    },
    h4: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '24px',
    },
    h5: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '24px',
    },
    h6: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '15px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '13px',
      lineHeight: '20px',
    },
    subtitle2: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '11px',
      lineHeight: '16px',
    },
    body1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '28px',
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '13px',
      lineHeight: '20px',
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '20px',
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '11px',
      lineHeight: '16px',
    },
    overline: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  spacing: [0, 4, 8, 12, 16, 20, 24, 32, 40, 48],
  shadows: [
    'none',
    '0px 4px 12px 0px rgba(32,52,63,0.04)',
    '0px 4px 12px -2px rgba(0,0,0,0.08)',
    '0px 8px 16px 0px rgba(32,52,63,0.08)',
    '0px 8px 16px -2px rgba(32,52,63,0.16)',
    '0px 12px 24px -2px rgba(32,52,63,0.16)',
    '0px 12px 24px 0px rgba(32,52,63,0.2)',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
}
