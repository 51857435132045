"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Home = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Home = function (props) {
    var palette = material_1.useTheme().palette;
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M21.1 12.4C20.9 12.4 20.7 12.3 20.5 12.2L12 5.3L3.59999 12.1C3.19999 12.4 2.49999 12.4 2.19999 12C1.89999 11.6 1.89999 10.9 2.29999 10.6L11.3 3.3C11.7 3 12.2 3 12.6 3.3L21.7 10.7C22.1 11 22.2 11.7 21.8 12.1C21.7 12.3 21.4 12.4 21.1 12.4Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M19 21H14.6C13.7 21 13 20.3 13 19.4V15.8H11V19.4C11 20.3 10.3 21 9.4 21H5.6C4.7 21 4 20.3 4 19.4V9.8C4 9.2 4.4 8.8 5 8.8C5.6 8.8 6 9.2 6 9.8V19H9V15.4C9 14.5 9.7 13.8 10.6 13.8H13.4C14.3 13.8 15 14.5 15 15.4V19H19C19.6 19 20 19.4 20 20C20 20.6 19.6 21 19 21Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M19 21C18.4 21 18 20.6 18 20V10C18 9.4 18.4 9 19 9C19.6 9 20 9.4 20 10V20C20 20.6 19.6 21 19 21Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Home = Home;
