"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrowForward = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var ArrowForward = function (props) {
    var id = react_1.useMemo(function () { return Math.round(Math.random() * 10000000); }, []);
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("g", __assign({ clipPath: "url(#" + id + ")" }, { children: jsx_runtime_1.jsx("path", { d: "m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z", fill: "currentColor" }, void 0) }), void 0), jsx_runtime_1.jsx("defs", { children: jsx_runtime_1.jsx("clipPath", __assign({ id: id.toString() }, { children: jsx_runtime_1.jsx("path", { fill: "#fff", d: "M0 0h24v24H0z" }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
exports.ArrowForward = ArrowForward;
