"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RightArrow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var RightArrow = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.784623 24.0807C0.189622 23.4113 0.249915 22.3864 0.919291 21.7914L11.2314 12.625L0.91929 3.45865C0.249914 2.86365 0.189621 1.83867 0.784622 1.16929C1.37962 0.499914 2.4046 0.43962 3.07398 1.03462L16.1132 12.625L3.07398 24.2154C2.40461 24.8104 1.37962 24.7501 0.784623 24.0807Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.RightArrow = RightArrow;
