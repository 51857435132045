"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Question = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Question = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M13.6678 17.4815C13.6049 16.9185 13.5734 16.4444 13.5734 15.9704C13.5734 14.4 14.0455 13.6889 16.028 12.4444C18.6084 10.7852 19.1748 10.3407 19.8986 9.42222C20.6538 8.44445 21 7.34815 21 5.92593C21 2.42963 17.507 0 12.4406 0C9.48252 0 7.09091 0.711111 5.48601 2.07407C4.13287 3.22963 3.5035 4.35556 3 6.51852L7.65734 7.05185C7.78322 5.92593 7.97203 5.42222 8.41259 4.82963C9.16783 3.76296 10.521 3.14074 12.1573 3.14074C14.8636 3.14074 16.5315 4.26667 16.5315 6.07407C16.5315 7.40741 15.7762 8.35556 13.7622 9.62963C11.7168 10.8741 11.465 11.0815 10.8042 11.8519C9.92308 12.8889 9.51399 14.1037 9.51399 15.6741L9.54545 16.237C9.54545 16.6222 9.51399 16.9778 9.45105 17.4815H13.6678ZM9.26224 19.9407V24H13.8252V19.9407H9.26224Z", fill: "#C1D0D7" }, void 0) }), void 0) }), void 0));
};
exports.Question = Question;
