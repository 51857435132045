"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tip = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Tip = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 35 35" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "35", height: "35", viewBox: "0 0 35 35", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M26.85 9.333h-.014a4.821 4.821 0 0 0 .289-1.604 4.812 4.812 0 0 0-4.813-4.812h-.037c-1.669.012-3.113.876-3.973 2.165l-.07.092A4.792 4.792 0 0 0 17.5 7.73a4.813 4.813 0 0 0-9.625 0c0 .562.112 1.107.288 1.604h-.015c.263.742.702 1.401 1.267 1.925H6.39l.01.021H4.133c-.356 0-.71.07-1.038.206a2.676 2.676 0 0 0-.875.584c-.25.25-.445.547-.575.872-.13.326-.194.673-.185 1.023v5.243h2.673v10.047c.023.68.314 1.326.813 1.8a2.702 2.702 0 0 0 1.86.738h21.39c.708 0 1.388-.277 1.89-.768a2.596 2.596 0 0 0 .783-1.854V19.123h2.673v-5.16c0-.694-.281-1.361-.783-1.853a2.7 2.7 0 0 0-1.89-.768H28.6l.041-.084h-3.058a4.819 4.819 0 0 0 1.267-1.925zm-3.635 4.612 1.327 1.805h-3.978L17.5 11.58l-3.065 4.17h-3.978l1.327-1.805H6.833v.019h-2.7v2.538h2.7v2.622h-.027v10.13h.027v.011h21.335v-.011h.026v-10.13h-.026V16.67h2.7v-2.706h-2.7v-.019h-4.953zm-2.65 1.805 2.357 3.208 2.598-1.877-.978-1.331h-3.978zm-8.488 3.208 2.358-3.208h-3.978L9.48 17.08l2.599 1.877zM22.313 6.125c.882 0 1.604.722 1.604 1.604 0 .882-.722 1.604-1.604 1.604a1.609 1.609 0 0 1-1.604-1.604c0-.882.722-1.604 1.604-1.604zm-9.625 0c.882 0 1.604.722 1.604 1.604 0 .882-.722 1.604-1.604 1.604a1.609 1.609 0 0 1-1.604-1.604c0-.882.722-1.604 1.604-1.604zm3.353 20.67c-.07-.45-.095-.899-.095-1.537v-1.973h2.257c1.134 0 1.938-.272 2.516-.85.52-.544.792-1.276.792-2.174 0-.851-.248-1.56-.732-2.033-.567-.567-1.37-.838-2.517-.838h-2.47c-.625 0-.992-.012-1.5-.036.071.532.095.957.095 1.524v6.392c0 .638-.024 1.051-.095 1.524h1.749zm-.095-4.869v-3.178h2.257c.626 0 1.016.13 1.288.425.26.272.39.662.39 1.135 0 1.04-.591 1.618-1.678 1.618h-2.257z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Tip = Tip;
