/**
 * Application initialize file.
 *
 * This file should import application root file.
 *
 * Execute all of module initialization.
 */
import { config } from '@/config'
import { getPlatformResult } from '@/core/native/app'
import { initWeb3auth } from '@/core/web3/web3auth'

import { init as initAtt } from '../core/appTrackingTransparency'
import { i18n, init as initI18n } from '../core/i18n/init'
import { init as initPayment } from '../core/payment'
import { init as initDayjs } from '../lib/dayjs'
import { init as initFirebase } from '../lib/firebase'

initI18n()
initDayjs(i18n.language)
initFirebase({
  appId: config.firebase.appId,
  projectId: config.firebase.projectId,
  apiKey: config.firebase.apiKey,
  senderId: config.firebase.senderId,
  measurementId: config.firebase.measurementId,
})
initPayment(
  config.stripeApiKey,
  getPlatformResult({
    ios: config.revenueCatApiKey.ios,
    android: config.revenueCatApiKey.android,
    web: '',
  })
)
initAtt()
initWeb3auth()
