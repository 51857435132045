import { getApp, getApps, initializeApp } from 'firebase/app'
import {
  getAuth as fbGetAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'

import { getPlatform } from '@/core/native/app'

export type InitProps = {
  appId: string
  projectId: string
  apiKey: string
  senderId: string
  measurementId: string
}

export function init({
  appId,
  projectId,
  apiKey,
  senderId,
  measurementId,
}: InitProps) {
  if (getApps().length) {
    return
  }

  const app = initializeApp({
    appId,
    projectId,
    apiKey,
    authDomain: `${projectId}.firebaseapp.com`,
    databaseURL: `https://${projectId}.firebaseio.com`,
    storageBucket: `${projectId}.appspot.com`,
    messagingSenderId: senderId,
    measurementId,
  })

  if (getPlatform() !== 'web') {
    initializeAuth(app, { persistence: indexedDBLocalPersistence })
  }
}

/**
 * Returns the Auth instance associated with the provided @firebase/app#FirebaseApp.
 * If no instance exists throw error
 *
 */
export function getAuth() {
  const app = getApp()
  if (!app) {
    throw new Error('FirebaseApp is not initialized')
  }
  return fbGetAuth(app)
}
