"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cart = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Cart = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M7.736 18.578c.667 0 1.25-.583 1.25-1.25 0-.666-.583-1.25-1.25-1.25-.666 0-1.25.5-1.25 1.167 0 .666.584 1.333 1.25 1.333zM15.818 18.578c.667 0 1.25-.583 1.25-1.25 0-.666-.583-1.25-1.25-1.25-.666 0-1.25.584-1.25 1.25 0 .667.584 1.25 1.25 1.25zM15.569 14.912h-7.5c-.417 0-.833-.167-1.167-.417-.333-.25-.583-.666-.75-1.083L4.07 6.245l-.75-2.583H2.152a.747.747 0 0 1-.75-.75c0-.417.334-.75.75-.75h1.334c.333 0 .583.083.833.25.167.167.333.5.417.75l.583 2.167h13.417c.25 0 .416.083.583.333.167.25.167.417.083.667l-2.083 7.25c-.083.416-.333.75-.75 1.083-.167.083-.583.25-1 .25zm-9.75-8.167 1.75 6.25c0 .084.083.167.167.25.083.084.166.084.333.084h7.5c.083 0 .25 0 .333-.084.084-.083.167-.166.167-.25l1.75-6.25h-12z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Cart = Cart;
