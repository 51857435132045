"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../components");
/**
 * モーダル表示用コンポーネント
 *
 * インナー要素は画面中央、大きさは子要素の大きさ＋デフォルトpadding(32px 四方)で描画
 * @param modalProps モーダル操作用props onCloseは右上のクローズボタンのイベントと共用
 * @param children モーダルポップアップ要素
 */
var Modal = function (props) {
    var children = props.children, argProps = __rest(props, ["children"]);
    return (jsx_runtime_1.jsx(material_1.Modal, __assign({}, argProps, { children: jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.default',
                outline: 'none',
                borderRadius: 1,
                padding: { xs: 6, md: 7 },
                boxSizing: 'border-box',
            } }, { children: [jsx_runtime_1.jsx(components_1.IconButton.Close, { onClick: argProps.onClose, sx: {
                        position: 'absolute',
                        top: 8,
                        right: 8,
                    } }, void 0), children] }), void 0) }), void 0));
};
exports.Modal = Modal;
