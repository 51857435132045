"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var TextField = function (props) {
    var sx = props.sx, args = __rest(props, ["sx"]);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    return (jsx_runtime_1.jsx(material_1.TextField, __assign({ sx: __assign({ bgcolor: 'grey.50', color: 'grey.300', minHeight: 'auto', borderRadius: 1, mt: 2, mb: 5 }, sx) }, __assign(__assign({}, args), { 
        // ホワイトアウトするバグ対策のため、Safariの場合はtype="time"をtype="text"に変更する
        // https://app.clickup.com/t/908127/UT-12317
        type: args.type === 'time' && isSafari ? 'text' : args.type })), void 0));
};
exports.TextField = TextField;
