"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScreenRotate = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var ScreenRotate = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "26", height: "24", viewBox: "0 0 26 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.5999 22.3001C9.9865 22.3001 10.2999 21.9866 10.2999 21.6001C10.2999 21.2135 9.9865 20.9001 9.5999 20.9001H7.5999C5.77736 20.9001 4.2999 19.4226 4.2999 17.6V16.3016L5.10868 17.0986C5.38405 17.37 5.82726 17.3667 6.0986 17.0913C6.36995 16.816 6.36669 16.3727 6.09132 16.1014L4.06167 14.1014C3.92942 13.9711 3.75082 13.8986 3.56516 13.9C3.3795 13.9014 3.20199 13.9765 3.07169 14.1087L1.10134 16.1087C0.830025 16.3841 0.833336 16.8273 1.10874 17.0987C1.38414 17.37 1.82734 17.3667 2.09866 17.0913L2.8999 16.278L2.8999 17.6C2.8999 20.1958 5.00416 22.3 7.5999 22.3L9.5999 22.3001Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.4001 1.90002C16.0135 1.90002 15.7001 2.21342 15.7001 2.60002C15.7001 2.98662 16.0135 3.30002 16.4001 3.30002L18.4001 3.30002C20.2226 3.30002 21.7001 4.77748 21.7001 6.60002L21.7001 7.89843L20.8913 7.10147C20.6159 6.83012 20.1727 6.83338 19.9014 7.10875C19.63 7.38412 19.6333 7.82733 19.9087 8.09867L21.9383 10.0987C22.0706 10.229 22.2492 10.3014 22.4348 10.3001C22.6205 10.2987 22.798 10.2236 22.9283 10.0913L24.8987 8.09134C25.17 7.81594 25.1667 7.37273 24.8913 7.10141C24.6159 6.8301 24.1727 6.83341 23.9013 7.10881L23.1001 7.92211L23.1001 6.60002C23.1001 4.00429 20.9958 1.90002 18.4001 1.90002L16.4001 1.90002Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.4 9.4V13.6H16.6V9.4H9.4ZM9 8C8.44772 8 8 8.44772 8 9V14C8 14.5523 8.44772 15 9 15H17C17.5523 15 18 14.5523 18 14V9C18 8.44772 17.5523 8 17 8H9Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.ScreenRotate = ScreenRotate;
