"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Time = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Time = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M11.9939 1.99952C6.47391 1.99952 2.00391 6.4795 2.00391 11.9995C2.00391 17.5195 6.47391 21.9995 11.9939 21.9995C17.5239 21.9995 22.0039 17.5195 22.0039 11.9995C22.0039 6.4795 17.5239 1.99952 11.9939 1.99952ZM12.0039 19.9995C7.58391 19.9995 4.00391 16.4194 4.00391 11.9995C4.00391 7.57951 7.58391 3.99951 12.0039 3.99951C16.4239 3.99951 20.0038 7.57951 20.0038 11.9995C20.0038 16.4194 16.4239 19.9995 12.0039 19.9995Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M12.5039 6.99975H11.0039V12.9998L16.2539 16.1498L17.0039 14.9197L12.5039 12.2498V6.99975Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Time = Time;
