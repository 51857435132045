"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
/**
 * アイテム/コレクションのステータスを示すタグ
 *
 * @param label 項目の表示文言
 * @param color タグの色
 */
var Tag = function (_a) {
    var label = _a.label, color = _a.color;
    return (jsx_runtime_1.jsx(material_1.Chip, { label: jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle2" }, { children: label }), void 0), variant: "filled", 
        /**
         * @description [ClickUp] https://app.clickup.com/t/79thfj
         * material-uiのcolor拡張PRがマージされ次第修正
         * https://github.com/mui-org/material-ui/issues/13875#issuecomment-761156846
         @ts-ignore */
        color: color }, void 0));
};
exports.Tag = Tag;
