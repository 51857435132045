"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkeletonText = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var SkeletonText = function (_a) {
    var _b = _a.width, width = _b === void 0 ? '100%' : _b, variant = _a.variant, sx = _a.sx, numberOfLine = _a.numberOfLine, _c = _a.lastWidth, lastWidth = _c === void 0 ? '100%' : _c;
    if (!numberOfLine) {
        return (jsx_runtime_1.jsx(material_1.Skeleton, { variant: variant, sx: __assign({ width: width, borderRadius: '2px', bgcolor: 'grey.100' }, sx) }, void 0));
    }
    return (jsx_runtime_1.jsx(jsx_runtime_1.Fragment, { children: Array.from(Array(numberOfLine).keys()).map(function (key) { return (jsx_runtime_1.jsx(material_1.Skeleton, { variant: variant, sx: __assign({ width: numberOfLine <= key + 1 ? lastWidth : width, borderRadius: '2px', bgcolor: 'grey.100' }, sx) }, key)); }) }, void 0));
};
exports.SkeletonText = SkeletonText;
