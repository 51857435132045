"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Window = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Window = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M10.2 14.8C9.90001 14.8 9.70001 14.7 9.50001 14.5C9.10001 14.1 9.10001 13.5 9.50001 13.1L19.8 2.8C20.2 2.4 20.8 2.4 21.2 2.8C21.6 3.2 21.6 3.8 21.2 4.2L10.9 14.5C10.7 14.7 10.5 14.8 10.2 14.8Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M21 11.2C20.4 11.2 20 10.8 20 10.2V4H13.8C13.2 4 12.8 3.6 12.8 3C12.8 2.4 13.2 2 13.8 2H21C21.6 2 22 2.4 22 3V10.2C22 10.8 21.6 11.2 21 11.2Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M16.6 21H5.8C4.2 21 3 19.8 3 18.2V7.4C3 5.8 4.2 4.6 5.8 4.6H9.4C10 4.6 10.4 5 10.4 5.6C10.4 6.2 10 6.6 9.4 6.6H5.8C5.3 6.6 5 6.9 5 7.4V18.2C5 18.7 5.3 19 5.8 19H16.6C17.1 19 17.4 18.7 17.4 18.2V14.6C17.4 14 17.8 13.6 18.4 13.6C19 13.6 19.4 14 19.4 14.6V18.2C19.4 19.8 18.2 21 16.6 21Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Window = Window;
