import { Components, Theme } from '@mui/material'

import { RouterLinkBehavior } from '../../lib/LinkBehavior'

export const generateComponentsStyle = ({
  shadows,
}: Theme): Components<Theme> => ({
  MuiPaper: {
    defaultProps: {
      elevation: 2,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.grey[50],
      }),
      outlined: ({ theme }) => ({
        border: theme.palette.grey && `solid 1px ${theme.palette.grey[200]}`,
        backgroundColor: 'transparent',
      }),
      // elevation0 ~ elevation24
      ...Object.fromEntries(
        [...Array(25).keys()].map(k => [
          `elevation${k}`,
          {
            boxShadow: shadows[k],
          },
        ])
      ),
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'none',
      // @ts-ignore
      component: RouterLinkBehavior,
    },
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: RouterLinkBehavior,
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'contained', color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.white,
        }),
      },
    ],
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 34,
        textTransform: 'none',
        '&.Mui-disabled': {
          background: theme.palette.grey[100],
        },
      }),
      outlined: ({ theme }) => ({
        color: theme.palette.text.primary,
        borderColor: theme.palette.grey[300],
      }),
      sizeMedium: {
        height: 'auto',
      },
      sizeLarge: {
        height: 44,
      },
      endIcon: {
        '& > *:first-of-type': {
          fontSize: 12,
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    variants: [
      {
        props: { variant: 'contained', color: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.white,
        }),
      },
    ],
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 34,
        textTransform: 'none',
        '&.Mui-disabled': {
          background: theme.palette.grey[100],
        },
      }),
      outlined: ({ theme }) => ({
        color: theme.palette.text.primary,
        borderColor: theme.palette.grey[300],
      }),
      sizeMedium: {
        height: 'auto',
      },
      sizeLarge: {
        height: 44,
      },
      endIcon: {
        '& > *:first-of-type': {
          fontSize: 12,
        },
      },
    },
  },
  MuiList: {
    defaultProps: {
      disablePadding: true,
    },
  },
  MuiDivider: {
    defaultProps: {
      light: true,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[200],
      }),
      light: ({ theme }) => ({ borderColor: theme.palette.grey[100] }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 16,
        color: 'inherit',
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        width: 40,
        height: 40,
        fontSize: 20,
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      color: 'secondary',
      InputProps: {
        inputProps: {
          variant: 'body2',
          sx: {
            px: 4,
            py: 3,
          },
        },
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        top: -4,
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      color: 'secondary',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: 200,
        minHeight: 56,
        backgroundColor: 'transparent!important',
        input: {
          backgroundColor: theme.palette.background.default,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
      }),
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: 0,
        paddingTop: 4,
        paddingRight: 0,
        paddingLeft: 0,
        backgroundColor: 'transparent',
      }),
    },
  },
  MuiListItemButton: {
    defaultProps: {
      /**
       * mui bug issue
       * @see https://github.com/mui-org/material-ui/issues/29030
       */
      // @ts-ignore
      component: RouterLinkBehavior,
    },
  },
  MuiIconButton: {
    defaultProps: {
      color: 'inherit',
    },
    variants: [
      {
        props: { size: 'xSmall' },
        style: {
          svg: {
            fontSize: 18,
          },
          width: 28,
          height: 28,
        },
      },
      {
        props: { size: 'xLarge' },
        style: {
          svg: {
            fontSize: 35,
          },
          width: 64,
          height: 64,
        },
      },
    ],
    styleOverrides: {
      sizeLarge: {
        svg: {
          fontSize: 28,
        },
        width: 52,
        height: 52,
      },
      sizeMedium: {
        svg: {
          fontSize: 24,
        },
        width: 44,
        height: 44,
      },
      sizeSmall: {
        svg: {
          fontSize: 20,
        },
        width: 30,
        height: 30,
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'medium',
      color: 'default',
    },
    styleOverrides: {
      fontSizeSmall: 20,
      fontSizeLarge: 35,
    },
    variants: [
      {
        props: { color: 'default' },
        style: ({ theme }) => ({
          color:
            theme.palette.mode === 'dark'
              ? theme.palette.grey[700]
              : theme.palette.grey[600],
        }),
      },
      {
        props: { fontSize: 'xSmall' },
        style: { fontSize: 12 },
      },
      {
        props: { fontSize: 'xLarge' },
        style: { fontSize: 40 },
      },
    ],
  },
  MuiTypography: {
    defaultProps: {
      color: 'text.primary',
      textTransform: 'none',
    },
    styleOverrides: {
      root: {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        borderRadius: 4,
        minHeight: 48,
        flexGrow: 0,
        padding: theme.spacing(3, 4),
        backgroundColor: theme.palette.grey[700],
      }),
      message: ({ theme }) => ({
        padding: theme.spacing(0, 3, 0, 0),
      }),
    },
  },
  MuiSelect: {
    defaultProps: {
      inputProps: {
        variant: 'body2',
      },
    },
  },
  MuiChip: {
    defaultProps: {
      sx: { typography: 'overline' },
    },
    variants: [
      {
        props: { color: 'default' },
        style: ({ theme }) => ({ backgroundColor: theme.palette.grey[100] }),
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: '4px',
        width: 'fit-content',
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      subheader: {
        textTransform: 'none',
      },
    },
  },
  MuiSlider: {
    defaultProps: {
      sx: {
        '& .MuiSlider-thumb': {
          '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow:
              '0 1px 1px rgba(0,0,0,0.1),0 2px 4px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
          },
        },
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
      rail: ({ theme }) => ({
        color: theme.palette.grey[200],
        opacity: 1,
      }),
      thumb: ({ theme }) => ({
        height: 20,
        width: 20,
        backgroundColor: theme.palette.white,
      }),
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.grey['100'],
      }),
    },
    variants: [
      {
        props: { variant: 'h1' },
        style: { height: 32, marginTop: 2, marginBottom: 2 },
      },
      {
        props: { variant: 'h2' },
        style: { height: 24, marginTop: 4, marginBottom: 4 },
      },
      {
        props: { variant: 'h3' },
        style: { height: 20, marginTop: 4, marginBottom: 4 },
      },
      {
        props: { variant: 'h4' },
        style: { height: 18, marginTop: 5, marginBottom: 5 },
      },
      {
        props: { variant: 'h5' },
        style: { height: 16, marginTop: 4, marginBottom: 4 },
      },
      {
        props: { variant: 'h6' },
        style: { height: 15, marginTop: 5, marginBottom: 4 },
      },
      {
        props: { variant: 'subtitle1' },
        style: { height: 13, marginTop: 4, marginBottom: 3 },
      },
      {
        props: { variant: 'subtitle2' },
        style: { height: 11, marginTop: 3, marginBottom: 2 },
      },
      {
        props: { variant: 'body1' },
        style: { height: 15, marginTop: 7, marginBottom: 6 },
      },
      {
        props: { variant: 'body2' },
        style: { height: 13, marginTop: 4, marginBottom: 3 },
      },
      {
        props: { variant: 'caption' },
        style: { height: 11, marginTop: 3, marginBottom: 2 },
      },
      {
        props: { variant: 'overline' },
        style: { height: 10, marginTop: 1, marginBottom: 1 },
      },
      {
        props: { variant: 'button' },
        style: { height: 14, marginTop: 3, marginBottom: 3 },
      },
      {
        props: { variant: 'large' },
        style: { height: 20, marginTop: 4, marginBottom: 4 },
      },
      {
        props: { variant: 'medium' },
        style: { height: 15, marginTop: 4, marginBottom: 4 },
      },
      {
        props: { variant: 'small' },
        style: { height: 10, marginTop: 4, marginBottom: 4 },
      },
    ],
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        padding: '6px 0 8px',
        minWidth: 64,
        '&.Mui-selected': {
          svg: {
            transform: 'scale(1.05)',
          },
        },
        '.MuiBottomNavigationAction-label, &.Mui-selected .MuiBottomNavigationAction-label':
          {
            fontSize: 10,
            lineHeight: 1.2,
            pt: 0,
          },
      },
    },
  },
  MuiDrawer: {
    defaultProps: { anchor: 'bottom', elevation: 0 },
    variants: [
      {
        props: { anchor: 'bottom' },
        style: ({ theme }) => ({
          '.MuiBackdrop-root, .MuiPaper-root': {
            maxWidth: 600,
            width: '100%',
            margin: '0 auto',
          },
          '.MuiPaper-root.MuiDrawer-paper': {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            backgroundColor: theme.palette.grey['50'],
            paddingBottom: 'env(safe-area-inset-bottom, 0)',
          },
        }),
      },
      {
        props: { anchor: 'left' },
        style: ({ theme }) => ({
          '.MuiBackdrop-root': {
            maxWidth: 600,
            width: '100%',
            margin: '0 auto',
          },
          '.MuiPaper-root.MuiDrawer-paper': {
            backgroundColor: theme.palette.background.default,
            paddingBottom: 'env(safe-area-inset-bottom, 0)',
            backgroundImage: 'unset',
          },
        }),
      },
    ],
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiBackdrop-root, .MuiDialog-container': {
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        },
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        '.MuiBackdrop-root, .MuiDialog-container': {
          maxWidth: 600,
          width: '100%',
          margin: '0 auto',
        },
      },
    },
  },
  /**
   * fix lagging text when open menu
   * https://stackoverflow.com/a/69385787
   */
  MuiMenu: {
    defaultProps: { TransitionProps: { timeout: 0 } },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        backgroundColor: theme.palette.grey[100],
        color: theme.palette.text.primary,
        whiteSpace: 'pre-wrap',
        lineHeight: 1.54,
        fontSize: '13px',
        padding: '12px',
      }),
      arrow: ({ theme }) => ({
        '&::before': {
          backgroundColor: theme.palette.grey[100],
        },
      }),
    },
  },
})
