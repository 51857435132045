import { ThemeOptions } from '@mui/material'

import { defaultTheme } from './defaultTheme'

/**
 * Dark Theme Color Palette
 */
const palette = {
  mode: 'dark',
  black: '#000000',
  white: '#FFFFFF',
  background: {
    default: '#121212',
  },
  like: {
    tap: '#F83D53',
  },
  icon: {
    line: '#c1d0d7',
    background: '#181f22',
  },
  bonus: {
    main: '#fad862',
  },
  grey: {
    50: '#181F22',
    100: '#293840',
    200: '#54656D',
    300: '#798A94',
    400: '#97A7B0',
    500: '#A7B8C0',
    600: '#C1D0D7',
    700: '#D2DEE2',
    800: '#E5ECEF',
    900: '#F1F5F6',
  },
  primary: {
    main: '#459CFF',
    light: '#E2F1FE',
    dark: '#1640A1',
    gradient:
      'linear-gradient(102.8deg, #7EC5A3 0.39%, #498CB8 62.89%, #296AC5 100.31%)',
  },
  secondary: {
    main: '#27CFCF',
    light: '#e0f7f7',
    dark: '#04615b',
  },
  error: {
    main: '#D32F2F',
    light: '#FFCDD2',
  },
  info: {
    main: '#1990F6',
    light: '#e2f1fe',
    dark: '#1970D4',
  },
  warning: {
    main: '#D36500',
    light: '#EFDCB2',
    dark: '#B45501',
  },
  text: {
    primary: '#F1F5F6',
    secondary: '#C1D0D7',
    hint: '#798A94',
    disabled: '#54656D',
  },
} as const

export const defaultDarkTheme: ThemeOptions = {
  ...defaultTheme,
  palette,
}
