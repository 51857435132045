export class PostalCode {
  /**
   * @param postalCodeLike value like postal code
   *
   * @returns formatted postal code or pure value.
   */
  static format(postalCodeLike: string) {
    const match = postalCodeLike.match(/^(\d{3})(\d{4})$/)
    if (match) {
      return `〒${match[1]}-${match[2]}`
    }

    return `〒${postalCodeLike}`
  }
}
