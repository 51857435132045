"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Camera = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Camera = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M20.375 5.625H17.1875L16.4281 3.49688C16.3757 3.35125 16.2795 3.22538 16.1528 3.13645C16.0261 3.04752 15.8751 2.99986 15.7203 3H8.52969C8.21328 3 7.92969 3.19922 7.82422 3.49688L7.0625 5.625H3.875C2.83906 5.625 2 6.46406 2 7.5V18.1875C2 19.2234 2.83906 20.0625 3.875 20.0625H20.375C21.4109 20.0625 22.25 19.2234 22.25 18.1875V7.5C22.25 6.46406 21.4109 5.625 20.375 5.625ZM20.5625 18.1875C20.5625 18.2906 20.4781 18.375 20.375 18.375H3.875C3.77188 18.375 3.6875 18.2906 3.6875 18.1875V7.5C3.6875 7.39688 3.77188 7.3125 3.875 7.3125H8.25078L8.65156 6.19219L9.18828 4.6875H15.0594L15.5961 6.19219L15.9969 7.3125H20.375C20.4781 7.3125 20.5625 7.39688 20.5625 7.5V18.1875ZM12.125 8.8125C10.0531 8.8125 8.375 10.4906 8.375 12.5625C8.375 14.6344 10.0531 16.3125 12.125 16.3125C14.1969 16.3125 15.875 14.6344 15.875 12.5625C15.875 10.4906 14.1969 8.8125 12.125 8.8125ZM12.125 14.8125C10.8828 14.8125 9.875 13.8047 9.875 12.5625C9.875 11.3203 10.8828 10.3125 12.125 10.3125C13.3672 10.3125 14.375 11.3203 14.375 12.5625C14.375 13.8047 13.3672 14.8125 12.125 14.8125Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Camera = Camera;
