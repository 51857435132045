"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Collection = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M18.2222 3H6.66667C5.59467 3 4 3.7191 4 5.7V18.3C4 20.2809 5.59467 21 6.66667 21H20V19.2H6.67733C6.26667 19.1892 5.77778 19.0245 5.77778 18.3C5.77778 18.2091 5.78578 18.1281 5.79911 18.0543C5.89867 17.5368 6.31733 17.409 6.67644 17.4H19.1111C19.1271 17.4 19.1387 17.3919 19.1547 17.391H20V4.8C20 3.8073 19.2027 3 18.2222 3ZM18.2222 15.6H5.77778V5.7C5.77778 4.9746 6.26667 4.8108 6.66667 4.8H12.8889V11.1L14.6667 10.2L16.4444 11.1V4.8H18.2222V15.6Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Collection = Collection;
