"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Complete = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Complete = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 36 36" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "36", height: "36", viewBox: "0 0 36 36", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 1.958C9.14 1.958 1.958 9.141 1.958 18c0 8.86 7.183 16.042 16.042 16.042 8.86 0 16.042-7.183 16.042-16.042 0-8.86-7.183-16.042-16.042-16.042zm6.953 13.33a1.46 1.46 0 0 0-.164-2.081 1.457 1.457 0 0 0-2.076.214l-6.27 7.523-3.245-3.246a1.459 1.459 0 0 0-2.062 2.062l4.375 4.375a1.459 1.459 0 0 0 2.15-.098l7.292-8.75z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Complete = Complete;
