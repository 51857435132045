/**

 ルーティング設定ファイル
 遷移図はこちらに記載 https://lucid.app/lucidchart/9e49d806-fa6c-4f05-ba04-0cd1cb5f6fde/view

 規約
 - 画面名はユニークな名前にする
 - 画面名は動詞、または名詞  動詞の場合はその動作を行う画面
 - ページではなく付属するコンポーネント（モ〜ダル、メニューなど）の場合、pathをnullにする

 */

export const routes = {
  /**
   * Auth
   */
  Top: {
    name: 'トップ',
    path: '/top',
  },
  Login: {
    name: 'ログイン',
    path: '/login',
  },
  SignUp: {
    name: '新規登録',
    path: '/signUp',
  },
  MailAuth: {
    name: 'メールアドレス認証',
    path: '/mailAuth',
  },
  PasswordReset: {
    name: 'パスワードリセット',
    path: '/resetPassword',
  },
  PasswordResetSend: {
    name: 'パスワード再発行',
    path: '/resetPasswordSend',
  },
  Register: {
    name: 'アカウント登録',
    path: '/register',
  },
  QRcodeGetItem: {
    name: 'QRコードアイテム',
    path: '/items/:itemId/serials/:serialId',
  },
  QRcodeGetGachaTicket: {
    name: 'QRコードガチャチケット',
    path: '/gachaTickets/:gachaTicketId/serials/:serialId',
  },
  Tutorial: {
    name: 'チュートリアル',
    path: '/tutorial',
  },

  /**
   * Global
   */
  GlobalHome: {
    name: 'ホーム',
    path: '/home',
  },
  GlobalArtist: {
    name: 'アーティスト一覧',
    path: '/artist',
  },
  ArtistSearch: {
    name: 'アーティスト検索',
    path: '/artistSearch',
  },
  GlobalEvents: {
    name: 'イベント',
    path: '/campaign-events/:tab(mission|campaign)?',
  },
  ShokufesEvents: {
    name: 'イベント',
    path: '/events',
  },
  ShokufesEventDetail: {
    name: 'イベント',
    path: '/events/:eventId',
  },
  ShokufesEventCollectionDetail: {
    name: 'コレクション詳細(イベント)',
    path: '/events/:eventId/collections/:collectionId',
  },
  MyOwnedTicketsEventSelection: {
    name: '受け取り商品があるイベント一覧',
    path: '/pickup',
  },
  MyOwnedTickets: {
    name: '受け取り商品',
    path: '/pickup/:eventId',
  },
  MyOwnedTicketsHistory: {
    name: '受け取った商品',
    path: '/pickup/:eventId/history',
  },
  MyOwnedTicketDetail: {
    name: '受け取り商品詳細',
    path: '/pickup/:eventId/collections/:collectionId/pickupUser/:pickupUserId',
  },
  Market: {
    name: 'マーケット',
    path: '/market',
  },
  Gachas: {
    name: 'ガチャ',
    path: '/globalGachas',
  },
  // グローバルに表示するコレクションタブ。自分が持っているコレクションのみ表示する。
  GlobalCollections: {
    name: 'マイコレクション',
    path: '/myCollections',
  },

  /**
   * MyProfile
   */
  MyProfile: {
    name: 'プロフィール',
    path: '/myProfile',
  },
  MyTickets: {
    name: 'イベントチケット',
    path: '/myTickets',
  },
  EditCustomProfile: {
    name: 'カスタムプロフィール編集',
    path: '/myProfile/editCustomProfile',
  },
  CustomProfileEditWallPaper: {
    name: '背景を変更',
    path: '/myProfile/customProfileEditWallPaper',
  },
  CustomProfileEditAvatar: {
    name: 'アバターを変更',
    path: '/myProfile/CustomProfileEditAvatar',
  },
  MyProfileEdit: {
    name: 'プロフィール編集',
    path: '/myProfile/edit',
  },
  MyOwnedItems: {
    name: '所有アイテム一覧',
    path: '/myOwnedItems',
  },
  MySellListingItems: {
    name: '出品中のアイテム',
    path: '/mySellListingItems',
  },
  MyTradeRequestingItems: {
    name: '申請中アイテム',
    path: '/myTradeRequestingItems',
  },
  MyRanking: {
    name: 'マイランキング',
    path: '/myRanking',
  },

  /**
   * UserProfile
   */
  UserProfile: {
    name: 'ユーザープロフィール',
    path: '/userProfile/:userId',
  },
  UserItemDetail: {
    name: 'ユーザーアイテム一覧',
    path: '/userProfile/:userId/ownedItems/:itemId',
  },
  UserCollectionDetail: {
    name: 'コレクション',
    path: '/userProfile/:userId/collections/:collectionId',
  },

  /**
   * Notification
   */
  Notifications: {
    name: '通知一覧',
    path: '/notifications',
  },
  NotificationDetail: {
    name: '通知詳細',
    path: '/notifications/:notificationId',
  },
  OfficialInformation: {
    name: '公式からの通知',
    path: '/officialInformation',
  },
  OfficialInformationDetail: {
    name: '通知データのアイテムを反映してください',
    path: '/officialInformation/:notificationId',
  },

  /**
   * Setting
   */
  Setting: {
    name: '設定',
    path: '/setting',
  },
  MailRegister: {
    name: 'メールアドレス登録',
    path: '/mailRegister',
  },
  EmailNotificationSettings: {
    name: 'メール通知',
    path: '/settings/notifications/email',
  },
  PushNotificationSettings: {
    name: 'プッシュ通知',
    path: '/settings/notifications/push',
  },
  AutoFollowSettings: {
    name: '自動フォロー設定',
    path: '/settings/autoFollowSettings',
  },
  PaymentMethodSetting: {
    name: '支払い方法',
    path: '/setting/paymentMethod',
  },
  PaymentDetail: {
    name: '支払い方法',
    path: '/setting/PaymentDetail',
  },
  DeliveryInfoSetting: {
    name: '配送設定',
    path: '/setting/deliveryInfo',
  },
  PersonalInfoSettings: {
    name: '個人情報入力',
    path: '/setting/personalInfo',
  },
  StoreUserInfo: {
    name: 'お客様情報入力',
    path: '/setting/userInfo',
  },
  StoreDeliveryInfo: {
    name: '配送先住所入力',
    path: '/setting/storeDeliveryInfo',
  },
  BankCreate: {
    name: '振込先口座設定',
    path: '/setting/bank',
  },
  AccountSetting: {
    name: 'アカウント設定',
    path: '/setting/account',
  },
  PrivacySettings: {
    name: 'プライバシー設定',
    path: '/setting/privacySettings',
  },
  LanguageSetting: {
    name: '言語設定',
    path: '/setting/languageSetting',
  },

  /**
   * AccountSetting
   */
  PrivateSetting: {
    name: '個人情報設定',
    path: '/setting/private',
  },
  EmailSetting: {
    name: 'メールアドレス設定',
    path: '/setting/email',
  },
  PasswordSetting: {
    name: 'パスワード設定',
    path: '/setting/password',
  },
  Integrations: {
    name: '外部連携',
    path: '/setting/integrations',
  },
  Deactivation: {
    name: '退会',
    path: '/setting/deactivation',
  },
  DeactivationConfirm: {
    name: '退会',
    path: '/setting/deactivationConfirm',
  },
  DeactivationCompletion: {
    name: '',
    path: '/setting/deactivationCompletion',
  },

  /**
   * Management
   */
  Management: {
    name: '管理',
    path: '/manage',
  },
  DeliveryManagement: {
    name: '配送管理',
    path: '/manage/deliveries',
  },
  MembershipManagement: {
    name: 'メンバーシップ管理',
    path: '/manage/membership',
  },
  ItemOrders: {
    name: 'アイテム取得履歴',
    path: '/manage/itemOrders',
  },
  ResaleManagement: {
    name: 'リセール管理',
    path: '/manage/resales/:tab(raffle|purchased)?',
  },
  SMSVerification: {
    name: 'SMS認証',
    path: '/manage/sms-verification',
  },
  LotteryApplications: {
    name: '抽選応募管理',
    path: '/manage/lotteryApplications',
  },
  /**
   * Wallet
   */
  Wallet: {
    name: 'ウォレット',
    path: '/wallet',
  },
  PointOrders: {
    name: 'ポイント履歴',
    path: '/wallet/prepaidPoint/orders',
  },
  PrepaidPointCharge: {
    name: 'ポイント購入',
    path: '/wallet/prepaidPoint/charge',
  },
  GachaTicketPurchase: {
    name: 'ガチャチケット購入',
    path: '/wallet/gachaTicket/purchase',
  },
  TicketOrders: {
    name: 'ガチャチケット履歴',
    path: '/wallet/gachaTicketOrders',
  },

  /**
   * Artist
   */
  SignUpArtist: {
    name: 'アーティスト登録',
    path: '/artist/signUp',
  },
  ProfileInputArtist: {
    name: 'アーティスト情報入力',
    path: '/artist/profileInput',
  },
  MailAuthArtist: {
    name: 'メールアドレス認証',
    path: '/artist/mailAuth',
  },
  ArtistTop: {
    name: 'アーティストトップ',
    path: '/artists/:artistId/home',
  },
  ArtistMembership: {
    name: 'メンバーシップ',
    path: '/artists/:artistId/membership',
  },
  ArtistMembershipPurchaseProceed: {
    name: '支払い方法を選択',
    path: '/artists/:artistId/membership/:productId',
  },
  ArtistMembershipPurchaseConfirmation: {
    name: '支払い確認画面',
    path: '/artists/:artistId/membership/:productId/confirmation',
  },
  ArtistMembershipCompletion: {
    name: 'メンバーシップ入会完了',
    path: '/artists/:artistId/membership/:productId/complete',
  },
  ArtistMembershipPaymentEdit: {
    name: '支払い方法を変更',
    path: '/artists/:artistId/membership/:productId/edit',
  },
  ArtistMembershipKonbiniCompletion: {
    name: 'メンバーシップ入会手続き完了',
    path: '/artists/:artistId/membership/:productId/complete/:pendingPurchaseId',
  },
  ArtistStore: {
    name: 'アーティストストア',
    path: '/artists/:artistId/store',
  },
  ArtistStoreItemDetail: {
    name: 'アーティストストアアイテム詳細',
    path: '/artists/:artistId/store/items/:itemId',
  },
  StoreItemPurchaseProceed: {
    name: '購入手続き(カート)',
    path: '/carts/:cartId/proceed',
  },
  // 物販アイテム購入時の確認ページ
  StoreItemPurchaseConfirmation: {
    name: '購入内容の確認(カート)',
    path: '/carts/:cartId/confirm',
  },
  StorePurchaseOrders: {
    name: 'ストア購入履歴',
    path: '/purchaseOrders',
  },
  StorePurchaseOrderDetail: {
    name: 'ストア購入履歴詳細',
    path: '/purchaseOrders/:purchaseOrderId',
  },
  PaymentStatuses: {
    name: '決済状況一覧',
    path: '/paymentStatus',
  },
  PaymentStatusDetail: {
    name: '決済状況詳細',
    path: '/paymentStatus/:pendingPurchaseId',
  },
  StoreItemPurchaseComplete: {
    name: 'アイテム購入完了(カート)',
    path: '/carts/:cartTransactionOrderId/completed',
  },
  StoreItemCompleteFree: {
    name: 'アイテム購入完了',
    path: '/artists/:artistId/store/items/:itemId/complete',
  },
  StoreRequestProvidableItemSearch: {
    name: 'アイテム検索',
    path: '/artists/:artistId/store/search',
  },
  StoreCollectionsSelect: {
    name: 'コレクションから探す',
    path: '/artists/:artistId/store/collections',
  },
  StoreCollectionDetail: {
    name: 'コレクション詳細',
    path: '/artists/:artistId/store/collections/:collectionId',
  },
  ArtistGachaDetailLatest: {
    name: 'ガチャ',
    path: '/artists/:artistId/gachas/latest',
  },
  ArtistGachas: {
    name: 'ガチャ一覧',
    path: '/artists/:artistId/gachas',
  },
  ArtistGachaDetail: {
    name: 'ガチャ',
    path: '/artists/:artistId/gachas/:gachaId',
  },
  ArtistPosts: {
    name: 'ポスト一覧',
    path: '/artists/:artistId/posts',
  },
  ArtistEvents: {
    name: 'イベント',
    path: '/artists/:artistId/events',
  },
  ArtistMissions: {
    name: 'ミッション一覧',
    path: '/artists/:artistId/missions',
  },
  ArtistMissionDetail: {
    name: 'ミッション詳細',
    path: '/artists/:artistId/missions/:missionId',
  },
  ArtistMissionAccomplished: {
    name: 'ミッション完了',
    path: '/artists/:artistId/missions/:missionId/completed',
  },

  ArtistCollections: {
    name: 'コレクション一覧',
    path: '/artists/:artistId/collections',
  },
  ArtistMarket: {
    name: 'アーティストマーケット',
    path: '/artists/:artistId/market',
  },
  ArtistCampaignDetail: {
    name: 'キャンペーン詳細ランキング',
    path: '/artists/:artistId/campaigns/:campaignId',
  },
  ArtistRanking: {
    name: 'ランキング',
    path: '/artists/:artistId/ranking',
  },
  RecentViewedItems: {
    name: '最近みた商品',
    path: '/artists/:artistId/items/recently',
  },
  NewStoreItems: {
    name: 'ストア新着',
    path: '/artists/:artistId/items/new',
  },
  RecommendCollections: {
    name: '閲覧履歴からのおすすめ',
    path: '/artists/:artistId/items/recommended',
  },

  /**
   * Mission
   */
  MissionDetail: {
    name: 'ミッション詳細',
    path: '/missions/:missionId',
  },
  MissionAccomplished: {
    name: 'ミッション完了',
    path: '/missions/:missionId/completed',
  },
  ItemMissions: {
    name: 'アイテムミッション',
    path: '/artists/:artistId/itemMissions/:itemId',
  },

  /**
   * Cart
   */

  StoreCartList: {
    name: 'カート一覧',
    path: '/carts',
  },
  StoreCartDetail: {
    name: 'カート詳細',
    path: '/carts/:cartId',
  },

  /**
   * Campaign
   */
  CampaignDetail: {
    name: 'キャンペーン詳細ランキング',
    path: '/campaigns/:campaignId',
  },
  /**
   * Item
   */
  ItemDetail: {
    name: 'アイテム詳細',
    path: '/items/:itemId',
  },
  PostDetail: {
    name: 'ポスト詳細',
    path: '/posts/:postId',
  },
  UserItemInformation: {
    name: 'アイテム情報',
    path: '/userProfile/:userId/ownedItem/:itemId/information',
  },
  OwnedItemInformation: {
    name: 'アイテム情報',
    path: '/ownedItems/:itemId/information',
  },
  OwnedItemNftItems: {
    name: 'NFTアイテム情報',
    path: '/ownedItems/:itemId/nftItems',
  },
  OwnedItemNftItemDetail: {
    name: 'NFTアイテム情報',
    path: '/ownedItems/:itemId/nftItems/:serialId',
  },

  /**
   * CollectionDetail
   */
  CollectionDetail: {
    name: 'コレクション',
    path: '/collections/:collectionId',
  },
  CollectionItemDetail: {
    name: 'コレクションアイテム詳細',
    path: '/collections/:collectionId/items/:itemId',
  },

  /**
   * Gacha
   */
  SingleGachaResult: {
    name: '単発ガチャ結果',
    path: '/gachas/:gachaId/completed/:gachaOrderId',
  },
  TenGachaResult: {
    name: '10連ガチャ結果',
    path: '/gachas/:gachaId/completed/:gachaOrderId',
  },
  GachaItemList: {
    name: 'ガチャ排出一覧',
    path: '/artists/:artistId/gachas/:gachaId/items',
  },

  /**
   * OwnedItem
   */
  OwnedItemDetail: {
    name: '所有アイテム詳細',
    path: '/ownedItems/:itemId',
  },
  UserOwnedItems: {
    name: '所有アイテム一覧',
    path: '/userProfile/:userId/ownedItems',
  },
  TicketShareSelect: {
    name: 'チケットの分配',
    path: '/ownedItems/:itemId/serials/:serialId/ticketShareSelect',
  },
  TicketShareCopy: {
    name: 'チケットの分配',
    path: '/ownedItems/:itemId/serials/:serialId/ticketShareCopy',
  },
  TicketShareConfirm: {
    name: 'チケットの受け取り',
    path: '/ticketDistribution/:token',
  },
  /**
   * Community
   */
  ArtistThreads: {
    name: 'スレッド一覧',
    path: '/artists/:artistId/threads',
  },
  ArtistThreadCreate: {
    name: 'スレッド新規作成',
    path: '/artists/:artistId/threads/create',
  },
  ArtistThreadEdit: {
    name: 'スレッド編集',
    path: '/artists/:artistId/threads/:threadId/edit',
  },
  ArtistThreadDetail: {
    name: 'スレッド詳細',
    path: '/threads/:threadId',
  },
  ThreadCommentCreate: {
    name: 'スレッドへのコメント投稿',
    path: '/threads/:threadId/create',
  },
  ReplyDetail: {
    name: 'スレッドコメント詳細',
    path: '/:type(threads|items|missions)/:id/comments/:commentId',
  },

  ItemComment: {
    name: 'コメント',
    path: '/:itemId/comments',
  },

  /**
   * @todo CommentBoxからの遷移に必要。デザインがないので、遷移など確認する
   */
  CommentDetail: {
    name: 'コメント詳細',
    path: '/comments/:commentId',
  },

  /**
   * トレード
   */
  ArtistTrade: {
    name: 'トレード',
    path: '/artists/:artistId/trade',
  },

  MyTrade: {
    name: 'トレードBox',
    path: '/myTrade',
  },

  MyTradeDetail: {
    name: 'マイトレード詳細',
    path: '/myTrade/:tradeNegotiationId',
  },

  UserTradeDetail: {
    name: 'トレード詳細',
    path: '/trade/:tradeNegotiationId',
  },

  TradeRequestUserSelect: {
    name: 'トレード申請するユーザーの選択',
    path: '/items/:itemId/requestTrade/selectCandidate',
  },

  TradeRequestProvidableItemSelect: {
    name: 'トレード申請の提供アイテム選択',
    path: '/items/:itemId/requestTrade/selectProvidableItem',
  },

  TradeRequestConfirm: {
    name: 'トレード申請の確認',
    path: '/items/:itemId/requestTrade/confirm',
  },

  SerialNumberSelect: {
    name: 'シリアルナンバーを選択',
    path: '/users/:userId/items/:itemId/requestTrade',
  },

  /**
   * ライブ配信
   */
  LiveDetail: {
    name: '配信ライブ詳細',
    path: '/live/:liveStreamId',
  },

  /**
   * リセール出品フロー
   */
  ResaleCreate: {
    name: 'リセール出品',
    path: '/resale/create/:itemId',
  },
  ResaleCreatePrecaution: {
    name: '出品の際の注意事項',
    path: '/resale/create/:itemId/precaution',
  },
  ResaleCreateConfirmation: {
    name: 'リセール出品アイテムの確認',
    path: '/resale/create/:itemId/confirmation',
  },
  ResaleCreateCompletion: {
    name: 'リセール出品完了',
    path: '/resale/create/:itemId/completion',
  },
  ResaleDetail: {
    name: 'リセール出品詳細',
    path: '/resale/:resaleItemId',
  },
  /**
   * リセール応募フロー
   */
  ResaleApplicationDetail: {
    name: 'リセール応募情報',
    path: '/resale/application/:itemId',
  },
  ResaleApplicationCreatePrecaution: {
    name: 'リセール応募注意事項',
    path: '/resale/application/:itemId/precaution',
  },
  ResaleApplicationCreate: {
    name: 'リセール応募',
    path: '/resale/application/:itemId/create',
  },
  ResaleApplicationCreateConfirmation: {
    name: 'リセール応募確認',
    path: '/resale/application/:itemId/confirmation',
  },
  ResaleApplicationCreateCompletion: {
    name: 'リセール応募完了',
    path: '/resale/application/:itemId/completion/:raffleId',
  },
  /**
   * 先行販売
   */
  LotteryApplicationDetail: {
    name: '抽選結果詳細',
    path: '/lotteryApplications/:preSaleId',
  },
  LotteryApplicationDetailPaymentConfirmation: {
    name: 'チケット代金のお支払い',
    path: '/lotteryApplications/:preSaleId/payment/confirmation',
  },
  LotteryApplicationDetailCancelCompletion: {
    name: '抽選キャンセル完了',
    path: '/lotteryApplications/:preSaleId/cancelCompletion',
  },
  LotteryDetail: {
    name: '先行販売詳細(抽選応募ページ)',
    path: '/preSales/:preSaleId/lottery',
  },
  LotteryConfirmation: {
    name: '応募情報詳細',
    path: '/preSales/:preSaleId/lottery/confirmation',
  },
  LotteryCompletion: {
    name: '先行販売応募完了',
    path: '/preSales/:preSaleId/lottery/completion',
  },
  FirstComeFirstServedDetail: {
    name: '販売情報詳細(先行販売ページ)',
    path: '/preSales/:preSaleId/firstComeFirstServed',
  },
  FirstComeFirstServedConfirmation: {
    name: '購入情報詳細(先行販売ページ)',
    path: '/preSales/:preSaleId/firstComeFirstServed/confirmation',
  },
  FirstComeFirstServedComplete: {
    name: 'アイテム購入完了(先行販売)',
    path: '/preSales/:preSaleId/:cartTransactionOrderId/completed',
  },
  LotteryUserInfo: {
    name: 'お客様情報入力',
    path: '/preSales/:preSaleId/setting/lotteryUserInfo',
  },
  LotteryDeliveryInfo: {
    name: '配送先住所入力',
    path: '/preSales/:preSaleId/setting/lotteryDeliveryInfo',
  },
  /**
   * リセール詳細
   */
  ResaleRaffleDetail: {
    name: 'リセール応募詳細',
    path: '/resale/:itemId/raffle/:raffleId',
  },
  ResaleRaffleDetailCancelCompletion: {
    name: 'リセール応募キャンセル完了',
    path: '/resale/:itemId/raffle/:raffleId/cancel/completion',
  },
  ResaleRafflePaymentConfirmation: {
    name: 'リセールお支払い',
    path: '/resale/:itemId/raffle/:raffleId/payment/confirmation',
  },
  ResaleRafflePaymentCompletion: {
    name: 'リセールお支払い完了',
    path: '/resale/:itemId/raffle/:raffleId/payment/completion',
  },
  /**
   * Console
   * アーティスト管理画面
   * Artistだと名前がかぶってしまうので、Consoleと命名
   */
  ConsoleDashboard: {
    name: 'ダッシュボード',
    path: '/console/dashboard',
  },
  ConsoleItems: {
    name: 'アイテム一覧',
    path: '/console/items',
  },
  ConsoleItemDetail: {
    name: 'アイテム詳細',
    path: '/console/items/:itemId',
  },
  ConsoleItemInformation: {
    name: 'アイテム情報',
    path: '/console/items/:itemId/information',
  },
  ConsoleItemInformationLimitUser: {
    name: '購入可能者一覧',
    path: '/console/items/:itemId/purchaseUsers',
  },
  ConsoleItemCreate: {
    name: 'アイテム作成',
    path: '/console/items/create/:resourceType',
  },
  ConsoleItemEdit: {
    name: 'アイテム編集',
    path: '/console/items/:itemId/edit',
  },
  ConsoleItemOrders: {
    name: 'アイテム購入履歴',
    path: '/console/items/itemOrders',
  },
  ConsoleCollections: {
    name: 'コレクション一覧',
    path: '/console/collections',
  },
  ConsoleShokufesEvents: {
    name: 'イベント一覧',
    path: '/console/events',
  },
  ConsoleShokufesEventCreate: {
    name: 'イベント作成',
    path: '/console/events/create',
  },
  ConsoleShokufesEventEdit: {
    name: 'イベント編集',
    path: '/console/events/:eventId/edit',
  },
  ConsoleShokufesEventDetail: {
    name: 'イベント詳細',
    path: '/console/events/:eventId',
  },
  ConsoleQuantityManagements: {
    name: '個数管理',
    path: '/events/:eventId/collections/:collectionId/quantityManagement',
  },
  ConsoleTicketReceives: {
    name: '受け取り一覧',
    path: '/console/events/:eventId/collections/:collectionId/ticketReceives/:type(history)?',
  },
  ConsoleTicketReceiveDetail: {
    name: '受け取り詳細',
    path: '/console/events/:eventId/collections/:collectionId/ticketReceives/:pickupUserId',
  },
  ConsoleEventCollectionReceiveTimeEdit: {
    name: 'コレクション受け取り時間編集',
    path: '/console/events/:eventId/collections/:collectionId/receiveTime/edit',
  },
  ConsoleCollectionCreate: {
    name: 'コレクション作成',
    path: '/console/collections/create',
  },
  ConsoleCollectionEdit: {
    name: 'コレクション編集',
    path: '/console/collections/:collectionId/edit',
  },
  ConsoleCollectionDetail: {
    name: 'コレクション詳細',
    path: '/console/collections/:collectionId',
  },
  ConsoleMembership: {
    name: 'メンバーシップ管理',
    path: '/console/membership',
  },
  ConsoleMembershipDetail: {
    name: 'メンバーシッププラン詳細',
    path: '/console/membershipPlan/:planId',
  },
  ConsoleCampaigns: {
    name: 'コンテスト一覧',
    path: '/console/campaigns',
  },
  ConsoleCampaignDetail: {
    name: 'コンテスト一覧',
    path: '/console/campaigns/:campaignId',
  },
  ConsolePostDetail: {
    name: 'ポスト詳細',
    path: '/console/posts/:postId',
  },
  ConsolePostCreate: {
    name: 'ポスト作成',
    path: '/console/posts/create/:resourceType',
  },
  ConsolePost: {
    name: 'ポスト一覧',
    path: '/console/posts',
  },
  ConsoleUnpublishedPosts: {
    name: '未投稿ポスト一覧',
    path: '/console/posts/unpublished',
  },
  ConsolePostEdit: {
    name: 'ポスト編集',
    path: '/console/posts/edit/:postId',
  },
  ConsoleAccountSetting: {
    name: 'アカウント設定',
    path: '/console/setting/account',
  },
  ConsoleMyProfileEdit: {
    name: 'プロフィール設定',
    path: '/console/myProfile/edit',
  },
  ConsoleRevenue: {
    name: '収益管理',
    path: '/console/revenue',
  },
  ConsoleBankEdit: {
    name: '口座情報設定',
    path: '/console/setting/bank',
  },
  ConsoleBusinessEdit: {
    name: 'ビジネス情報設定',
    path: '/console/setting/business',
  },
  ConsoleMissions: {
    name: '管理画面ミッション一覧',
    path: '/console/missions',
  },
  ConsoleMissionCreate: {
    name: '管理画面ミッション作成',
    path: '/console/missions/create/:missionType',
  },
  ConsoleMissionEdit: {
    name: 'ミッション編集',
    path: '/console/missions/:missionId/edit',
  },
  ConsoleCryptocurrencyWallet: {
    name: '仮想通貨ウォレット連携',
    path: '/console/setting/cryptcurrencyWallet',
  },
  ConsoleLiveSetting: {
    name: 'ライブ配信',
    path: '/console/live/setting',
  },
  ConsoleLiveDetail: {
    name: 'ライブ配信',
    path: '/console/live/:liveStreamId',
  },
  ConsoleLiveFinish: {
    name: 'ライブ配信',
    path: '/console/live/:liveStreamId/finish',
  },
  ConsoleMembershipExplain: {
    name: '月額メンバーシップ',
    path: '/console/membershipExplain',
  },
  ConsoleMembershipCreate: {
    name: '月額メンバーシップの開設',
    path: '/console/membershipCreate',
  },
  ConsoleMembershipEdit: {
    name: '月額メンバーシップの編集',
    path: '/console/membershipEdit/:planId',
  },
  ConsoleOrders: {
    name: '注文履歴',
    path: '/console/orders',
  },
  ConsoleUsers: {
    name: '顧客情報',
    path: '/console/users',
  },
  ConsoleUserDetail: {
    name: '顧客情報詳細',
    path: '/console/user/:userId',
  },
  ConsoleUserOwnedItems: {
    name: 'ユーザー所有アイテム',
    path: '/console/user/:userId/ownedItems',
  },
  ConsoleUserOwnedItemDetail: {
    name: 'ユーザー所有アイテム詳細',
    path: '/console/user/:userId/ownedItems/:itemId',
  },
  ConsoleUserOrders: {
    name: '顧客情報',
    path: '/console/users/:userId/orders',
  },
  ConsoleOrderDetail: {
    name: '注文履歴',
    path: '/console/orders/:orderId/users/:userId',
  },
  ConsoleData: {
    name: 'データ分析',
    path: '/console/data',
  },
  ConsoleDeliveryManagement: {
    name: '配送管理',
    path: '/console/deliveries',
  },
  ConsoleCampaignBannerSetting: {
    name: 'バナー設定',
    path: '/console/banner/setting',
  },
  ConsolePreSaleCreate: {
    name: '先行販売作成',
    path: '/console/events/:eventId/preSale/create/:preSaleType',
  },
  ConsolePreSaleDetail: {
    name: '先行販売詳細',
    path: '/console/preSale/:preSaleId',
  },
  ConsolePreSaleEdit: {
    name: '先行販売編集',
    path: '/console/events/:eventId/preSale/:preSaleId/edit',
  },
  ConsoleLotteryManagementDetail: {
    name: '抽選応募者管理',
    path: '/console/preSales/:preSaleId/lotteryManagements/users/:userId',
  },
  ConsoleLotteryCsvUpload: {
    name: '当選者の一括登録',
    path: '/console/preSales/:preSaleId/lotteryManagements/csv',
  },
  ConsoleLotteryManagements: {
    name: '当選者管理',
    path: '/console/preSales/:preSaleId/lotteryManagements',
  },
} as const

export const standaloneRoutes = {
  ...routes,
  ArtistTop: {
    name: 'ホーム',
    path: '/home',
  },
  ArtistMembership: {
    name: routes.ArtistMembership.name,
    path: '/membership',
  },
  ArtistMembershipPurchaseProceed: {
    name: routes.ArtistMembershipPurchaseProceed.name,
    path: '/membership/:productId',
  },
  ArtistMembershipPurchaseConfirmation: {
    name: routes.ArtistMembershipPurchaseConfirmation.name,
    path: '/membership/:productId/confirmation',
  },
  ArtistMembershipCompletion: {
    name: routes.ArtistMembershipCompletion.name,
    path: '/membership/:productId/complete',
  },
  ArtistMembershipPaymentEdit: {
    name: '支払い方法を変更',
    path: '/membership/:productId/edit',
  },
  ArtistMembershipKonbiniCompletion: {
    name: routes.ArtistMembershipKonbiniCompletion.name,
    path: '/membership/:productId/complete/:pendingPurchaseId',
  },
  ArtistStore: {
    name: routes.ArtistStore.name,
    path: '/store',
  },
  ArtistStoreItemDetail: {
    name: routes.ArtistStoreItemDetail.name,
    path: '/store/items/:itemId',
  },
  StoreItemCompleteFree: {
    name: routes.StoreItemCompleteFree.name,
    path: '/store/items/:itemId/complete',
  },
  StoreRequestProvidableItemSearch: {
    name: routes.StoreRequestProvidableItemSearch.name,
    path: '/store/search',
  },
  StoreCollectionsSelect: {
    name: routes.StoreCollectionsSelect.name,
    path: '/store/collections',
  },
  StoreCollectionDetail: {
    name: routes.StoreCollectionDetail.name,
    path: '/store/collections/:collectionId',
  },
  ArtistGachaDetailLatest: {
    name: routes.ArtistGachaDetailLatest.name,
    path: '/gachas/latest',
  },
  ArtistGachas: {
    name: routes.ArtistGachas.name,
    path: '/gachas',
  },
  ArtistGachaDetail: {
    name: routes.ArtistGachaDetail.name,
    path: '/gachas/:gachaId',
  },
  GachaItemList: {
    name: routes.GachaItemList.name,
    path: '/gachas/:gachaId/items',
  },
  ArtistPosts: {
    name: routes.ArtistPosts.name,
    path: '/posts',
  },
  ArtistEvents: {
    name: routes.ArtistEvents.name,
    path: '/artist-events',
  },
  ArtistMissions: {
    name: routes.ArtistMissions.name,
    path: '/missions',
  },
  ArtistMissionDetail: {
    name: routes.ArtistMissionDetail.name,
    path: '/missions/:missionId',
  },
  ArtistMissionAccomplished: {
    name: routes.ArtistMissionAccomplished.name,
    path: '/missions/:missionId/completed',
  },
  ArtistCollections: {
    name: routes.ArtistCollections.name,
    path: '/collections',
  },
  ArtistMarket: {
    name: routes.ArtistMarket.name,
    path: '/market',
  },
  ArtistThreads: {
    name: routes.ArtistThreads.name,
    path: '/threads',
  },
  ArtistThreadCreate: {
    name: routes.ArtistThreadCreate.name,
    path: '/threads/create',
  },
  ArtistTrade: {
    name: routes.ArtistTrade.name,
    path: '/trade',
  },
  ItemMissions: {
    name: routes.ItemMissions.name,
    path: '/itemMissions/:itemId',
  },
  ArtistCampaignDetail: {
    name: routes.ArtistCampaignDetail.name,
    path: '/campaigns/:campaignId',
  },
  LiveDetail: {
    name: routes.LiveDetail.name,
    path: '/live/:liveStreamId',
  },
  ArtistRanking: {
    name: routes.ArtistRanking.name,
    path: '/ranking',
  },
  RecentViewedItems: {
    name: routes.RecentViewedItems.name,
    path: '/items/recently',
  },
  RecommendCollections: {
    name: routes.RecommendCollections.name,
    path: '/items/recommended',
  },
}

export function getRoutes(applicationType: string) {
  return applicationType === 'standalone' ? standaloneRoutes : routes
}
