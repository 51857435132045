"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Artist = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Artist = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M19.148 3.971C18.9698 3.67552 18.7185 3.43095 18.4183 3.26087C18.118 3.09079 17.779 3.00095 17.434 3H6.566C5.868 3 5.211 3.372 4.852 3.971L2.143 8.485C2.04926 8.64041 1.99981 8.8185 2 9C2.00399 9.96521 2.35949 10.8959 3 11.618V20C3 21.103 3.897 22 5 22H19C20.103 22 21 21.103 21 20V11.618C21.6405 10.8959 21.996 9.96521 22 9C22.0002 8.8185 21.9507 8.64041 21.857 8.485L19.148 3.971ZM19.984 9.251C19.9223 9.73382 19.6868 10.1776 19.3217 10.4995C18.9566 10.8214 18.4867 10.9993 18 11C16.897 11 16 10.103 16 9C16 8.932 15.975 8.872 15.961 8.808L15.981 8.804L15.22 5H17.434L19.984 9.251ZM10.819 5H13.18L13.993 9.065C13.958 10.137 13.08 11 12 11C10.92 11 10.042 10.137 10.007 9.065L10.819 5ZM6.566 5H8.78L8.02 8.804L8.04 8.808C8.025 8.872 8 8.932 8 9C8 10.103 7.103 11 6 11C5.51325 10.9993 5.04341 10.8214 4.67828 10.4995C4.31315 10.1776 4.07772 9.73382 4.016 9.251L6.566 5ZM10 20V17H14V20H10ZM16 20V17C16 15.897 15.103 15 14 15H10C8.897 15 8 15.897 8 17V20H5V12.858C5.321 12.941 5.652 13 6 13C6.56782 13.0003 7.12915 12.8794 7.6465 12.6454C8.16386 12.4114 8.6253 12.0696 9 11.643C9.733 12.475 10.807 13 12 13C13.193 13 14.267 12.475 15 11.643C15.3747 12.0696 15.8361 12.4114 16.3535 12.6454C16.8708 12.8794 17.4322 13.0003 18 13C18.348 13 18.679 12.941 19 12.858V20H16Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Artist = Artist;
