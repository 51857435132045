"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioPlay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var AudioPlay = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 24 24" }, { children: jsx_runtime_1.jsxs("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" }, { children: [jsx_runtime_1.jsx("path", { fillRule: "evenodd", d: "M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.64,12.49-6.55,3.76a.45.45,0,0,1-.27.06.43.43,0,0,1-.27-.06.55.55,0,0,1-.28-.49V8.24a.55.55,0,0,1,.28-.49.48.48,0,0,1,.54,0l6.55,3.76a.58.58,0,0,1,0,1Z" }, void 0), jsx_runtime_1.jsx("rect", { fill: "currentColor", x: "6.03", y: "12.03", width: "7.58", transform: "translate(-5.33 15.43) rotate(-63.43)" }, void 0)] }), void 0) }), void 0));
};
exports.AudioPlay = AudioPlay;
