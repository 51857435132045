"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataBox = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../../components");
/**
 * データ表示用コンポーネント
 *
 * @param main { title: タイトルテキスト, value: 値 string }
 * @param subs Array<{ title: タイトルテキスト, value: 値 string }>
 */
var DataBox = function (_a) {
    var main = _a.main, _b = _a.subs, subs = _b === void 0 ? [] : _b;
    return (jsx_runtime_1.jsxs(components_1.Layout.Container, __assign({ sx: { bgcolor: 'background.paper', borderRadius: 3 } }, { children: [!!main && (jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { xs: 'flex-start', md: 'center' },
                    justifyContent: 'space-between',
                    mb: { xs: 4, md: 3 },
                } }, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h3", sx: { mb: { xs: 3, md: 0 } } }, { children: main === null || main === void 0 ? void 0 : main.title }), void 0), jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h2" }, { children: main === null || main === void 0 ? void 0 : main.value }), void 0)] }), void 0)), (subs === null || subs === void 0 ? void 0 : subs.length) > 0 &&
                subs.map(function (_a) {
                    var title = _a.title, value = _a.value;
                    return (jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: { xs: 'flex-start', md: 'center' },
                            justifyContent: 'space-between',
                            mb: { xs: 3, md: 0 },
                        } }, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2" }, { children: title }), void 0), jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1" }, { children: value }), void 0)] }), void 0));
                })] }), void 0));
};
exports.DataBox = DataBox;
