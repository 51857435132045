"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThumbnailUploadField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var react_dropzone_1 = require("react-dropzone");
var DataDisplay_1 = require("../../../DataDisplay");
var Input_1 = require("../../../Input");
/**
 *
 * thumbnail upload field component
 */
exports.ThumbnailUploadField = react_1.forwardRef(function (_a, ref) {
    var error = _a.error, readOnly = _a.readOnly, _b = _a.fileUploadInfo, onDrop = _b.onDrop, progress = _b.progress, type = _b.type, t = _a.t, shape = _a.shape, onClose = _a.onClose, thumbnailProps = _a.thumbnailProps;
    var _c = react_dropzone_1.useDropzone({
        maxSize: Input_1.uploadFileMaxSize[type],
        accept: Input_1.acceptMimeTypes[type],
        onDrop: function (acceptedFiles) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!acceptedFiles.length)
                            return [2 /*return*/];
                        return [4 /*yield*/, onDrop(acceptedFiles[0])];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    }), getRootProps = _c.getRootProps, getInputProps = _c.getInputProps;
    var _d = react_1.useMemo(function () {
        if (shape === 'square') {
            return { width: 96, height: 96 };
        }
        return { width: 64, height: 104 };
    }, [shape]), width = _d.width, height = _d.height;
    return (jsx_runtime_1.jsxs(material_1.Stack, __assign({ alignItems: "center", justifyContent: "center", sx: { position: 'relative' } }, { children: [(thumbnailProps === null || thumbnailProps === void 0 ? void 0 : thumbnailProps.src) ? (jsx_runtime_1.jsxs(jsx_runtime_1.Fragment, { children: [onClose && (jsx_runtime_1.jsx(Input_1.IconButton.Close, { onClick: onClose, size: "small", sx: {
                            position: 'absolute',
                            top: 4,
                            right: 4,
                            zIndex: 2,
                            bgcolor: 'grey.50',
                            '&:hover': {
                                bgcolor: 'grey.100',
                            },
                        } }, void 0)), jsx_runtime_1.jsx(material_1.Avatar, __assign({ variant: "square" }, thumbnailProps, { sx: __assign({ width: width, height: height, cursor: 'pointer' }, thumbnailProps.sx) }), void 0)] }, void 0)) : (jsx_runtime_1.jsxs(material_1.Stack, __assign({}, getRootProps(), { alignItems: "center", justifyContent: "center", sx: {
                    width: width,
                    height: height,
                    borderStyle: 'dashed',
                    borderWidth: 1,
                    borderColor: 'grey.200',
                    borderRadius: 1,
                    position: 'relative',
                    cursor: 'pointer',
                    pointerEvents: readOnly ? 'none' : 'unset',
                }, onClick: onDrop }, { children: [!!progress && (jsx_runtime_1.jsx(material_1.LinearProgress, { variant: "determinate", value: progress, sx: {
                            position: 'absolute',
                            top: 0,
                            width: '100%',
                        } }, void 0)), !readOnly && (jsx_runtime_1.jsxs(material_1.Stack, __assign({ alignItems: "center", spacing: 1 }, { children: [jsx_runtime_1.jsx(DataDisplay_1.Icons.Image, { fontSize: "small", sx: { color: 'grey.600' } }, void 0), jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "overline" }, { children: t('addImage') }), void 0)] }), void 0))] }), void 0)), error && jsx_runtime_1.jsx(material_1.FormHelperText, __assign({ error: true }, { children: error.message }), void 0)] }), void 0));
});
