import { getApp } from 'firebase/app'
import {
  fetchAndActivate,
  getRemoteConfig,
  getString,
} from 'firebase/remote-config'

/**
 * @description
 * Remote Configに設定しているiOS/Androidの最新バージョンの値を取得する
 * https://firebase.google.com/docs/remote-config/get-started?platform=web
 * https://app.clickup.com/908127/v/dc/vpuz-82/vpuz-4625
 */
export const getLatestVersion = async ({
  applicationKey,
  platform,
}: {
  applicationKey: string
  platform: string
}) => {
  if (platform === 'web') return

  const app = getApp()
  if (!app) {
    throw new Error('FirebaseApp is not initialized')
  }
  const remoteConfig = getRemoteConfig(app)
  await fetchAndActivate(remoteConfig)

  return getString(remoteConfig, `latest_version_${applicationKey}_${platform}`)
}
