"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PointDisplay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var EmphasizedNumber_1 = require("../EmphasizedNumber");
var Icons_1 = require("../Icons");
var Size;
(function (Size) {
    Size["SMALL"] = "small";
    Size["MEDIUM"] = "medium";
    Size["LARGE"] = "large";
})(Size || (Size = {}));
/**
 *
 * @param point
 * @param size
 * @param sx
 * @param custom
 * @constructor
 */
var PointDisplay = function (_a) {
    var point = _a.point, _b = _a.size, size = _b === void 0 ? Size.MEDIUM : _b, custom = _a.custom, sx = _a.sx, _c = _a.paymentType, paymentType = _c === void 0 ? 'prepaid_point' : _c;
    var _d = react_1.useMemo(function () {
        if (custom) {
            return custom;
        }
        switch (size) {
            case Size.SMALL:
                return { fontSize: 'xSmall', variant: 'subtitle1', spacing: 1 };
            case Size.LARGE:
                return { fontSize: 'large', variant: 'h1', spacing: 2 };
            default:
                return { fontSize: '16px', variant: 'h4', spacing: 2 };
        }
    }, [size, custom]), fontSize = _d.fontSize, variant = _d.variant, spacing = _d.spacing;
    return (jsx_runtime_1.jsxs(material_1.Stack, __assign({ direction: "row", sx: sx, alignItems: "center", spacing: spacing }, { children: [paymentType === 'prepaid_point' ? (jsx_runtime_1.jsx(Icons_1.Point, { fontSize: fontSize }, void 0)) : (jsx_runtime_1.jsx(EmphasizedNumber_1.EmphasizedNumber, __assign({ sx: { lineHeight: 1 }, fontSize: fontSize === 'large'
                    ? '24px'
                    : fontSize === 'xSmall'
                        ? '14px'
                        : '18px' }, { children: "\u00A5" }), void 0)), jsx_runtime_1.jsx(EmphasizedNumber_1.EmphasizedNumber, __assign({ variant: variant, sx: { ml: paymentType === 'prepaid_point' ? 2 : 1 } }, { children: point }), void 0)] }), void 0));
};
exports.PointDisplay = PointDisplay;
