import { PreferencesPlugin } from '@capacitor/preferences'
import { PersistentStorage } from 'apollo3-cache-persist'

export class StorageWrapper implements PersistentStorage<string | null> {
  private storage

  constructor(storage: PreferencesPlugin) {
    this.storage = storage
  }

  getItem(key: string): Promise<string | null> {
    return this.storage.get({ key }).then(({ value }) => value)
  }

  removeItem(key: string): Promise<void> {
    return this.storage.remove({ key })
  }

  setItem(key: string, value: string | null): Promise<void> {
    return this.storage.set({ key, value: value ?? '' })
  }
}
