"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudioRewind = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../../components");
/**
 * 音声プレイヤーの巻き戻しボタン
 *
 * @param props IconButtonのProps
 */
var AudioRewind = function (_a) {
    var _b = _a.size, size = _b === void 0 ? 'medium' : _b, badge = _a.badge, props = __rest(_a, ["size", "badge"]);
    return (jsx_runtime_1.jsx(material_1.IconButton, __assign({ size: size, sx: __assign({ padding: 0 }, props.sx) }, props, { children: jsx_runtime_1.jsx(material_1.Badge, __assign({}, badge, { children: jsx_runtime_1.jsx(components_1.Icons.AudioRewind, {}, void 0) }), void 0) }), void 0));
};
exports.AudioRewind = AudioRewind;
