"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NextArrow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var NextArrow = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("mask", __assign({ id: "mask0_0_4", maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "24", height: "25" }, { children: jsx_runtime_1.jsx("path", { d: "M24 0.34375H0V24.3438H24V0.34375Z", fill: "white" }, void 0) }), void 0), jsx_runtime_1.jsx("g", __assign({ mask: "url(#mask0_0_4)" }, { children: jsx_runtime_1.jsx("path", { d: "M15.297 11.9455L5.62773 2.27622L7.07469 0.829264L18.3735 12.1879L7.07469 23.5466L5.62773 22.0996L15.297 12.4304L15.5394 12.1879L15.297 11.9455Z", fill: "white", stroke: "currentColor", strokeWidth: "0.8" }, void 0) }), void 0)] }), void 0) }), void 0));
};
exports.NextArrow = NextArrow;
