"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.More = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var More = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("circle", { cx: "12", cy: "12", r: "2", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("circle", { cx: "20", cy: "12", r: "2", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("circle", { cx: "4", cy: "12", r: "2", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.More = More;
