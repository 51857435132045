"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var ItemInfo = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M11.5 18.334c-.398 0-.78-.16-1.06-.444a1.523 1.523 0 0 1-.44-1.072V10c0-.833.675-1.515 1.5-1.515h3l3 3.03v5.303c0 .841-.668 1.516-1.5 1.516h-4.5zm0-1.515H16v-4.675L13.877 10H11.5v6.819zm3-13.637c.825 0 1.5.682 1.5 1.515v3.16l-.877-.887H14.5V4.697H13v1.515H5.5V4.697H4v10.606h4.5v1.515H4c-.825 0-1.5-.681-1.5-1.515V4.697c0-.833.675-1.515 1.5-1.515h3.135c.315-.879 1.14-1.515 2.115-1.515.975 0 1.8.636 2.115 1.515H14.5zm-5.25 0c-.412 0-.75.341-.75.758 0 .416.338.757.75.757s.75-.34.75-.757a.756.756 0 0 0-.75-.758z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.ItemInfo = ItemInfo;
