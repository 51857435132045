"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Prev = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Prev = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.7952 6.27215C15.0927 6.60683 15.0625 7.11932 14.7279 7.41682L9.57178 12L14.7279 16.5832C15.0625 16.8807 15.0927 17.3932 14.7952 17.7279C14.4977 18.0625 13.9852 18.0927 13.6505 17.7952L7.13092 12L13.6505 6.20481C13.9852 5.90731 14.4977 5.93746 14.7952 6.27215Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Prev = Prev;
