"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Drop = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Drop = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 56 40" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "56", height: "40", viewBox: "0 0 56 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M45.5907 18.8311C45.5907 18.5713 45.5907 18.3116 45.5907 18.0519C45.5907 12.4675 40.9789 7.922 35.3131 7.922C33.6001 7.922 31.8872 8.31161 30.4378 9.09083C28.8566 4.80512 24.6401 1.68823 19.7648 1.68823C13.5719 1.68823 8.43306 6.6233 8.43306 12.8571C8.43306 14.0259 8.56483 15.1947 8.96012 16.2337C4.61189 17.922 1.5813 22.0778 1.5813 26.883C1.5813 33.2467 6.85189 38.3116 13.1766 38.3116H43.2189C43.4825 38.3116 43.8778 38.3116 44.1413 38.3116C49.6754 38.3116 54.1554 33.896 54.1554 28.4415C54.2872 23.5064 50.466 19.3506 45.5907 18.8311Z", stroke: "currentColor", strokeWidth: "2.5", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M23.4541 23.7662L27.6706 19.6104L31.887 23.7662", stroke: "currentColor", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M27.6707 30.9091V19.6104", stroke: "currentColor", strokeWidth: "2", strokeMiterlimit: "10", strokeLinecap: "round", strokeLinejoin: "round" }, void 0)] }), void 0) }), void 0));
};
exports.Drop = Drop;
