"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatePickerField = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var __1 = require("../../..");
var DatePickerField = function (_a) {
    var _b;
    var _c = _a.value, value = _c === void 0 ? null : _c, onChange = _a.onChange, error = _a.error, _d = _a.placeholder, placeholder = _d === void 0 ? '' : _d, titleProps = _a.titleProps, title = _a.title, inputFormat = _a.inputFormat, textFieldProps = _a.textFieldProps, props = __rest(_a, ["value", "onChange", "error", "placeholder", "titleProps", "title", "inputFormat", "textFieldProps"]);
    return (jsx_runtime_1.jsxs(material_1.Stack, __assign({ spacing: 2 }, { children: [jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1", color: "text.secondary" }, titleProps, { children: (_b = titleProps === null || titleProps === void 0 ? void 0 : titleProps.children) !== null && _b !== void 0 ? _b : title }), void 0), jsx_runtime_1.jsx(__1.DatePickerInput, __assign({ value: value, inputFormat: inputFormat, placeholder: placeholder, error: error, onChange: onChange, textFieldProps: __assign({ sx: __assign({ '.MuiOutlinedInput-input': {
                            py: 3,
                        }, '&& .MuiOutlinedInput-notchedOutline': {
                            borderColor: error ? 'error.main' : undefined,
                        }, '&& .MuiInputLabel-root, && .MuiFormHelperText-root': {
                            color: error ? 'error.main' : 'grey.600',
                        } }, textFieldProps === null || textFieldProps === void 0 ? void 0 : textFieldProps.sx) }, textFieldProps) }, props), void 0)] }), void 0));
};
exports.DatePickerField = DatePickerField;
