import { init as revenueCatInit } from '../../lib/revenueCat'
import { api } from '../../lib/stripe'
export { getSubscriptionById } from '../../lib/revenueCat'
export { purchase } from './purchase'

export const init = (stripeApiKey: string, revenueCatApiKey: string) => {
  api.interceptors.request.use(request => {
    request.headers.Authorization = `Bearer ${stripeApiKey}`
    return request
  })

  revenueCatInit(revenueCatApiKey)
}
