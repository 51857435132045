"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Download = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Download = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.517 1.75c.543 0 .982.44.982.982v10.867l4.14-4.04a.982.982 0 1 1 1.372 1.406l-5.808 5.666a.982.982 0 0 1-1.34.03l-6.342-5.667A.982.982 0 1 1 4.83 9.53l4.705 4.204V2.732c0-.542.44-.982.982-.982z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.782 17.466c0-.433.38-.784.847-.784h15.243c.468 0 .847.35.847.784 0 .433-.38.784-.847.784H2.629c-.468 0-.847-.351-.847-.784z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Download = Download;
