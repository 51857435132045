"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Nft = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Nft = function (props) {
    var sx = props.sx, filledColor = props.filledColor, args = __rest(props, ["sx", "filledColor"]);
    var filledColorSxProps = {
        color: 'common.white',
        bgcolor: 'secondary.dark',
        borderRadius: 10,
        p: 1,
    };
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({ sx: filledColor ? __assign(__assign({}, filledColorSxProps), sx) : sx }, args, { viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.623 5.864c.967 2.709 2.087 5.905 2.48 6.99V5.837h8.564v1.71h-1.855v6.62h-1.545v-3.171l-.016-3.449H9.604v1.254h2.48v1.71H9.606l-.017 2.195v1.46H8.32l-1.071-.002-1.117-3.054C5.318 9.03 4.86 7.945 4.791 7.774c0 0-.012.146 0 3.336v3.056H3.333V5.833l1.324.03h.966z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M10 20c-.224 0-.447-.073-.67-.146l-7.826-4.392a1.726 1.726 0 0 1-.522-.512c-.149-.22-.149-.44-.149-.66V5.655c0-.22.075-.439.15-.659.074-.22.298-.366.521-.512L9.33.165c.373-.22.895-.22 1.342 0l7.825 4.318c.224.146.373.293.522.512.149.22.149.44.149.66v8.636c0 .22-.075.44-.15.659-.148.22-.297.366-.521.512l-7.825 4.318c-.224.147-.447.22-.671.22zm0-18.884s-.074.073 0 0L2.1 5.508s-.074 0-.074.073V14.364s0 .073.074.073l7.826 4.319h.149l7.825-4.319s.074 0 .074-.073V5.581s0-.073-.074-.073L10 1.116c.075.073 0 0 0 0z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Nft = Nft;
