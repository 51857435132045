"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Inquiry = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Inquiry = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M3.66038 20.9262C3.43396 20.9262 3.13208 20.8525 2.98113 20.7787C2.67925 20.6311 2.45283 20.4098 2.30189 20.1885C2.15094 19.8934 2 19.5984 2 19.3033V4.62295C2 4.18033 2.15094 3.7377 2.5283 3.44262C2.75472 3.14754 3.20755 3 3.66038 3H20.2642C20.717 3 21.1698 3.14754 21.4717 3.51639C21.7736 3.81148 22 4.2541 22 4.69672V16.5C22 16.9426 21.8491 17.3852 21.4717 17.6803C21.0943 17.9754 20.717 18.1967 20.2642 18.1967H7.73585L4.71698 20.6311C4.49057 20.8525 4.18868 21 3.88679 21C3.81132 20.9262 3.73585 20.9262 3.66038 20.9262ZM3.81132 4.77049V18.8607L6.5283 16.6475C6.83019 16.4262 7.20755 16.2787 7.58491 16.2787H19.9623V4.77049H3.81132Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Inquiry = Inquiry;
