"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChoiceChip = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
/**
 * Chipを複数並べて1つを選択するコンポーネント
 *
 * @param listItems 選択項目情報 { value: inputの値 label: 表示文言 }
 * @param onClick チップ選択イベント（引数は選択中のvalue）
 * @param repeat 横に並べて改行するまでの数（デフォルト: 2）
 * @param disabled ボタンの押下可否
 */
var ChoiceChip = function (_a) {
    var listItems = _a.listItems, _b = _a.onClick, onClick = _b === void 0 ? null : _b, _c = _a.repeat, repeat = _c === void 0 ? 2 : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d;
    var _e = react_1.useState(0), select = _e[0], setSelect = _e[1];
    var handleClick = function (select) {
        onClick && onClick(listItems[select].value);
        setSelect(select);
    };
    return (jsx_runtime_1.jsx(material_1.Box, __assign({ sx: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: 3,
        } }, { children: listItems.map(function (_a, i) {
            var value = _a.value, label = _a.label;
            return (jsx_runtime_1.jsx(material_1.Chip, { label: jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "subtitle1", color: i === select ? 'default' : 'textSecondary' }, { children: label }), void 0), variant: "filled", color: i === select ? 'secondary' : 'default', onClick: function () { return handleClick(i); }, disabled: disabled }, void 0));
        }) }), void 0));
};
exports.ChoiceChip = ChoiceChip;
