"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartHistory = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var react_1 = require("react");
var CartHistory = function (props) {
    var _a = react_1.useMemo(function () { return [
        Math.round(Math.random() * 10000000).toString(),
        Math.round(Math.random() * 10000000).toString(),
    ]; }, []), idOne = _a[0], idTwo = _a[1];
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsxs("g", __assign({ clipPath: "url(#" + idTwo + ")" }, { children: [jsx_runtime_1.jsx("mask", __assign({ id: idOne, 
                            // style="mask-type:luminance"
                            maskUnits: "userSpaceOnUse", x: "0", y: "0", width: "24", height: "24" }, { children: jsx_runtime_1.jsx("path", { d: "M0 0h24v24H0V0z", fill: "#fff" }, void 0) }), void 0), jsx_runtime_1.jsxs("g", __assign({ mask: "url(#" + idOne + ")", fill: "currentColor", stroke: "currentColor" }, { children: [jsx_runtime_1.jsx("path", { d: "M16.557 20.668c.643 0 1.166-.523 1.166-1.165a1.167 1.167 0 0 0-2.331 0c0 .642.523 1.165 1.165 1.165zm0-3.21c1.128 0 2.045.918 2.045 2.045a2.047 2.047 0 0 1-2.045 2.044 2.047 2.047 0 0 1-2.044-2.044c0-1.128.917-2.044 2.044-2.044zM7.026 20.668c.643 0 1.166-.523 1.166-1.165a1.167 1.167 0 0 0-2.331 0c0 .642.522 1.165 1.165 1.165zm0-3.21c1.127 0 2.044.918 2.044 2.045a2.047 2.047 0 0 1-2.044 2.044 2.047 2.047 0 0 1-2.044-2.044c0-1.128.917-2.044 2.044-2.044z", strokeWidth: ".6" }, void 0), jsx_runtime_1.jsx("path", { d: "M3.034 5.349c.8 0 1.49.543 1.677 1.32l.427 1.772h1.818c.002.221.018.439.045.653H5.296l1.914 7.935h9.323l1.859-3.344h.672l-2.027 3.997H6.696l-2.62-10.86a1.069 1.069 0 0 0-1.042-.82H1.033v-.653h2.001z", strokeWidth: "1.2", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M22.96 7.58A6.635 6.635 0 0 0 16.332.951a6.634 6.634 0 0 0-6.626 6.55h-.718l1.185 1.654 1.185-1.655h-.713a5.695 5.695 0 0 1 5.687-5.61 5.696 5.696 0 0 1 5.69 5.688 5.696 5.696 0 0 1-5.69 5.69 5.712 5.712 0 0 1-4.603-2.344l-.758.552a6.653 6.653 0 0 0 5.361 2.73 6.635 6.635 0 0 0 6.628-6.628z", strokeLinejoin: "round" }, void 0), jsx_runtime_1.jsx("path", { d: "M15.8 4.547v2.975l1.82 1.82.466-.465-1.627-1.628V4.547H15.8z", strokeLinejoin: "round" }, void 0)] }), void 0)] }), void 0), jsx_runtime_1.jsx("defs", { children: jsx_runtime_1.jsx("clipPath", __assign({ id: idTwo }, { children: jsx_runtime_1.jsx("path", { fill: "#fff", d: "M0 0h24v24H0z" }, void 0) }), void 0) }, void 0)] }), void 0) }), void 0));
};
exports.CartHistory = CartHistory;
