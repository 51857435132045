"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rotate = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Rotate = function (_a) {
    var variant = _a.variant, props = __rest(_a, ["variant"]);
    if (variant === 'v2') {
        return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.3703 3.25963C10.3703 2.92693 10.64 2.65723 10.9727 2.65723H14.0363C16.0037 2.65723 17.5986 4.25213 17.5986 6.21955V9.10817L18.1131 8.58592C18.3797 8.31525 18.8152 8.31196 19.0859 8.57857C19.3565 8.84518 19.3598 9.28073 19.0932 9.5514L17.5169 11.1517C17.3889 11.2817 17.2144 11.3555 17.0319 11.3568C16.8495 11.3582 16.6739 11.287 16.544 11.1589L14.9202 9.55861C14.6496 9.29192 14.6465 8.85637 14.9132 8.58578C15.1798 8.31519 15.6154 8.31202 15.886 8.57871L16.3938 9.07923V6.21955C16.3938 4.91753 15.3383 3.86203 14.0363 3.86203H10.9727C10.64 3.86203 10.3703 3.59233 10.3703 3.25963ZM4.52954 7.8149H12.1921V15.479L4.52954 15.479V7.8149ZM3.29492 7.8149C3.29492 7.13304 3.84768 6.58028 4.52954 6.58028H12.1921C12.8739 6.58028 13.4267 7.13304 13.4267 7.8149V15.479C13.4267 16.1608 12.8739 16.7136 12.1921 16.7136H4.52954C3.84768 16.7136 3.29492 16.1608 3.29492 15.479V7.8149Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
    }
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { viewBox: "0 0 20 20" }, { children: jsx_runtime_1.jsx("svg", __assign({ width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M4.167 13.333 7.5 10H5c0-2.758 2.242-5 5-5 .841 0 1.642.208 2.333.583l1.217-1.216A6.609 6.609 0 0 0 10 3.333 6.665 6.665 0 0 0 3.333 10h-2.5l3.334 3.333zM15 10c0 2.758-2.242 5-5 5a4.892 4.892 0 0 1-2.333-.583L6.45 15.633A6.609 6.609 0 0 0 10 16.667 6.665 6.665 0 0 0 16.666 10h2.5l-3.333-3.333L12.5 10H15z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Rotate = Rotate;
