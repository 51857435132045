"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../../components");
/**
 * Form用Button
 *
 * @param content { title: 見出しテキスト, description: 見出し下の説明文　}
 * @param buttonProps インプットフィールドProps
 * @param buttonAreaOptionNode ボタン下部に配置できるノード（オプショナル）
 */
var Button = function (_a) {
    var content = _a.content, buttonProps = _a.buttonProps, buttonAreaOptionNode = _a.buttonAreaOptionNode;
    var title = content.title, description = content.description, buttonText = content.buttonText;
    return (jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            px: 2,
            py: 7,
            boxSizing: 'border-box',
        } }, { children: [jsx_runtime_1.jsx(material_1.Box, __assign({ sx: {
                    display: 'flex',
                    alignItems: 'center',
                    pr: { xs: 0, md: 6 },
                    pl: { xs: 0, md: 2 },
                    boxSizing: 'border-box',
                } }, { children: jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "h5", sx: { width: { xs: '100%', md: 200 }, lineHeight: '28px' } }, { children: title }), void 0) }), void 0), jsx_runtime_1.jsxs(material_1.Box, __assign({ sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: 'center', md: 'flex-start' },
                    gap: 4,
                } }, { children: [description && (jsx_runtime_1.jsx(material_1.Typography, __assign({ variant: "body2", color: "grey.900", sx: {
                            pt: 2,
                            alignSelf: 'flex-start',
                            whiteSpace: 'pre-wrap',
                        } }, { children: description }), void 0)), jsx_runtime_1.jsx(components_1.Button, __assign({ color: "secondary" }, buttonProps, { children: buttonText }), void 0), buttonAreaOptionNode] }), void 0)] }), void 0));
};
exports.Button = Button;
