"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SerialNumber = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var SerialNumber = function (props) {
    return (jsx_runtime_1.jsx(material_1.Chip, __assign({}, props, { sx: __assign({ p: 1, height: '20px', fontSize: '10px', typography: 'overline', '.MuiChip-label': {
                p: 0,
            } }, props.sx) }), void 0));
};
exports.SerialNumber = SerialNumber;
