"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rarity02 = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Rarity02 = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 13 12", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.5 10.117 5.045 11l-.41-1.665L6 8.215 4.202 8.07 3.5 6.5l-.703 1.57L1 8.215l1.365 1.12L1.955 11l1.545-.883zM9.5 10.117l1.545.883-.41-1.665L12 8.215l-1.797-.145L9.5 6.5l-.703 1.57L7 8.215l1.365 1.12L7.955 11l1.545-.883zM6.5 4.617l1.545.883-.41-1.665L9 2.715 7.202 2.57 6.5 1l-.702 1.57L4 2.715l1.365 1.12-.41 1.665L6.5 4.617z", fill: "#27CFCF" }, void 0) }), void 0) }), void 0));
};
exports.Rarity02 = Rarity02;
