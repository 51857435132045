import Analytics, { PageData } from 'analytics'

import { User } from '@/core'

import { EventKeys } from './EventKeys'

/**
 * 型を統一するためにAnalyticsModuleとして定義
 */
export class AnalyticsModule {
  private analytics

  constructor(config: Parameters<typeof Analytics>[0]) {
    this.analytics = Analytics(config)
  }

  track(eventName: EventKeys[number], payload?: Payload) {
    return this.analytics?.track(eventName, payload)
  }

  ecommerce(payload: ECommercePayload) {
    return this.analytics?.track('purchase', payload)
  }

  identify(user: User | null, traits: Record<string, unknown>) {
    if (!user) return
    return this.analytics?.identify(user.uid, traits)
  }

  page(data?: PageData) {
    return this.analytics.page(data)
  }

  user(id?: string) {
    return this.analytics.user(id)
  }
}

interface Payload {}

export interface ECommercePayload {
  transaction_id: string
  value?: number
  currency: 'JPY' | 'USD'
  tax?: number
  shipping?: number
  type: 'prepaid_point' | 'credit_card' | 'convenience_store'
  items: ECommerceItem[]
}

export interface ECommerceItem {
  item_id: string
  item_name: string
  item_list_name?: string
  item_brand?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_variant?: string
  index?: number
  quantity: number
  price: number
}
