"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.New = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var New = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M19.04 5.39995H4.96C3.9832 5.39995 3.2088 6.18315 3.2088 7.15995L3.2 17.72C3.2 18.6968 3.9832 19.48 4.96 19.48H19.04C20.0168 19.48 20.8 18.6968 20.8 17.72V7.15995C20.8 6.18315 20.0168 5.39995 19.04 5.39995ZM8.92 15.08H7.864L5.62 12V15.08H4.52V9.79995H5.62L7.82 12.88V9.79995H8.92V15.08ZM13.32 10.9088H11.12V11.8944H13.32V13.0032H11.12V13.98H13.32V15.08H9.8V9.79995H13.32V10.9088ZM19.48 14.2C19.48 14.684 19.084 15.08 18.6 15.08H15.08C14.596 15.08 14.2 14.684 14.2 14.2V9.79995H15.3V13.7688H16.2944V10.6712H17.3944V13.76H18.38V9.79995H19.48V14.2Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.New = New;
