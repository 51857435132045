"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChevronRight = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var ChevronRight = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M15.432 11.916L9.34799 18.0323L8.56891 17.2532L13.7751 12.0461L13.9057 11.9155L13.7751 11.7849L8.56891 6.57876L9.34799 5.79922L15.432 11.9155V11.916Z", fill: "currentColor", stroke: "#97A7B0", strokeWidth: "0.5" }, void 0) }), void 0) }), void 0));
};
exports.ChevronRight = ChevronRight;
