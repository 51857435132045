"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bookmark = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Bookmark = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M5.77776 20V5.77777C5.77776 5.28888 5.95199 4.87021 6.30043 4.52177C6.64887 4.17332 7.06724 3.9994 7.55554 3.99999H16.4444C16.9333 3.99999 17.352 4.17421 17.7004 4.52266C18.0489 4.8711 18.2228 5.28947 18.2222 5.77777V20L12 17.3333L5.77776 20ZM7.55554 17.2889L12 15.3778L16.4444 17.2889V5.77777H7.55554V17.2889Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Bookmark = Bookmark;
