"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Apple = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Apple = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { htmlColor: props.isDark ? '#221f20' : props.color, sx: {
            color: props.isDark ? '#221f20' : props.color,
        } }, { children: jsx_runtime_1.jsxs("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: [jsx_runtime_1.jsx("path", { d: "M18.1964 12.829C18.1964 9.76251 20.7171 8.33877 20.8267 8.22926C19.402 6.14841 17.2101 5.92937 16.4429 5.81985C14.5798 5.60082 12.8262 6.91504 11.8399 6.91504C10.8535 6.91504 9.42878 5.81985 7.89444 5.81985C5.92171 5.92937 4.05859 7.13407 2.96263 8.88637C0.880313 12.5005 2.41465 17.9764 4.49697 20.9334C5.48333 22.3571 6.68888 23.9999 8.22322 23.9999C9.75756 23.8904 10.3055 23.0142 12.1687 23.0142C14.0318 23.0142 14.4702 23.9999 16.1141 23.9999C17.758 23.9999 18.7444 22.5762 19.7308 21.0429C20.9363 19.4001 21.3747 17.7574 21.3747 17.6478C21.3747 17.6478 18.1964 16.4431 18.1964 12.829Z", fill: "currentColor" }, void 0), jsx_runtime_1.jsx("path", { d: "M15.2372 3.95814C16.0043 2.97248 16.6619 1.54874 16.4427 0.125C15.2372 0.125 13.8124 0.891629 12.9357 1.98681C12.1685 2.86296 11.5109 4.2867 11.6205 5.60092C12.9357 5.71044 14.3604 4.94381 15.2372 3.95814Z", fill: "currentColor" }, void 0)] }), void 0) }), void 0));
};
exports.Apple = Apple;
