import {
  Stack,
  TextFieldProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import debounce from 'lodash/debounce'
import { forwardRef, useCallback } from 'react'
import { useEffect } from 'react'
import {
  FieldError,
  FieldName,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { UseFormTrigger } from 'react-hook-form/dist/types/form'
import {
  Button,
  DatePickerField,
  DatePickerFieldProps,
  MaskedInputField,
  MaskedInputFieldProps,
  RadioButtonsGroup,
  RadioButtonsGroupProps,
  TextInputField,
  TextInputFieldProps,
} from 'utoniq-ui'

import { handleError, tLabel, useMessage, useTranslation } from '@/core'
import { CellFrame, Organism } from '@/core/architecture'
import { Form, useForm, UseFormProps } from '@/core/form'
import { getInputBusiness, setInputBusiness } from '@/core/native/storage'
import { AddressInfo } from '@/lib/zipCloud'

export type ConsoleBusinessEditFormProps = {
  onSubmit: (inputs: SubmitValues) => Promise<void>
  onGetAddressInfoByCode: (code: string) => Promise<AddressInfo[]>
}

export type SubmitValues = {
  birthday: string | null
  state: string
  stateKana: string
  city: string
  cityKana: string
  line1: string
  line1Kana: string
  line2: string
  line2Kana: string
  postalCode: string
  firstNameKana: string
  firstNameKanji: string
  lastNameKana: string
  lastNameKanji: string
  gender: string
  phone: string
  town: string
  townKana: string
}

export type StorageSaveValues = Omit<SubmitValues, 'birthday'> & {
  birthday: string
}

const schema: UseFormProps['schema'] = o => ({
  lastNameKanji: o
    .string()
    .max(50)
    .label(tLabel('consoleBusinessEdit.lastNameKanji')),
  firstNameKanji: o
    .string()
    .max(50)
    .label(tLabel('consoleBusinessEdit.firstNameKanji')),
  lastNameKana: o
    .string()
    .max(50)
    .label(tLabel('consoleBusinessEdit.lastNameKana')),
  firstNameKana: o
    .string()
    .max(50)
    .label(tLabel('consoleBusinessEdit.firstNameKana')),
  phone: o.string().min(10).max(11).label(tLabel('consoleBusinessEdit.phone')),
  postalCode: o
    .string()
    .regex(/^\d{7}$/)
    .label(tLabel('consoleBusinessEdit.postalCode')),
  city: o.string().max(150).label(tLabel('consoleBusinessEdit.city')),
  cityKana: o.string().max(150).label(tLabel('consoleBusinessEdit.cityKana')),
  state: o.string().max(150).label(tLabel('consoleBusinessEdit.state')),
  stateKana: o.string().max(150).label(tLabel('consoleBusinessEdit.stateKana')),
  line1: o.string().max(150).label(tLabel('consoleBusinessEdit.line1')),
  line1Kana: o.string().max(150).label(tLabel('consoleBusinessEdit.line1Kana')),
  line2: o.string().max(150).label(tLabel('consoleBusinessEdit.line2')),
  line2Kana: o.string().max(150).label(tLabel('consoleBusinessEdit.line2Kana')),
  gender: o.string().required().label(tLabel('consoleBusinessEdit.gender')),
  birthday: o.string().required().label(tLabel('consoleBusinessEdit.birthday')),
  town: o.string().required().label(tLabel('consoleBusinessEdit.town')),
  townKana: o.string().required().label(tLabel('consoleBusinessEdit.townKana')),
})

export const ConsoleBusinessEditForm: Organism<ConsoleBusinessEditFormProps> =
  ({ onSubmit, onGetAddressInfoByCode }) => {
    const { t } = useTranslation('consoleBusinessEdit')
    const { showMessage, showError } = useMessage()
    const {
      handleSubmit,
      register,
      setValue,
      watch,
      reset,
      trigger,
      setError,
      formState: { errors, isValid, isSubmitting },
    } = useForm<SubmitValues>({
      schema,
      defaultValues,
    })

    const handleOnSubmit = handleSubmit(values => {
      return onSubmit(values)
        .then(() => {
          reset(defaultValues)
          showMessage(t('registerSuccess'), 'success')
        })
        .catch(e => {
          handleError<SubmitValues>(e, setError, showError)
        })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debounceSetAddressField = useCallback(
      debounce(async (postalCode: string) => {
        const option = {
          shouldDirty: true,
          shouldValidate: true,
          shouldTouch: true,
        }
        const results = await onGetAddressInfoByCode(postalCode)
        results?.[0]?.address3 &&
          setValue('town', results?.[0].address3, option)
        results?.[0]?.address2 &&
          setValue('city', results?.[0].address2, option)
        results?.[0]?.address1 &&
          setValue('state', results?.[0].address1, option)
      }, 1000),
      []
    )

    useEffect(() => {
      const t = async () => {
        const item = await getInputBusiness()
        reset(item)
      }

      t()

      return () => {
        const { birthday, ...args } = watch()
        setInputBusiness({ birthday: birthday ?? '', ...args })
      }
    }, [])

    return (
      <Form
        onSubmit={handleOnSubmit}
        sx={{ pt: 3, bgcolor: 'background.default' }}
      >
        <Stack sx={{ pb: 6 }}>
          <Typography variant="h4">{t('personInChargeInformation')}</Typography>
        </Stack>
        <TextInputCell
          type="text"
          error={errors?.lastNameKanji}
          {...register('lastNameKanji')}
        />
        <TextInputCell
          type="text"
          error={errors?.firstNameKanji}
          {...register('firstNameKanji')}
        />
        <TextInputCell
          type="text"
          error={errors?.lastNameKana}
          {...register('lastNameKana')}
        />
        <TextInputCell
          type="text"
          error={errors?.firstNameKana}
          {...register('firstNameKana')}
        />

        <RadioButtonsCell
          name="gender"
          trigger={trigger}
          setValue={setValue}
          watch={watch}
          error={errors?.gender}
          listItems={[
            { value: 'male', label: t('male') },
            { value: 'female', label: t('woman') },
          ]}
        />
        <DatePickerButtonsCell
          watch={watch}
          name="birthday"
          setValue={setValue}
          error={errors?.birthday}
          trigger={trigger}
        />
        <MaskedInputCell
          type="tel"
          mask="00000000000"
          error={errors?.phone}
          {...register('phone')}
        />
        <Typography variant="h4" sx={{ pb: 5 }}>
          {t('address')}
        </Typography>
        <MaskedInputCell
          {...register('postalCode')}
          type="text"
          mask="0000000"
          onChange={async code => {
            setValue('postalCode', code.target.value, { shouldDirty: true })
            debounceSetAddressField(code.target.value)
          }}
          error={errors?.postalCode}
        />
        <TextInputCell
          type="text"
          error={errors?.state}
          {...register('state')}
        />
        <TextInputCell
          type="text"
          error={errors?.state}
          {...register('stateKana')}
        />
        <TextInputCell type="text" error={errors?.city} {...register('city')} />
        <TextInputCell
          type="text"
          error={errors?.city}
          {...register('cityKana')}
        />
        <TextInputCell type="text" error={errors?.town} {...register('town')} />
        <TextInputCell
          type="text"
          error={errors?.town}
          {...register('townKana')}
        />
        <TextInputCell
          type="text"
          error={errors?.line1}
          {...register('line1')}
        />
        <TextInputCell
          type="text"
          error={errors?.line1}
          {...register('line1Kana')}
        />
        <TextInputCell
          type="text"
          error={errors?.line2}
          {...register('line2')}
        />
        <TextInputCell
          type="text"
          error={errors?.line2}
          {...register('line2Kana')}
        />
        <CellFrame sx={{ pb: 5, textAlign: 'center' }}>
          <Button
            type="submit"
            size="large"
            disabled={!isValid}
            sx={{ width: 244 }}
            loading={isSubmitting}
          >
            {t('submit')}
          </Button>
        </CellFrame>
      </Form>
    )
  }

type RadioButtonsCellProps = {
  name: FieldName<SubmitValues>
  setValue: UseFormSetValue<SubmitValues>
  watch: UseFormWatch<SubmitValues>
  trigger: UseFormTrigger<SubmitValues>
  error?: FieldError
  titleProps?: TypographyProps
  listItems?: RadioButtonsGroupProps['listItems']
}

const RadioButtonsCell = ({
  titleProps,
  error,
  setValue,
  watch,
  name,
  trigger,
  listItems,
}: RadioButtonsCellProps) => {
  const { t } = useTranslation('consoleBusinessEdit')
  return (
    <CellFrame>
      <RadioButtonsGroup
        error={error}
        value={watch('gender') || ''}
        title={name}
        row
        onChange={async value => {
          setValue(name, value, { shouldDirty: true })
          await trigger(name)
        }}
        listItems={listItems ?? []}
        titleProps={{
          variant: 'h5',
          color: 'text.primary',
          sx: { alignItems: 'center', display: 'flex' },
          ...titleProps,
        }}
        t={t}
      />
    </CellFrame>
  )
}

type DatePickerButtonsCellProps = {
  titleProps?: DatePickerFieldProps['titleProps']
  error?: FieldError
  name: FieldName<SubmitValues>
  setValue: UseFormSetValue<SubmitValues>
  watch: UseFormWatch<SubmitValues>
  trigger: UseFormTrigger<SubmitValues>
  textFieldProps?: TextFieldProps
  placeholder?: string
}
const DatePickerButtonsCell = ({
  titleProps,
  error,
  name,
  setValue,
  watch,
  trigger,
  textFieldProps,
}: DatePickerButtonsCellProps) => {
  const { t } = useTranslation('consoleBusinessEdit')
  return (
    <CellFrame sx={{ pb: 5 }}>
      <DatePickerField
        error={error}
        onChange={async date => {
          setValue(name, date, { shouldDirty: true })
          await trigger(name)
        }}
        value={watch(name) || null}
        title={t(`${name}Title`)}
        placeholder="1999/09/09"
        textFieldProps={textFieldProps}
        titleProps={{
          variant: 'h5',
          color: 'text.primary',
          ...titleProps,
        }}
      />
    </CellFrame>
  )
}

const TextInputCell = forwardRef(
  (
    {
      titleProps,
      textFieldProps,
      error,
      ...props
    }: Omit<TextInputFieldProps, 't'>,
    ref
  ) => {
    const { t } = useTranslation('consoleBusinessEdit')
    return (
      <CellFrame>
        <TextInputField
          {...props}
          titleProps={{
            variant: 'h5',
            color: 'text.primary',
            ...titleProps,
          }}
          textFieldProps={textFieldProps}
          error={error}
          ref={ref}
          t={t}
        />
      </CellFrame>
    )
  }
)

const MaskedInputCell = forwardRef(
  (
    {
      titleProps,
      textFieldProps,
      error,
      ...props
    }: Omit<MaskedInputFieldProps, 't'>,
    ref
  ) => {
    const { t } = useTranslation('consoleBusinessEdit')
    return (
      <CellFrame>
        <MaskedInputField
          {...props}
          titleProps={{
            variant: 'h5',
            color: 'text.primary',
            ...titleProps,
          }}
          textFieldProps={textFieldProps}
          ref={ref}
          t={t}
          error={error}
          placeholder={t(`${props.name}Placeholder`)}
        />
      </CellFrame>
    )
  }
)
export const defaultValues: SubmitValues = {
  birthday: '',
  state: '',
  stateKana: '',
  city: '',
  cityKana: '',
  line1: '',
  line1Kana: '',
  line2: '',
  line2Kana: '',
  postalCode: '',
  firstNameKana: '',
  firstNameKanji: '',
  lastNameKana: '',
  lastNameKanji: '',
  gender: '',
  phone: '',
  town: '',
  townKana: '',
}
