"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Share = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Share = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 2C11.7258 2 11.4516 2.09763 11.2424 2.29289L7.31382 5.95954C6.89539 6.35006 6.89539 6.98323 7.31382 7.37375C7.73224 7.76428 8.41063 7.76428 8.82905 7.37375L11 5.34754V13.9166C11 14.4689 11.4477 14.9166 12 14.9166C12.5523 14.9166 13 14.4689 13 13.9166V5.34754L15.1709 7.37375C15.5894 7.76428 16.2678 7.76428 16.6862 7.37375C17.1046 6.98323 17.1046 6.35006 16.6862 5.95954L12.7576 2.29289C12.5484 2.09763 12.2742 2 12 2ZM4 9.16653C4.55228 9.16653 5 9.61424 5 10.1665V18.1667C5 18.3877 5.0878 18.5996 5.24407 18.7559C5.40035 18.9122 5.61231 19 5.83332 19H18.1667C18.3877 19 18.5996 18.9122 18.7559 18.7559C18.9122 18.5996 19 18.3877 19 18.1667V10.1665C19 9.61424 19.4477 9.16653 20 9.16653C20.5523 9.16653 21 9.61424 21 10.1665V18.1667C21 18.9181 20.7015 19.6388 20.1701 20.1701C19.6388 20.7015 18.9181 21 18.1667 21H5.83332C5.08188 21 4.36121 20.7015 3.82986 20.1701C3.29851 19.6388 3 18.9181 3 18.1667V10.1665C3 9.61424 3.44772 9.16653 4 9.16653Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Share = Share;
