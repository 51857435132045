"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Circle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var Circle = function (props) {
    return (jsx_runtime_1.jsx(material_1.SvgIcon, __assign({}, props, { children: jsx_runtime_1.jsx("svg", __assign({ width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, { children: jsx_runtime_1.jsx("path", { d: "M11.9916 22.0002C6.47157 22.0002 2.00156 17.5202 2.00156 12.0002C2.00156 6.48019 6.47157 2.0002 11.9916 2.0002C17.5216 2.0002 22.0015 6.48019 22.0015 12.0002C22.0015 17.5202 17.5216 22.0002 11.9916 22.0002ZM12.0016 4.0002C7.58156 4.0002 4.00157 7.5802 4.00157 12.0002C4.00157 16.4202 7.58156 20.0002 12.0016 20.0002C16.4215 20.0002 20.0016 16.4202 20.0016 12.0002C20.0016 7.5802 16.4215 4.0002 12.0016 4.0002Z", fill: "currentColor" }, void 0) }), void 0) }), void 0));
};
exports.Circle = Circle;
