import {
  ArtistMembershipSubscriptionDetailFragment,
  MembershipSubscriptionPlanDetailFragment,
  MembershipSubscriptionProductDetailFragment,
} from '@/clients/utoniq-core/schema'

export type MembershipSubscriptionProduct =
  MembershipSubscriptionProductDetailFragment & {
    plan: MembershipSubscriptionPlanDetailFragment
  }

/**
 * get active product plan
 * @param artist
 * @param planId
 */
export const getActiveMembershipSubscriptionProductPlan = (
  artist: ArtistMembershipSubscriptionDetailFragment | undefined | null,
  planId?: string | null
): MembershipSubscriptionProduct | undefined => {
  if (!artist || !planId) {
    return
  }

  const product = artist.subscription?.products?.find(product =>
    product?.plans?.some(plan => plan.planId === planId)
  )
  if (!product) {
    return
  }

  const plan = product.plans.find(plan => plan.planId === planId)
  if (!plan) {
    return
  }

  return {
    ...product,
    plan,
  }
}
