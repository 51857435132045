import 'dayjs/locale/ja'
import 'dayjs/locale/en'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export function init(locale: string) {
  dayjs.locale(locale)
  dayjs.tz.setDefault('Asia/Tokyo')
}

/**
 * DateTimePickerの入力値をDateオブジェクトに変換する
 *
 * @param time 時間表記
 * @returns 変換後のフォーマット時刻
 */
export function convertDatePickerValueToDate(time?: string | null) {
  if (!time) {
    return null
  }
  return dayjs(time, 'YYYY/MM/DD HH:mm', 'ja').toDate()
}

/**
 * （リセール抽選日となる） 次の12時の日時を返す
 */
export function nextRaffleDateTime(format = 'YYYY/MM/DD HH:mm') {
  const now = dayjs()

  const time =
    now.hour() > 12 || (now.hour() === 12 && now.minute() > 0)
      ? now.add(1, 'day').hour(12).minute(0).second(0)
      : now.hour(12).minute(0).second(0)

  return time.format(format)
}

/**
 * return hours, minutes, seconds
 * @param seconds
 */
export function secondsToHms(seconds: number) {
  return {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
    seconds: Math.floor((seconds % 3600) % 60),
  }
}

export { dayjs }
